import React, {useState, useImperativeHandle, forwardRef} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {addReservationAddress, addReservationCity, addReservationPostalCode} from "../../../reducer";
import {Col, Row} from "reactstrap";
import PinDropIcon from '@mui/icons-material/PinDrop';
import {InputAdornment, TextField, CircularProgress, Paper, List, ListItem, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {fetchSuggestions} from "../../../tools/AddressUtils";
import {extractHouseNumber} from "../../../tools/Tools";
import AddAddressManually from "./AddAddressManually";

/* eslint-disable-next-line no-empty-pattern */
export const AddLocation = forwardRef(({setShowLongWaitBtn}, ref) => {
    const dispatch = useDispatch();
    const {address, city} = useSelector((state) => state.contactForm);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");
    const profileData = useSelector(state => state.profile.profileData);
    const [suggestions, setSuggestions] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isManually, setIsManually] = useState(false);
    const country_code = useSelector((state) => state.global.countryCode);

    useImperativeHandle(ref, () => ({
        addAddressManually() {
            setIsManually(true);
        },
    }));

    const handleFocus = () => setIsFocused(true);

    const handleBlur = () => setIsFocused(false);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setQuery(newValue);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(async () => {
            try {
                if (newValue.length > 2) {
                    setLoading(true);
                    const suggestions = await fetchSuggestions(newValue, country_code);
                    setSuggestions(suggestions);
                } else {
                    setSuggestions([]); // Réinitialise si la requête est trop courte
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setLoading(false);
            }
        }, 1000);

        setDebounceTimeout(newTimeout);
    };

    const handleSuggestionClick = (addressData) => {
        const placeName = addressData.place_name; // Nom complet de l’adresse
        if (!placeName) return;

        const houseNumber = extractHouseNumber(query);
        const context = addressData.context || [];

        const country = context.find((c) => c.id.includes("country"));
        const countryCode = country?.short_code;

        if (countryCode?.toLowerCase() !== country_code?.toLowerCase()) {
            toast.error("Adresse invalide pour cette fiche.");
            return;
        }

        const street = `${houseNumber || ""} ${addressData.text || ""}`.trim();
        const city = context.find((c) => c.id.includes("place"))?.text || "";
        const postalCode = context.find((c) => c.id.includes("postcode"))?.text || "";

        if (!street || !city || !postalCode) {
            setShowLongWaitBtn(true)
            toast.error("L'adresse n'est pas complète.");
            return;
        }

        dispatch(addReservationAddress(street));
        dispatch(addReservationCity(city));
        dispatch(addReservationPostalCode(postalCode));

        setQuery("");
        setSuggestions([]);
    };

    const handleProfileAddressClick = () => {
        let [streetDefault, pcCity] = profileData.address.split(", ");
        const [postalCode, city] = pcCity.split(" ");
        const street = streetDefault;

        dispatch(addReservationAddress(street));
        dispatch(addReservationCity(city));
        dispatch(addReservationPostalCode(postalCode));

        toast.success("L'adresse par defaut a été choisi.");
        setQuery("");
        setSuggestions([]);
    };

    return (
        <Row>
            <Modal show={isManually} size="md">
                <AddAddressManually closeModal={() => setIsManually(false)}/>
            </Modal>

            {profileData?.address && (
                <div className="mx-3 mb-2">
                    <Typography
                        sx={{
                            padding: "2px 5px", // Réduit l'espace en haut et en bas
                            fontSize: "14px", // Taille plus petite pour le texte "Adresse par défaut"
                            color: "#999", // Couleur secondaire
                        }}
                    >
                        Adresse par défaut sur votre profil
                    </Typography>
                    <ListItem
                        button
                        style={{
                            padding: "5px 15px", // Moins de padding en haut et en bas, mais suffisamment sur les côtés
                            width: "100%", // L'adresse prend toute la largeur
                            display: "flex", // Flexbox pour aligner l'icône et le texte
                            alignItems: "center", // Centre verticalement l'icône et le texte
                            whiteSpace: "nowrap", // Évite le retour à la ligne
                            overflow: "hidden", // Coupe l'excès de texte
                            textOverflow: "ellipsis", // Ajoute "..." si le texte est trop long
                        }}
                        onClick={handleProfileAddressClick}
                    >
                        <PinDropIcon
                            sx={{
                                fontSize: "18px", // Taille de l'icône
                                color: "#666", // Couleur de l'icône
                                marginLeft: "10px", // Espacement entre l'icône et le texte
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "14px", // Taille de police réduite
                                color: "#666", // Couleur secondaire pour l'adresse
                                lineHeight: "1.5", // Une ligne compacte
                            }}
                        >
                            {profileData.address}
                        </Typography>
                    </ListItem>
                </div>
            )}

            <Col md="12 mb-4">
                <TextField
                    fullWidth
                    value={!isFocused && address ? `${address}, ${city}` : query}
                    variant="filled"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    label="Entrez l'adresse"
                    placeholder="123 Elm Street...."
                    onChange={handleInputChange}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                        endAdornment: loading && (
                            <InputAdornment position="end">
                                <CircularProgress size={20}/>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        "& .MuiFilledInput-root": {
                            borderRadius: "10px",
                            backgroundColor: "#f1f3f4",
                        },
                    }}
                />
                {suggestions.length > 0 && (
                    <Paper
                        sx={{
                            position: "absolute",
                            width: "100%",
                            maxHeight: 200,
                            overflowY: "auto",
                            marginTop: 1,
                            zIndex: 1,
                            backgroundColor: "#f3f3f3",
                        }}
                    >
                        <List>
                            {suggestions.map((suggestion) => (
                                <ListItem
                                    button
                                    style={{width: "95%"}}
                                    className="p-2"
                                    key={suggestion.id}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.place_name.length > 40
                                        ? `${suggestion.place_name.slice(0, 40)}...`
                                        : suggestion.place_name}
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                )}
            </Col>
        </Row>
    );
});
