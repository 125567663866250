import axios from "axios";
import Conf from "../../config/tsconfig.json";
import DecryptData from "../../tools/DecryptData";

const serverApi = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? Conf.configs.ServerApiDev
    : Conf.configs.ServerApi

const generateAxios = (headers) => {
    const instance = axios.create({baseURL: serverApi, headers: headers})
    instance.interceptors.response.use(
        response => {
            return {data: DecryptData(response.data)}
        },
        error => {
            return Promise.reject(error);
        }
    );
    return instance;
}

const initState = {
    auditor: generateAxios({
            'Language': "fr",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Isl-Token': Conf.configs.TokenVisitor
    }),
    auditorForm: generateAxios({
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': "*",
        'Language': "fr",
        'Isl-Token': ""
    })
};

const AxiosUSerConfig = (state = initState, action) => {
    switch (action.type) {
        case "ADD_TOKEN_IP_AXIOS_AUDITOR":

            let {ipAddress, token} = action.data

            return {
                ...state,
                auditor: generateAxios({
                    'ipAddress': ipAddress,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': "*",
                    'Language': "fr",
                    'Isl-Token': token
                }),
                auditorForm: generateAxios({
                    'ipAddress': ipAddress,
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': "*",
                    'Language': "fr",
                    'Isl-Token': token
                })
            };

        default:
            return state;
    }
};

export default AxiosUSerConfig;
