import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FeedIcon from '@mui/icons-material/Feed';
import {Card, Row} from "reactstrap";
import {Info, InfoTitle} from '@mui-treasury/components/info';
import {useMusicInfoStyles} from '@mui-treasury/styles/info/music';

function TabPanel({children, value, index}) {
    return (
        value === index && (
            <Box sx={{p: 2}}>
                <Typography>{children}</Typography>
            </Box>
        )
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

function ContentCard({title, content, height = 200}) {
    return (
        <Card className="border-0 pt-2" style={{height, width: '100%'}}>
            <Row className="justify-content-center">
                <Info useStyles={useMusicInfoStyles} className="text-center">
                    <InfoTitle>{title}</InfoTitle>
                    <div
                        style={{
                            maxHeight: height - 50,
                            overflowY: 'auto',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingBottom: '10px',
                            margin: '15px',
                            textAlign: 'justify',
                        }}
                    >
                        <small className="text-secondary">{content || 'Aucun contenu disponible'}</small>
                    </div>
                </Info>
            </Row>
        </Card>
    );
}

export default function VerticalTabs({materials, rPolicy, description}) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue);

    const tabContent = [
        {
            title: 'À propos de la fiche',
            content: description,
        },
        {
            title: 'Matériels',
            content: materials?.list_of_materials.length
                ? materials.list_of_materials.map((item, index) => (
                    <small key={index} className="btn btn-grey text-lowercase text-secondary mx-2">
                        {item}
                    </small>
                ))
                : 'Pas de matériel',
        },
        {
            title: 'Politique',
            content: rPolicy === 'strict' ? 'Stricte' : rPolicy,
        },
    ];

    return (
        <div>
            <ContentCard {...tabContent[value]} />
            <Box sx={{flexGrow: 1, display: 'flex', borderRadius: 2, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab icon={<FeedIcon/>}/>
                    <Tab icon={<HomeRepairServiceIcon/>}/>
                    <Tab icon={<LocalPoliceIcon/>}/>
                </Tabs>
            </Box>
        </div>
    );
}
