import Badge from "@mui/material/Badge";
import PerfectScrollbar from "perfect-scrollbar";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {NavLink, useNavigate} from "react-router-dom";
import {Nav} from "reactstrap";
import logo from "../../assets/img/logo.png"
import AppleIcon from '@mui/icons-material/Apple';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ShopIcon from '@mui/icons-material/Shop';
import IconButton from '@material-ui/core/IconButton';
import {swapSideBarExpandIcon} from "../../reducer";
import {getPathFromUrl} from "../../tools/Tools";
import Login from "../../views/auth/Login";
import Register from "../../views/auth/Register";

let ps;

function Sidebar(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebar = useRef();
    const isFirstRun = useRef(true);
    const [routes, setRoutes] = useState([]);
    const role = useSelector(state => state.profile.role);
    const auth = useSelector(state => state.session.authenticated);
    // verifies if routeName is the one active (in browser input)
    const pathname = getPathFromUrl(window.location.href);
    const [isLogin, setIsLogin] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const show = useSelector(state => state.global.sideBarExpandIcon);
    const {contactsLength, contacts_to_finish} = useSelector(state => state.profile);


    const onDashClick = (e) => {
        e && e.preventDefault();
        dispatch(swapSideBarExpandIcon(!show))
    }

    const sideBarItemElement = (route, key) => {
        const isActive = route.path?.split(":")[0] === pathname;
        const isShowAgendaBadge = route.path === 'agenda' && contacts_to_finish?.length;
        const isVisibleBadge = ['artist-contacted-demand', 'artist-contact-demand'].includes(route.path);
        const count = route.path === 'artist-contacted-demand'
            ? contactsLength['book']?.pending
            : contactsLength['auditor']?.pending;

        return (
            <li className='mt-0' key={key + route.path}>
                <NavLink
                    to={route.path}
                    className="nav-link"
                    style={{color: isActive ? "#b9f6ca" : null}}
                >
                    {isShowAgendaBadge ? (
                        // Structure spécifique pour l'élément "Agenda" pour placer le badge à droite du texte
                        <div className="d-flex">
                            <div className="row justify-content-start ml-1">
                                {route.icon(isActive)}
                                <p className="ml-2">{route.name}</p>
                            </div>
                            <Badge
                                color="secondary"
                                badgeContent={contacts_to_finish?.length}
                                invisible={!isShowAgendaBadge}
                                className="ml-4"
                            />
                        </div>
                    ) : (
                        // Structure par défaut pour les autres éléments
                        <div className="d-flex">
                            <div className="row justify-content-start ml-1">
                                {typeof route.icon === 'string' ? (
                                    <i className={route.icon}/>
                                ) : (
                                    <>
                                        {route.icon(isActive)}
                                        <p className="ml-2">{route.name}</p>
                                    </>
                                )}
                            </div>
                            {isVisibleBadge && (
                                <Badge
                                    color="secondary"
                                    badgeContent={count}
                                    invisible={!isVisibleBadge}
                                    className="ml-4"
                                />
                            )}
                        </div>
                    )}
                </NavLink>
            </li>
        );
    };

    const expandIcon = () => {
        return show
            ? <ExpandLess fontSize="small" id="no-sidebar-close-id-4"/>
            : <ExpandMore fontSize="small" id="no-sidebar-close-id-5"/>
    }

    const SideBarProcess = (route, key) => {

        switch (true) {
            case Boolean(route.component?.length) && !(route.requireOAuth && !auth && !role):
                return (
                    <div key={key + route.name} id="no-sidebar-close-id-0">
                        <li onClick={(e) => onDashClick()} id="no-sidebar-close-id-1">
                            <NavLink className="nav-link" id="no-sidebar-close-id-#">
                                {typeof route.icon === 'string'
                                    ? <i className={route.icon} id="no-sidebar-close-id-2"/>
                                    : <div className="row justify-content-start ml-1" id="no-sidebar-close-id-6">
                                        {route.icon(false)}
                                        <p className="ml-2" id="no-sidebar-close-id-6">
                                            {route.name}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {expandIcon()}
                                        </p>
                                    </div>}
                                {typeof route.icon === 'string'
                                    ? <p id="no-sidebar-close-id-3">
                                        {route.name}
                                        {expandIcon()}
                                    </p>
                                    : null}
                            </NavLink>
                        </li>

                        <div aria-expanded="true" className={`collapse pl-3 navbar-collapse ${show ? "show" : ""}`}>
                            {route.component?.map((r, index) => sideBarItemElement(r, index))}
                        </div>
                    </div>
                )
            case !(route.requireOAuth && !auth && !role):
                return sideBarItemElement(route, key)
            default:
                return null
        }
    }

    const filterRoutes = (_routes) => {
        const pList = ["artist-contact-demand", "artist-contacted-demand", "my-kantobiz-cards", "my-kantobiz-transfers"]
        for (let i = 0; i < pList.length; i++) {
            if (pathname.includes(pList[i])) {
                dispatch(swapSideBarExpandIcon(true))
            }
        }

        let new_routes = _routes.filter((value) => !value.hidden)

        for (let row in new_routes) {
            if (new_routes[row]?.component?.length) {
                new_routes[row]['component'] = _routes[row]?.component?.filter((value) => !value.hidden)
            }
        }

        return new_routes
    }

    useEffect(() => {
        if (isFirstRun.current || role) {
            isFirstRun.current = false;
            setRoutes(filterRoutes(props.routes));
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [role]);


    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }

        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });

    return (
        <div className="sidebar">
            <div className="logo d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    {/* Logo Image */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={null} onClick={() => navigate('/')} className="simple-text ml-2 logo-mini">
                        <div className="logo-img">
                            <img src={logo} alt="react-logo"/>
                        </div>
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="!#" onClick={() => navigate('/')} className="simple-text logo-normal ml-2">
                        KantoBiz
                    </a>
                </div>

                {/* App Store and Google Play Icons */}
                <div className="ml-auto d-flex">
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a hidden
                       target="_blank"
                       rel="noreferrer"
                       id="go_to_apple_app_auditor"
                       href="https://apps.apple.com/us/app/kantobiz/id1606736780"/>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a hidden
                       target="_blank"
                       rel="noreferrer"
                       id="go_to_google_app_auditor"
                       href="https://play.google.com/store/apps/details?id=com.kantobiz"/>
                    <IconButton
                        aria-label="App Store"
                        onClick={() => document.getElementById("go_to_apple_app_auditor").click()}>
                        <AppleIcon fontSize="small" sx={{color: "white !important"}}/>
                    </IconButton>
                    <IconButton
                        aria-label="Google Play"
                        onClick={() => document.getElementById("go_to_google_app_auditor").click()}>
                        <ShopIcon fontSize="small" sx={{color: "white !important"}}/>
                    </IconButton>
                </div>
            </div>
            <div className="sidebar-wrapper pt-2" ref={sidebar}>
                <Nav>
                    {routes.map((prop, key) => {
                        return SideBarProcess(prop, key)
                    })}
                </Nav>

                <Modal size="md" show={isLogin}>
                    <Login closeModal={() => setIsLogin(false)}/>
                </Modal>

                <Modal size="md" show={isRegister}>
                    <Register closeModal={() => setIsRegister(false)}/>
                </Modal>

                {/* Boutons Inscription et Connexion */}
                {!auth ? (
                    <div className="auth-buttons">
                        <button className="auth-button" onClick={() => {
                            document.getElementById("button-toggle-sidebar").click()
                            setIsRegister(true)
                        }}>
                            <small className="text-uppercase">Inscription</small>&nbsp;
                            <PersonAddIcon fontSize="small" sx={{pb: 1 / 2, color: "#FFF !important"}}/>
                        </button>
                        <button
                            id="login_button"
                            className="auth-button"
                            onClick={() => {
                                document.getElementById("button-toggle-sidebar").click()
                                setIsLogin(true)
                            }}>
                            <small className="text-uppercase">Connexion</small>&nbsp;
                            <PersonIcon fontSize="small" sx={{pb: 1 / 2, color: "#FFF !important"}}/>
                        </button>
                    </div>
                ) : null}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Sidebar;
