import React from 'react';
import {Box, Skeleton, Stack} from '@mui/material';

const EventListSkeleton = () => {
    return (
        <Box p={2} width="100%">
            {/* Prochain événement */}
            <Box mb={2} border="1px solid #e0e0e0" borderRadius="8px" padding="16px">
                <Stack direction="column" spacing={1}>
                    <Skeleton variant="text" width="60%" height={30}/>
                    <Skeleton variant="rectangular" width="100%" height={80}/>
                </Stack>
            </Box>

            {/* Autres événements */}
            <Box>
                <Skeleton variant="text" width="40%" height={25} style={{marginBottom: 16}}/>
                <Stack direction="column" spacing={1}>
                    {[...Array(4)].map((_, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            border="1px solid #e0e0e0"
                            borderRadius="8px"
                            padding="16px"
                        >
                            <Skeleton variant="rectangular" width="60px" height="60px"/>
                            <Stack width="70%" spacing={0.5}>
                                <Skeleton variant="text" width="100%" height={20}/>
                                <Skeleton variant="text" width="80%" height={15}/>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

export default EventListSkeleton;
