import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import React, {useState} from "react"
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {CardBody, CardFooter, Row, Spinner} from "reactstrap";
import {checkErrorMessage, clearContactToChat, customButton} from "../../tools/Tools";
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import {
    addBotMessageList,
    addProfileContactsArchived,
    addProfileDataContacts,
    addProfileDataContactsLength,
    switchBotMsgProcess,
    switchLoadingDiscussions
} from "../../reducer";


function DeclineEvent(props) {

    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const {messagesList} = useSelector(state => state.chatBot);
    const auditor = useSelector(state => state.axios.auditor);
    const {contacts, contacts_archived, contactsLength} = useSelector(state => state.profile);
    const {isBot, reservationId, setWarnDecline} = props

    const closeChat = async () => {
        await clearContactToChat(dispatch)
        dispatch(switchBotMsgProcess(true))
        isBot && dispatch(addBotMessageList([messagesList[0]]))
    }

    const cancelEventBooked = async () => {
        setLoad(true)
        await auditor.put("reservation/auditor_decline/" + reservationId).then(async (resp) => {
            let contactCard = resp.data;
            const contactFiltered = contacts['book']?.accepted.filter(n => n.id !== contactCard.id);
            const updatedContacts = {...contacts, book: {...contacts['book'], accepted: contactFiltered}};
            await dispatch(addProfileDataContacts(updatedContacts));
            const updatedContactsLength = {
                ...contactsLength,
                book: {
                    ...contactsLength['book'],
                    accepted: contactsLength['book']?.accepted - 1
                }
            };
            await dispatch(addProfileDataContactsLength(updatedContactsLength));
            await dispatch(addProfileContactsArchived([...contacts_archived, contactCard]));
            await closeChat()
            await dispatch(switchLoadingDiscussions(true))
            setLoad(false)
            setWarnDecline(false)
            toast.success('Vous avez annuler la réservation.')
        }).catch(error => {
            toast.error(checkErrorMessage(error).message)
        });
    };

    return (
        <div>
            <CardBody>
                <Row className="justify-content-center">
                    <Box maxWidth={343}>
                        <CardContent>
                            <TextInfoContent
                                useStyles={useN04TextInfoContentStyles}
                                heading={'Êtes vous sûr ?'}
                                overline={"Annulation de l'événement"}
                                body={
                                    'Ceci entraînera la fermeture de la discussion sur cette prise de contact.'
                                }
                            />
                        </CardContent>
                    </Box>
                </Row>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-center">
                    <Button
                        size="small"
                        disabled={load}
                        variant="outlined"
                        onClick={() => setWarnDecline(false)}
                        sx={customButton}
                    >
                        <CloseIcon/>
                    </Button>

                    <Button
                        size="small"
                        disabled={load}
                        variant="outlined"
                        className="ml-2"
                        onClick={() => cancelEventBooked()}
                        sx={customButton}
                        endIcon={
                            load
                                ? <Spinner type="grow" size="sm"/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                        }>
                        Oui
                    </Button>
                </Row>
            </CardFooter>

        </div>
    )
}

export default DeclineEvent
