import React, {useRef, useState, useEffect} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {useSelector} from "react-redux";
import {Card, CardFooter, CardHeader} from "reactstrap";
import {payment_appearance} from "../../../tools/Variables";
import CheckOutEvent from "../../../views/checkout/CheckOutEvent";
import AllowEvent from "../../../views/reservations/AllowEvent";
import ContractEvent from "../../../views/reservations/ContractEvent";
import DeclineEvent from "../../../views/reservations/DeclineEvent";
import NewProposition from "../../../views/reservations/NewProposition";
import ChatBot from "./ChatBot";
import ChatFooter from "./ChatFooter";
import ChatNavigation from "./ChatNavigation";
import {checkStripePromise, useWindowSize} from "../../../tools/Tools";
import ChatArtist from "./ChatArtist";
import EventBanner from "./EventBanner";


function Chat({bookType, initMessage}) {

    const isMounted = useRef(false);
    const width = useWindowSize();
    const toScroll = useRef()
    const [toPay, setToPay] = useState(false);
    const [isBot, setIsBot] = useState(false);
    const [stripePromise, setStripPromise] = useState();
    const [clientSecret, setClientSecret] = useState('');
    const [allowEvent, setAllowEvent] = useState(false);
    const [seeContract, setSeeContract] = useState(false);
    const [propose, setPropose] = useState(false);
    const [warnDecline, setWarnDecline] = useState(false);
    const auditor = useSelector(state => state.axios.auditor);
    const {isDev, contactToChat, currenciesChat, amountDetailChat} = useSelector(state => state.global);
    const {code} = currenciesChat;
    const {ttc} = amountDetailChat

    const close = () => {
        setToPay(false)
        setAllowEvent(true)
    }

    const handleChangePersonChat = () => {
        setIsBot(!isBot)
    }

    const renderContent = () => {
        switch (true) {
            case seeContract:
                return (
                    <ContractEvent closeContract={setSeeContract}/>
                )

            case toPay:
                return (
                    <Elements stripe={stripePromise} options={{clientSecret, payment_appearance}}>
                        <CheckOutEvent isBot={isBot} close={close}/>
                    </Elements>
                );

            case allowEvent:
                return (
                    <AllowEvent
                        isBot={isBot}
                        setToPay={setToPay}
                        initMessage={initMessage}
                        setAllowEvent={setAllowEvent}/>
                );

            case Boolean(warnDecline):
                return (
                    <DeclineEvent isBot={isBot} reservationId={warnDecline} setWarnDecline={setWarnDecline}/>
                );

            case Boolean(propose):
                return (
                    <NewProposition
                        isBot={isBot}
                        propose={propose}
                        bookType={bookType}
                        toScroll={toScroll}
                        setPropose={setPropose}
                        initMessage={initMessage}
                    />
                );

            default:
                return (
                    <div className="d-flex flex-column" style={{height: '100vh'}}>
                        <CardHeader className="border-bottom pt-2">
                            <div className="row justify-content-center">
                                <ChatNavigation
                                    isBot={isBot}
                                    bookType={bookType}
                                    setPropose={setPropose}
                                    setSeeContract={setSeeContract}
                                    setAllowEvent={setAllowEvent}
                                    setWarnDecline={setWarnDecline}
                                    handleChangePersonChat={handleChangePersonChat}
                                />
                            </div>
                        </CardHeader>

                        <EventBanner />

                        <div className="flex-grow-1 overflow-auto">
                            {isBot ? <ChatBot toScroll={toScroll}/> : <ChatArtist toScroll={toScroll}/>}
                        </div>

                        <CardFooter>
                            <ChatFooter isBot={isBot} toScroll={toScroll}/>
                        </CardFooter>
                    </div>
                );
        }
    };

    const loadStripePromise = async () => {
        if (['pending', 'accepted'].includes(contactToChat?.reservation?.status)) {
            checkStripePromise(isDev, setStripPromise, auditor, ttc, code, setClientSecret, null).then((resp) => null)
        }
    }

    useEffect(() => {

        if (!isMounted.current ) {
            loadStripePromise().then(r => null)

            return () => {
                isMounted.current = true
            };
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Card className="card-stats mb-0" style={{height: width < 500 ? '130vh' : '100vh'}}>
            {renderContent()}
        </Card>
    );
}

export default Chat;
