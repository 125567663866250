import apis from "../config/apis";
import {addProfileDataContacts} from "../reducer";
import {checkErrorMessage} from "./Tools";

const CheckContactsData = async (contact_type, auditor, dispatch, page, status, answers) => {
    try {
        const resp = await auditor.get(apis.getContacts(contact_type, page?.toString(), status));
        const response = resp.data;
        const updatedAnswers = {
            ...answers,
            [contact_type]: {
                ...answers[contact_type], // Empêche de perdre d’autres propriétés existantes
                [status]: response.data,
            },
        };

        await dispatch(addProfileDataContacts(updatedAnswers));
        return updatedAnswers
    } catch (error) {
        console.log('error: ', checkErrorMessage(error).message);
        return answers
    }

}

export default CheckContactsData;
