import React from 'react';
import {Grid, Skeleton} from '@mui/material';

const CalendarSkeleton = () => {
    // Tableau des jours pour la disposition de novembre 2024
    const days = [
        ['', '', '', '', '', '1', '2'],
        ['3', '4', '5', '6', '7', '8', '9'],
        ['10', '11', '12', '13', '14', '15', '16'],
        ['17', '18', '19', '20', '21', '22', '23'],
        ['24', '25', '26', '27', '28', '29', '30']
    ];

    return (
        <Grid
            container
            spacing={0.1}
            sx={{
                width: '100%',
                paddingLeft: {
                    xs: '16px',  // Très petit écran
                    sm: '36px',  // Petit écran
                    md: '56px',  // Écran moyen
                    lg: '66px' // Grand écran
                }
            }}>
            {days.flat().map((day, index) => (
                <Grid item xs={1.61} key={index}>
                    {day ? (
                        <Skeleton
                            variant="rectangular"
                            height={35}
                            sx={{
                                width: {
                                    xs: '40px',  // Très petit écran
                                    sm: '60px',  // Petit écran
                                    md: '80px',  // Écran moyen
                                    lg: '105px', // Grand écran
                                },
                                borderRadius: 1
                            }}
                        />
                    ) : (
                        // Cellule vide pour aligner les jours comme dans la disposition demandée
                        <div style={{height: 40}}></div>
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default CalendarSkeleton;
