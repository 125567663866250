// AddressUtils.js
import apis from "../config/apis";

export const fetchSuggestions = async (query, countryCode, cache = {}) => {
    if (query.length <= 2) {
        return []; // Retourne une liste vide si la requête est trop courte
    }

    // Vérifier le cache pour éviter des requêtes inutiles
    if (cache[query]) {
        return cache[query];
    }

    try {
        const response = await fetch(apis.getAddress(query), {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Filtrer les résultats pour le pays spécifié (si applicable)
        const filteredSuggestions = data.features.filter((feature) => {
            const context = feature.context || [];
            const country = context.find((c) => c.id.includes("country"));
            const resultCountryCode = country?.short_code;
            return (
                !countryCode || // Si aucun pays spécifié, ne pas filtrer
                resultCountryCode?.toLowerCase() === countryCode.toLowerCase()
            );
        });

        // Ajouter au cache
        cache[query] = filteredSuggestions;

        return filteredSuggestions;
    } catch (error) {
        console.error("Error fetching suggestions:", error);
        throw new Error("Erreur lors de la récupération des adresses");
    }
};
