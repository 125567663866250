import React from "react";
import {Box, Typography, IconButton, Dialog, DialogContent} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InfoIcon from "@mui/icons-material/Info";
import {CardFooter, Row} from "reactstrap";
import CardHeaderCustom from "../../../tools/CardHeaderCustom";
import {btnStyle} from "../../../tools/Tools";

function EssentialPoints({openModal, handleOpenModal, handleCloseModal}) {
    return (
        <>
            <Box
                sx={{
                    p: 1,
                    gap: 1,
                    borderRadius: 1,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "grey.300",
                }}
            >
                <InfoIcon sx={{color: "#e0e0e0 !important"}} onClick={handleOpenModal}/>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    onClick={handleOpenModal}
                    sx={{fontWeight: "bold", cursor: "pointer"}}
                >
                    Points essentiels à considérer
                </Typography>
            </Box>

            <Dialog open={openModal} onClose={handleCloseModal}>
                <CardHeaderCustom cardTitle="Points essentiels à considérer"/>
                <DialogContent>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{textAlign: "justify", mb: 2}}
                    >
                        Veuillez noter les points suivants :
                    </Typography>
                    <ol style={{paddingLeft: "20px", color: "#555", textAlign: "justify"}}>
                        <li style={{marginBottom: "10px", lineHeight: "1.5"}}>
                            Le document doit contenir vos nom et prénom officiels (Passeport ou carte d'identité du pays
                            de résidence).
                        </li>
                        <li style={{marginBottom: "10px", lineHeight: "1.5"}}>
                            Si le nom que vous avez renseigné sur Kantobiz diffère de celui indiqué
                            sur le document, votre certification sera automatiquement invalidée.
                        </li>
                    </ol>
                </DialogContent>
                <CardFooter>
                    <Row className="justify-content-end mx-1">
                        <Tooltip title="Valider" placement="top">
                            <IconButton
                                size="small"
                                aria-label="settings"
                                sx={btnStyle("lightGreen")}
                                onClick={() => handleCloseModal()}
                            >
                                <small>J'ai compris&nbsp;</small>
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Dialog>
        </>
    );
}

export default EssentialPoints;
