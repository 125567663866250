import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InfoIcon from '@mui/icons-material/Info';
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {FormControl} from "@mui/material";
import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {
    addServiceFormDurService,
    addServiceFormEvents,
    addServiceFormGalleries,
    addServiceFormPrepTime,
    addServiceFormPrice,
    addServiceFormUnitDurService,
    addServiceFormUnitPrepTime
} from "../../reducer";
import {
    calculateValue,
    changeFields,
    IPriceField,
    ISelectSearch,
    iTim,
    labelFormatDecode,
    setFrom,
    useWindowSize
} from "../../tools/Tools";
import {useFlexyTagStyles} from '@mui-treasury/styles/tag/flexy';
import {usePushingGutterStyles} from '@mui-treasury/styles/gutter/pushing';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import {marks, songMarks} from "../../tools/Variables";

function ServiceDetailForm({upDate, newArtist}) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const classes = useFlexyTagStyles();
    const [edit, setEdit] = useState(false);
    const gutterStyles = usePushingGutterStyles();
    const [currency, setCurrency] = useState("$");
    const role = useSelector(state => state.profile.role);
    const user_type = useSelector(state => state.artistFromData.user_type);
    const {eventTypes, countries} = useSelector(state => state.global);
    const {price, events, country, galleries, preparation_time} = useSelector(state => state.serviceForm);
    const {travel_expenses, duration_of_the_service} = useSelector(state => state.serviceForm);
    const {unit_duration_of_the_service, unit_of_the_preparation_time} = useSelector(state => state.serviceForm);
    const [preparationTime, setPreparationTime] = useState(iTim(unit_of_the_preparation_time, preparation_time));
    const [durationService, setDurationService] = useState(iTim(unit_duration_of_the_service, duration_of_the_service));
    const isArtist = user_type === 'artist_musician' || role === 'artist_musician';

    const updateState = (value, state, upProps) => {
        let tmp = {...state};
        for (let row in tmp) {
            if (row === value)
                tmp[value] = true;
            else tmp[row] = false
        }
        dispatch(upProps(tmp));
    }

    const onchangeUnitOfTime = (value, state, upProps) => {
        if (value === 'hours') {
            updateState('hours', state, upProps)
        } else if (value === 'min') {
            updateState('min', state, upProps)
        } else {
            updateState(value, state, upProps)
        }
    };

    const handleChange = (event, newValue, _f, _a, _b, _c) => {
        // (e, val, setDurationService, addServiceFormDurService, unit_duration_of_the_service, addServiceFormUnitDurService)
        if (typeof newValue === 'number') {

            if (isArtist) {
                _f(newValue);

                dispatch(_a(newValue))

                onchangeUnitOfTime(newValue === 1 ? 'song' : 'song(s)', _b, _c)
            } else {
                _f(newValue);

                const units = ['min', 'hours'];

                let unitIndex = 0;
                let scaledValue = newValue;

                if (scaledValue >= 60) {
                    unitIndex = 1
                    scaledValue = Math.floor(scaledValue / 60)
                }

                dispatch(_a(scaledValue))

                onchangeUnitOfTime(units[unitIndex], _b, _c)
            }
        }
    };

    const onDrop = async (e) => {
        e.preventDefault();

        let uploadedFile;
        let tmpFiles = [...galleries];
        let uploadedFiles = e.target.files || e.dataTransfer.files;
        if (uploadedFiles && uploadedFiles?.length > 5) {
            toast.error('Pas plus de 5 images');
        } else if (uploadedFiles) {
            Array.prototype.map.call(uploadedFiles, async (file) => {
                let {name, size, type} = file;
                let url = URL.createObjectURL(file);
                uploadedFile = {name, size, type, url, file};
                if (uploadedFile.type.split("/")[0] === "image")
                    tmpFiles.push(uploadedFile);
                else toast.error("Fichier non supporté trouver")
            });
            await dispatch(addServiceFormGalleries(tmpFiles));
        }
    };

    const onDragOver = (e) => e.preventDefault();

    const removeFile = async (index) => {
        let array = [...galleries];
        array.splice(index, 1);
        await dispatch(addServiceFormGalleries(array));
    };

    const clickAddPhoto = () => document.getElementsByClassName("input-file service")[0].click();

    useEffect(() => {
        let countryChoice = countries?.filter(tmp => tmp.name === country)[0]
        setCurrency(countryChoice?.currencies.symbol)
    }, [countries, country]);

    return (
        <Row className="justify-content-center pr-2">
            <Col md={12}>
                <FormGroup>
                    {ISelectSearch("Type d'événement ", events, (e, obj) => changeFields(
                            null, obj, addServiceFormEvents, dispatch),
                        eventTypes,
                        true,
                        "• Au moins 2 types"
                    )}
                </FormGroup>
            </Col>
            <Col md={12}>
                <Row>
                    <Col>
                        <FormGroup>
                            {upDate ?
                                <Row>
                                    {/* Si l'offre premium est activée col md={4} && pl-0 pr-2 ml-3*/}
                                    <Col md={12}>
                                        {IPriceField(
                                            'Montant primaire',
                                            price,
                                            currency,
                                            (e) => changeFields(null, e, addServiceFormPrice, dispatch),
                                            "• Montant de départ.")}
                                    </Col>

                                    {/* Non disponible pour le moment - Pour l'offre premium */}
                                    <div hidden>
                                        <Tooltip title="Modifier le frais de transport de cette fiche" placement="top">
                                            <Box className={gutterStyles.parent}>
                                                <Button classes={{root: classes.root, label: classes.label}}>
                                                    <div>
                                                        <span className={classes.overline}>Déplacement</span>
                                                        <div className="media-body mt-1">
                                                            <span>Frais de transport</span>
                                                        </div>
                                                    </div>
                                                    <div className={classes.icon}>
                                                        <div className="align-self-center mt-2">
                                                            <h4>
                                                                <small hidden={edit}>{currency}</small>
                                                                {!edit
                                                                    ? travel_expenses?.from
                                                                    : <InputGroup className="no-border"
                                                                                  style={{width: 150}}>
                                                                        <Input
                                                                            type="number"
                                                                            value={travel_expenses?.from}
                                                                            onChange={(e) => setFrom(e, dispatch)}
                                                                            placeholder={travel_expenses?.from + currency}/>
                                                                        <InputGroupAddon addonType="append">
                                                                            <InputGroupText>
                                                                                <i className="nc-icon nc-send text-success ml-2"
                                                                                   onClick={() => setEdit(false)}
                                                                                />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>}
                                                                <ModeEditOutlinedIcon
                                                                    hidden={edit}
                                                                    onClick={() => setEdit(true)}
                                                                    className="pb-1"
                                                                    sx={{color: "#51cbce!important"}}/>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </Button>
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </Row>
                                : IPriceField(
                                    'Montant primaire',
                                    price,
                                    currency,
                                    (e) => changeFields(null, e, addServiceFormPrice, dispatch),
                                    "• Cachet de départ par prestation.")}
                        </FormGroup>
                    </Col>
                </Row>
                {/* Non disponible pour le moment - Pour l'offre premium */}
                <Row hidden>
                    <Col>
                        <FormGroup>
                            <FormControl variant="filled" sx={{width: "100%!important"}}>
                                <Row className="justify-content-center">
                                    <Typography variant="caption" sx={{ml: 1}} gutterBottom>
                                        Temps de préparation:
                                        <strong>
                                            {labelFormatDecode(preparationTime, isArtist)}
                                        </strong>
                                    </Typography>
                                    <Tooltip
                                        title="Temps nécessaire pour la préparation avant la prestation."
                                        placement="top">
                                        <InfoIcon
                                            className="cursor-pointer"
                                            sx={{color: "blue!important", marginLeft: 1, paddingBottom: 0.5}}/>
                                    </Tooltip>
                                </Row>
                                <Box className="ml-2" sx={{width: width < 400 ? 200 : '100%'}}>
                                    <Slider
                                        min={10}
                                        max={300}
                                        marks={marks}
                                        scale={calculateValue}
                                        value={preparationTime}
                                        step={preparationTime < 60 ? 1 : 60}
                                        getAriaValueText={(value) => labelFormatDecode(value, isArtist)}
                                        valueLabelFormat={(value) => labelFormatDecode(value, isArtist)}
                                        onChange={(e, val) => handleChange(e, val, setPreparationTime, addServiceFormPrepTime, unit_of_the_preparation_time, addServiceFormUnitPrepTime)}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="non-linear-slider"
                                    />
                                </Box>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <FormControl variant="filled" sx={{width: "100%!important"}}>
                                <Row className="justify-content-start ml-3">
                                    <Typography variant="caption" gutterBottom>
                                        {isArtist ? "Musique minimum jouer" : "Durée minimale de la prestation"}:&nbsp;
                                        <strong>
                                            {labelFormatDecode(durationService, isArtist)}
                                        </strong>
                                    </Typography>
                                    <Tooltip
                                        title="Équivalent au temps minimum de la prestation à cette fiche pour ce montant."
                                        placement="top">
                                        <InfoIcon
                                            className="cursor-pointer"
                                            sx={{color: "blue!important", marginLeft: 1, paddingBottom: 0.5}}/>
                                    </Tooltip>
                                </Row>
                                <Box className="ml-2" sx={{width: !newArtist ? width < 400 ? 200 : '95%' : '95%'}}>
                                    {isArtist
                                        ? <Slider
                                            min={1}
                                            max={10}
                                            step={1}
                                            marks={songMarks}
                                            value={durationService}
                                            getAriaValueText={(value) => labelFormatDecode(value, isArtist)}
                                            valueLabelFormat={(value) => labelFormatDecode(value, isArtist)}
                                            onChange={(e, val) => handleChange(e, val, setDurationService, addServiceFormDurService, unit_duration_of_the_service, addServiceFormUnitDurService)}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="non-linear-slider"
                                        />
                                        : <Slider
                                            min={10}
                                            max={300}
                                            marks={marks}
                                            scale={calculateValue}
                                            value={durationService}
                                            step={durationService < 60 ? 1 : 60}
                                            getAriaValueText={(value) => labelFormatDecode(value, isArtist)}
                                            valueLabelFormat={(value) => labelFormatDecode(value, isArtist)}
                                            onChange={(e, val) => handleChange(e, val, setDurationService, addServiceFormDurService, unit_duration_of_the_service, addServiceFormUnitDurService)}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="non-linear-slider"
                                        />}
                                </Box>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Row>
                    <Col>
                        <div className="text-center">
                            <div className="mb-2 text-center">
                                <Tooltip title="2 images minimum" placement="top">
                                    <div className="dragBox m-auto rounded cursor-pointer"
                                         style={{border: "dashed 1px black", height: "100px"}}
                                         onClick={() => clickAddPhoto()}
                                         onDragOver={onDragOver}
                                         onDrop={onDrop}>
                                        <small className="pt-3">Déposer les images ici</small>
                                        <div className="row justify-content-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                                                 viewBox="0 0 64 64">
                                                <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2"
                                                   fill="none"
                                                   stroke="#0a0909" strokeMiterlimit="10">
                                                    <polyline points="32 55 32 32 32 36" stroke="#0a0909"/>
                                                    <polyline points="21 43 32 32 43 43" stroke="#0a0909"/>
                                                    <path
                                                        d="M40,54.918A24,24,0,1,0,14.185,28.066,13.5,13.5,0,0,0,15.5,55H24"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <input type="file"
                                               hidden={true}
                                               className="input-file service"
                                               accept="image/png, image/jpeg"
                                               onChange={onDrop}
                                               multiple
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                            {galleries?.length > 0 ?
                                <div className="text-center mt-2">
                                    <div className="row justify-content-center">
                                        {galleries.map((file, index) =>
                                            <div className="border cursor-pointer border-dark rounded m-1 pr-1"
                                                 key={index}>
                                                <img width={50}
                                                     height={50}
                                                     className="rounded m-1"
                                                     alt={"service_image" + index}
                                                     src={file.url ? file.url : file}
                                                />
                                                <Tooltip title="Supprimer" placement="top">
                                                    <svg key={index} onClick={() => removeFile(index)} height="32"
                                                         width="32"
                                                         viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="#0a0909" stroke="#ED1C24" strokeLinecap="square"
                                                           strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1">
                                                            <path d="M27,10V28a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10"
                                                                  fill="none"
                                                                  stroke="#ED1C24"/>
                                                            <line fill="none" stroke="#ED1C24" x1="16" x2="16" y1="15"
                                                                  y2="25"/>
                                                            <line fill="none" stroke="#ED1C24" x1="11" x2="11" y1="15"
                                                                  y2="25"/>
                                                            <line fill="none" stroke="#ED1C24" x1="21" x2="21" y1="15"
                                                                  y2="25"/>
                                                            <line fill="none" x1="30" x2="2" y1="6" y2="6"/>
                                                            <polyline fill="none" points="11 6 11 1 21 1 21 6"
                                                                      strokeLinecap="butt"/>
                                                        </g>
                                                    </svg>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                </div> : null}
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ServiceDetailForm;
