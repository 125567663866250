import React, {useState, useRef} from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Drawer from '@mui/material/Drawer';
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Modal from "react-bootstrap/Modal";
import {useSelector} from "react-redux";
import {CardBody, CardFooter, Nav, Row,} from "reactstrap";
import {useGradientAvatarStyles} from '@mui-treasury/styles/avatar/gradient';
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {btnStyle, useWindowSize} from "../../tools/Tools";
import NewArtist from "../../views/NewArtist";
import Certification from "../../views/profile/Certifications/Certification";
import Profile from "../../views/profile/Profile";

function Account() {

    const width = useWindowSize();
    const certifyRef = useRef();
    const [toArtist, setToArtist] = useState(false);
    const profile = useSelector(state => state.profile.profileData);
    const [process, setProcess] = useState(false);
    const [state, setState] = useState({right: false});
    const [editCertification, setEditCertification] = useState(false);
    const styles = useGradientAvatarStyles({
        size: 18,
        gap: 2,
        thickness: 3,
        gapColor: '#f4f7fa',
        color: 'linear-gradient(to bottom right, #feac5e, #c779d0, #4bc0c8)',
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const closeCertify = () => {
        document.getElementById("profile_open").click()
        setEditCertification(false)
    }

    const sendToValidate = () => {
        setProcess(true)
        certifyRef?.current?.validate()
        setTimeout(() => setProcess(false), 5000);
    }

    return (
        <Nav navbar>

            <Modal size="md" show={toArtist}>
                <NewArtist toClose={() => setToArtist(false)} setProcess={setProcess}/>
                <CardFooter>
                    <Row className="justify-content-end">
                        <Tooltip title="Fermer cette onglet" placement="top">
                            <IconButton
                                disabled={process}
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('red')}
                                onClick={() => setToArtist(false)}>
                                <small>Fermer&nbsp;</small>
                                <CloseIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            <Modal size="lg" show={editCertification}>
                <CardHeaderCustom cardTitle="Certification" closeModal={() => closeCertify()}/>

                <CardBody>
                    <Certification ref={certifyRef} closeCertification={() => closeCertify()}/>
                </CardBody>

                <CardFooter>
                    <Row className="justify-content-end mx-1">
                        <Tooltip title="Valider" placement="top">
                            <IconButton
                                size="small"
                                disabled={process}
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => sendToValidate()}>
                                <small>Envoyer ma demande&nbsp;</small>
                                <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            <Tooltip title="Mon profil" placement="bottom">
                <IconButton id="profile_open" sx={{p: '10px'}} onClick={toggleDrawer('right', true)}>
                    <div className={styles.root}>
                        {profile?.photo && profile?.photo !== "null"
                            ? <Avatar src={profile?.photo}/>
                            : <Avatar className={styles.logo}>
                                {profile?.artist_name?.substring(0, 1).toUpperCase() || profile?.name?.substring(0, 1).toUpperCase()}
                            </Avatar>}
                    </div>
                </IconButton>
            </Tooltip>

            <button hidden id="certification_button" onClick={() => setEditCertification(true)}/>

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >

                <Box sx={{width: width < 500 ? width : 450}} role="presentation">
                    <Profile setToArtist={setToArtist} toCloseProfile={() => setState({...state, 'right': false})}/>
                </Box>
            </Drawer>

        </Nav>
    );
}

export default Account;
