import React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";

function CustomContactStepper({steps, activeStep, sendBtn, backBtn}) {
    const {send, tabIndex} = useSelector((state) => state.toContact);

    return (
        <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel
                        optional={
                            index === steps.length - 1 && (
                                <Typography variant="caption">Dernière étape</Typography>
                            )
                        }
                    >
                        {step.label}
                    </StepLabel>
                    <StepContent>
                        <Typography>{step.description}</Typography>
                        <Box sx={{mt: 2, mb: 2}}>
                            {step.input}
                            {step.button}
                            {index === steps.length - 1 && (
                                <>
                                    {tabIndex === 0 && send ? null : sendBtn()}
                                    {backBtn()}
                                </>
                            )}
                            {index > 0 && index !== steps.length - 1 && backBtn()}
                        </Box>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}

export default CustomContactStepper;
