import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import UndoIcon from '@mui/icons-material/Undo';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import React, {useState} from "react";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import toast from "react-hot-toast";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {useDispatch, useSelector} from "react-redux";
import {ImpulseSpinner} from "react-spinners-kit";
import {CardFooter, Row} from "reactstrap";
import {addBotMessageList, switchBotMsgProcess, switchBotLoadingAnswer, switchLoadingDiscussions} from "../../reducer";
import {checkErrorMessage, clearContactToChat, customButton, upCardEvents} from "../../tools/Tools";
import PaymentsIcon from '@mui/icons-material/Payments';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';

function AllowEvent({isBot, setToPay, setAllowEvent}) {

    const dispatch = useDispatch();
    const [process, setProcess] = useState(false);
    const {messagesList} = useSelector(state => state.chatBot);
    const userId = useSelector(state => state.profile.userId);
    const auditor = useSelector(state => state.axios.auditor);
    const contacts = useSelector(state => state.profile.contacts);
    const {contactToChat, countries} = useSelector(state => state.global);
    let isArtistConnected = parseInt(contactToChat?.reservation?.artist_owner_id) === parseInt(userId)


    const closeChat = async () => {
        await clearContactToChat(dispatch)
        dispatch(switchBotMsgProcess(true))
        isBot && dispatch(addBotMessageList([messagesList[0]]))
    }

    const artistAccept = async () => {
        setProcess(true)
        isBot && dispatch(switchBotLoadingAnswer(true))
        await auditor.put("reservation/artist_accept/" + contactToChat?.reservation.id).then(async (resp) => {
            await upCardEvents(resp.data, contacts, dispatch, 'auditor', countries)
            isBot && dispatch(switchBotLoadingAnswer(false))
            setProcess(false)
            setAllowEvent(false)
            await closeChat()
            await dispatch(switchLoadingDiscussions(true))
            toast("Vous avez accepter la proposition." +
                "\nL'événement sera ajouté à votre agenda dès que l'auditeur aura signé la confirmation, et vous recevrez une notification.", {
                icon: <ThumbUpIcon sx={{color: "lightGreen!important"}}/>,
            });
        }).catch((error) => {
            setProcess(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    const dispatchFunction = () => {
        if (isArtistConnected) {
            artistAccept().then(r => null)
        } else {
            setAllowEvent(false)
            setToPay(true)
        }
    }

    const cardFooter = () => {
        return (
            <CardFooter className="mt-4">
                <Row className="justify-content-end mx-1">
                    <Button
                        size="small"
                        hidden={process}
                        variant="outlined"
                        aria-label="delete"
                        onClick={() => setAllowEvent(false)}
                        sx={customButton}
                    >
                        <UndoIcon fontSize="inherit"/>
                    </Button>

                    <Button
                        size="small"
                        hidden={process}
                        variant="outlined"
                        className="ml-3"
                        endIcon={
                            !isArtistConnected
                                ? <PaymentsIcon sx={{color: "lightGreen!important"}}/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                        }
                        onClick={() => dispatchFunction()}
                        sx={customButton}
                    >
                        {isArtistConnected ? "Accepter l'événement" : "D'accord"}
                    </Button>

                    <div style={{margin: 12, paddingTop: 3}} hidden={!process}>
                        <ImpulseSpinner size={25} frontColor="#f5f5f5"/>
                    </div>
                </Row>
            </CardFooter>
        )
    }

    return (
        <CardContent>
            <TextInfoContent
                useStyles={useBlogTextInfoContentStyles}
                overline={isArtistConnected ? "J'accepte la proposition" : "Fixer cet événement"}
                heading={isArtistConnected ? 'Pour la suite ?' : 'Dernières étapes'}
                body={
                    isArtistConnected
                        ? <div>
                            <span className="text-muted">
                                • L'événement sera ajouté à votre agenda dès que l'auditeur aura signé la confirmation, et vous recevrez une notification.
                            </span>
                            {cardFooter()}
                        </div>
                        : <div>
                            <span className="text-muted">
                                • Vous serez rédirigez vers l'étape de payment.
                            </span>
                            <br/>
                            <span className="text-muted">
                                • Cela fixera l'événement dans votre agenda et celui de l'artiste.
                            </span>
                            <br/>
                            <span className="text-muted">
                                • L'artiste ne sera rémunéré qu'à la fin de sa prestation.
                            </span>
                            <br/>
                            <span className="text-muted">
                                • Le chat restera actif jusqu'à la conclusion de l'événement.
                            </span>
                            <br/>
                            <span className="text-muted">
                                • Utilisez le chat pour plus d'infos.
                            </span>
                            {cardFooter()}
                        </div>
                }
            />
        </CardContent>
    )
}

export default AllowEvent;
