import InfoIcon from '@mui/icons-material/Info';
import {Button, IconButton, TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addServiceFormTravelExp} from "../../reducer";
import {HtmlTooltip, setFrom, setTo} from "../../tools/Tools";

function TravelExpense() {

    const dispatch = useDispatch();
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [activeExpenseTo, setActiveExpenseTo] = useState(false);
    const [activeExpenseFrom, setActiveExpenseFrom] = useState(false);
    const {travel_expenses} = useSelector(state => state.serviceForm);
    const {countries} = useSelector(state => state.global);
    const {conditions, profileData} = useSelector(state => state.profile);
    const {is_certified, currency} = profileData

    useEffect(() => {
        countries?.forEach((tmpCountry) => {
            if (tmpCountry?.currencies?.code?.toLowerCase() === currency?.toLowerCase()) {
                setCurrencySymbol(tmpCountry?.currencies?.symbol);
            }
        });
    }, [countries, currency]);

    return (
        <div className="row justify-content-center m-2">

            <div className="col-10 justify-content-center row">
                <em>
                    • C'est quoi un frais de transport ?&nbsp;
                    <IconButton sx={{p: '2px'}} aria-label="menu" small>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <React.Fragment>
                                    <em>{"• Il s'agit de vos frais de déplacement par défaut applicable à toute votre fiche prestation (Fiche KantoBiz) si ce dernier n'est pas encore personnalisé. "}</em>
                                    <br/>
                                    <em>{"• Vous pouvez modifier le frais de transport spécifique d'une fiche prestation (Fiche KantoBiz)."}</em>
                                    <br/>
                                    <em>{"• Le frais de transport général par défaut est de 0 et personnalisable en fonction de vos estimations."}</em>
                                </React.Fragment>
                            }
                        >
                            <InfoIcon sx={{marginBottom: 1}}/>
                        </HtmlTooltip>
                    </IconButton>
                </em>
                <em>
                    • Savoir comment ça marche ?&nbsp;
                    <IconButton sx={{p: '2px'}} aria-label="menu" small>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <React.Fragment>
                                    <em>{"• Ce prix sera ajouté à chaque réservation. "}</em>
                                    <br/>
                                    <em>{"• Si le montant n'est pas fixe. l'auditeur prend un prix dans la fourchette."}</em>
                                    <br/>
                                    <em>{"• Le montant par défaut si fourchette est la moitié."}</em>
                                </React.Fragment>
                            }
                        >
                            <InfoIcon sx={{marginBottom: 1}}/>
                        </HtmlTooltip>
                    </IconButton>
                </em>
            </div>

            <div className="col-10 justify-content-center row m-2 pt-2">
                {activeExpenseFrom && !activeExpenseTo
                    ? <TextField
                        rows={4}
                        type="number"
                        variant="filled"
                        value={travel_expenses.from}
                        label="Frais de transport"
                        id="filled-start-adornment"
                        placeholder={"Tapez ici votre frais de transport"}
                        helperText={"• Cette valeur s'applique à chaque nouveau(x) fiche prestation (Fiche KantoBiz). Cependant vous pouvez aussi " +
                            "personnaliser le frais de chaque fiche prestation (Fiche KantoBiz)."}
                        sx={{m: 1, width: '25ch'}}
                        onChange={(e) => setFrom(e, dispatch)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                        }}
                    />
                    : activeExpenseTo && !activeExpenseFrom ? <div className="row">
                        <TextField
                            rows={4}
                            type="number"
                            variant="filled"
                            value={travel_expenses.from}
                            label="Frais de transport minimum"
                            id="filled-start-adornment"
                            placeholder={"Tapez ici votre frais de transport minimum"}
                            sx={{m: 1, width: '25ch'}}
                            onChange={(e) => setFrom(e, dispatch)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                            }}
                        />
                        <TextField
                            rows={4}
                            type="number"
                            variant="filled"
                            value={travel_expenses.to}
                            label="Frais de transport maximum"
                            id="filled-start-adornment"
                            placeholder={"Tapez ici votre frais de transport minimum"}
                            sx={{m: 1, width: '25ch'}}
                            onChange={(e) => setTo(e, dispatch, travel_expenses)}
                            helperText={"• Par défaut, pendant la réservation\n, le frais de transport sera le minimum " +
                                "de la fourchette. Ensuite l'auditeur qui vous réserve pourrait ajuster le frais."}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                            }}
                        />
                    </div> : null}
            </div>

            {activeExpenseFrom || activeExpenseTo ?
                <div className="col-10 justify-content-center row m-2 pt-2">
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={async () => {
                            setActiveExpenseTo(false)
                            setActiveExpenseFrom(false)
                            await dispatch(addServiceFormTravelExp({'from': 0, 'to': 0}))
                        }}
                    >
                        Annuler et révenir
                    </Button>
                </div> : null}

            {!activeExpenseFrom && !activeExpenseTo &&
                <div className="col-10 justify-content-center row m-2 pt-2">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!is_certified}
                        onClick={() => {
                            setActiveExpenseTo(false);
                            setActiveExpenseFrom(true);
                        }}
                        startIcon={<InfoIcon/>}
                    >
                        Fixer un prix
                    </Button>
                </div>
            }

            {!activeExpenseTo && !activeExpenseFrom &&
                <div className="col-10 justify-content-center row m-2">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!is_certified}
                        onClick={() => {
                            setActiveExpenseTo(true);
                            setActiveExpenseFrom(false);
                        }}
                        startIcon={<InfoIcon/>}
                    >
                        Faire une fourchette de prix
                    </Button>
                </div>
            }

            <div className="justify-content-center row mt-4">
                {!conditions?.travel_expenses.to && !conditions?.travel_expenses.from
                    ? <small>
                        • Votre frais de transport actuel est <strong>GRATUIT</strong>
                    </small>
                    : <small>
                        • Votre frais de transport actuel est de&nbsp;
                        <strong>
                            {conditions?.travel_expenses.to
                                ? conditions?.travel_expenses.from + currencySymbol
                                + " jusqu'à "
                                + conditions?.travel_expenses.to + currencySymbol
                                : conditions?.travel_expenses.from + currencySymbol}
                        </strong>
                    </small>}
                {!is_certified && (
                    <small className="text-secondary">
                        • Veuillez <strong>certifier votre
                        compte</strong> avant d'utiliser cet option.
                    </small>
                )}
            </div>
        </div>
    );
};

export default TravelExpense;
