import React from 'react';
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {CardHeader, CardTitle, Row} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";

function CardHeaderCustom({cardTitle, closeModal, disabled, children}) {
    return (
        <CardHeader style={{padding: !closeModal ? '20px' : '12px'}}>
            <Row className="justify-content-between align-items-center mx-1">
                <CardTitle tag="h6">{cardTitle}</CardTitle>
                {closeModal
                    ? <Tooltip title="Fermer cet onglet" placement="top">
                        <IconButton
                            size="small"
                            disabled={disabled}
                            aria-label="settings"
                            onClick={closeModal}>
                            <CloseIcon sx={{color: "gray!important"}}/>
                        </IconButton>
                    </Tooltip> : null}
            </Row>
            {children && <div>{children}</div>}
        </CardHeader>
    );
}

export default CardHeaderCustom;
