import toast from "react-hot-toast";
import React, {useEffect, useState} from 'react';
import {GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';
import {jwtDecode} from "jwt-decode";
import {useSelector} from "react-redux";
import Conf from "../../config/tsconfig";
import {checkErrorMessage} from "../../tools/Tools";

function LoginGoogle(props) {
    const {LogIn, callBack, _setLoad, disable} = props;
    const [disabled, setDisabled] = useState(disable);
    const [loading, setLoading] = useState(false);
    const currency = useSelector(state => state.global.globalCurrencyCode);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const auth = useSelector(state => state.session.authenticated);
    const googleData = useSelector(state => state.artistFromData.googleData);

    const googleAuth = async (data) => {
        let bodyFormData = new FormData();
        bodyFormData.append('currency', currency);
        bodyFormData.append('google_data', JSON.stringify(data));

        try {
            const response = await auditorForm.post("users/gCallback", bodyFormData);
            await callBack(response.data);
        } catch (error) {
            _setLoad(false);
            toast.error(checkErrorMessage(error).message === "You need Password"
                ? "Un mot de passe est requis pour ce compte."
                : checkErrorMessage(error).message);
        } finally {
            setLoading(false);
        }
    };

    const onSuccess = (credentialResponse) => {
        _setLoad && _setLoad(true);
        setLoading(true);

        // Décodage du JWT pour extraire les données utilisateur
        const decoded = jwtDecode(credentialResponse.credential);
        const userProfile = {
            googleId: decoded.sub,
            imageUrl: decoded.picture,
            email: decoded.email,
            name: decoded.name,
            givenName: decoded.given_name,
            familyName: decoded.family_name,
        };

        if (LogIn) {
            googleAuth(userProfile).then(r => null);
        } else {
            callBack(userProfile);
            _setLoad && _setLoad(false);
            setLoading(false);
        }
    };

    const onFailure = () => {
        toast.error("Échec de l'authentification Google");
        setDisabled(true);
    };

    useEffect(() => {
        if (auth) setDisabled(true);
    }, [auth]);

    return (
        <GoogleOAuthProvider clientId={Conf.configs.GoogleId}>
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
                disabled={disabled || loading || Object.keys(googleData)?.length !== 0}
                theme="outline"
                size="medium"
            />
        </GoogleOAuthProvider>
    );
}

export default LoginGoogle;
