import React from "react";
import {useNavigate} from "react-router-dom";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import {useWindowSize} from "../../tools/Tools";

function Footer({fluid}) {
    const navigate = useNavigate();
    const width = useWindowSize();

    return (
        <footer
            style={{
                left: 0,
                bottom: 0,
                position: 'fixed',
                width: `calc(100% - ${width > 993 || !width ? 250 : 0}px)`, // Adjust the width when the sidebar is open
                marginLeft: width > 993 || !width ? 260 : 0, // Offset the navbar when the sidebar is visible
                backgroundColor: '#f4f3ef',
                zIndex: 1030
            }}
            className={"footer"}>
            <Container fluid={fluid}>
                <nav className="credits col">
                    <div className="copyright">
                        <div className="row justify-content-center">
                            <div>
                                <Tooltip title="Conditions générales d'utilisations" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/privacy')}>
                                        Conditions générales d'utilisations
                                    </small>
                                </Tooltip>
                            </div>
                            <small style={{marginTop: 2}}>&nbsp;|&nbsp;</small>
                            <div>
                                <Tooltip title="A propos de nous" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/about')}>
                                        A propos de nous
                                    </small>
                                </Tooltip>
                            </div>
                            <small style={{marginTop: 2}}>&nbsp;|&nbsp;</small>
                            <div>
                                <Tooltip title="Foires aux questions" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/faq')}>
                                        Faq
                                    </small>
                                </Tooltip>
                            </div>
                            <small style={{marginTop: 2}}>&nbsp;|&nbsp;</small>
                            <div>
                                <Tooltip title="Copyright" placement="top">
                                    <small className="cursor-pointer border-bottom"
                                           onClick={() => navigate('/')}>
                                        KantoBiz &copy; {1900 + new Date().getYear()}
                                    </small>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </nav>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
