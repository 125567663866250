import React from 'react';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from '@mui/icons-material/Message';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Badge from "@mui/material/Badge";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import {useDispatch, useSelector} from "react-redux";
import {setMsgTabIndex, switchLoadingDiscussions} from "../../../reducer";
import {useWindowSize} from "../../../tools/Tools";

function DiscussionsHeader({setBooked, initMessage, toggleDrawer}) {

    const dispatch = useDispatch();
    const width = useWindowSize();
    const {msgTabIndex} = useSelector(state => state.global);
    const {role, contactsLength} = useSelector(state => state.profile);

    const handleChange = async (event, newValue) => {
        await dispatch(switchLoadingDiscussions(true))
        await dispatch(setMsgTabIndex(newValue))
        await setBooked([])
        await initMessage(newValue);
    };

    return (
        <div>
            <div className={width > 500 ? "justify-content-center" : null}>
                <TextInfoContent
                    useStyles={useN04TextInfoContentStyles}
                    overline={width > 500 ? 'Échange sur les événements' : null}
                    heading={width > 500 ? "Discussions" :
                        <div className="row m-3 justify-content-between">
                            Discussions
                            <InputAdornment position="start" sx={{mt: 4}}>
                                <Button
                                    id="close_message"
                                    onClick={toggleDrawer('right', false)}
                                    variant="outlined"
                                    endIcon={<CloseIcon/>}>
                                    ESC
                                </Button>
                            </InputAdornment>
                        </div>}
                />
            </div>

            {role !== 'professional_auditor' &&
                <div className="row justify-content-center">
                    <BottomNavigation
                        style={{width: '100%'}}
                        className="mb-3"
                        showLabels
                        value={msgTabIndex}
                        onChange={handleChange}>
                        <BottomNavigationAction
                            label="Avec les auditeurs"
                            value="auditor"
                            icon={
                                <Badge
                                    color="secondary"
                                    badgeContent={contactsLength['auditor']?.accepted}>
                                    <RateReviewIcon/>
                                </Badge>}
                        />
                        <BottomNavigationAction
                            label="Avec les artistes"
                            value="book"
                            icon={
                                <Badge
                                    color="secondary"
                                    badgeContent={contactsLength['book']?.accepted}>
                                    <MessageIcon/>
                                </Badge>}
                        />
                    </BottomNavigation>
                </div>}
        </div>
    )
}

export default DiscussionsHeader;
