import Divider from "@material-ui/core/Divider";
import {Info, InfoTitle} from "@mui-treasury/components/info";
import React from "react";
import {Row} from "reactstrap";
import {ServiceCard} from "../views/services/ServiceCard";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";

function ServiceSliders({artistGenre, dataList}) {

    return (
        <div className="m-2">
            <Info className="mb-2 mt-4" useStyles={useThemeXsInfoStyles}>
                <InfoTitle>{artistGenre}</InfoTitle>
            </Info>
            <Divider/>
            <Row className="justify-content-start overflow-auto">
                <div className="mt-2">
                    <ServiceCard itemKey="artist_musician" data={dataList}/>
                </div>
            </Row>
        </div>
    )
}

export default ServiceSliders;
