import dayjs from "dayjs";
import React from "react";
import {Column} from "@mui-treasury/components/flex";
import {Info, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useChatzInfoStyles} from "@mui-treasury/styles/info/chatz";
import {Row} from "reactstrap";


function ContactReview(contactForm, country, currency) {

    const {name, lastname, reservation} = contactForm;
    const {
        event,
        total_amount,
        travel_expenses,
        address,
        event_date,
        description,
        postal_code
    } = reservation;

    return (
        <div>
            <Column className="m-3">
                <Info useStyles={useChatzInfoStyles}>
                    <InfoTitle>Détails sur l'événement proposée</InfoTitle>
                    {event_date
                        ? <InfoSubtitle className="mt-2">
                            Le&nbsp;
                            <strong>{dayjs(event_date).format("DD-MM-YYYY")}</strong>
                            &nbsp;à partir de&nbsp;
                            <strong>{dayjs(event_date).format("HH:mm A")}</strong>
                        </InfoSubtitle>
                        : null}
                </Info>
                <Info useStyles={useChatzInfoStyles} className="m-3">
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            • Auditeur
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>
                                {name + ' ' + lastname}
                            </strong>
                        </InfoSubtitle>
                    </Row>
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            • Type d'événement
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>{event}</strong>
                        </InfoSubtitle>
                    </Row>
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            • Addresse
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>{address}</strong>
                        </InfoSubtitle>
                    </Row>
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            • Code Postal
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>{postal_code}</strong>
                        </InfoSubtitle>
                    </Row>
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            • Pays
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>{country}</strong>
                        </InfoSubtitle>
                    </Row>
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            • Montant proposée
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>{total_amount + ' ' + currency}</strong>
                        </InfoSubtitle>
                    </Row>
                    <Row className="justify-content-between">
                        <InfoSubtitle>
                            + Frais de transport
                        </InfoSubtitle>
                        <InfoSubtitle>
                            <strong>
                                {travel_expenses ? travel_expenses + ' ' + currency : '*Offert'}
                            </strong>
                        </InfoSubtitle>
                    </Row>
                </Info>
                {description ?
                    <Info useStyles={useChatzInfoStyles}>
                        <InfoTitle>À propos</InfoTitle>
                        <InfoSubtitle className="mt-2 text-justify">
                            {description}
                        </InfoSubtitle>
                    </Info>
                    : null}
            </Column>
        </div>
    )
}

export default ContactReview;
