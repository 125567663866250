import axios from "axios"
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Spinner} from "reactstrap";
import {sessionService} from 'redux-react-session';
import apis from "./config/apis";
import Conf from "./config/tsconfig.json";
import Home from "./layouts/Home";
import {
    addArtistTypes,
    addCountries,
    addCountryLog,
    addCountryLogId,
    addEventTypes,
    addGlobalCurrencyCode,
    addGlobalCurrencySymbol,
    addIPAddress, addProfileRole,
    configAxios,
    setCountryCode, setIsDev,
} from "./reducer";
import {cleanAuthData, fetchUserData} from "./tools/Tools";


function App() {

    const now = moment()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    let headers = {
        'Content-Type': 'application/json',
        'Language': 'fr',
        'Access-Control-Allow-Origin': "*",
    }

    const loadSession = async (ipAddress) => {
        await sessionService.loadSession().then((currentSession) => {
            sessionService.loadUser().then(async (data) => {
                headers['Isl-Token'] = currentSession.token
                axios.get(apis.isTokenValid, {headers: headers}).then(async () => {
                    await dispatch(configAxios({ipAddress: ipAddress, token: currentSession.token}))
                    await dispatch(addIPAddress(ipAddress))
                    await fetchUserData(currentSession.token, dispatch);
                }).catch(async () => {
                    await sessionService.deleteSession()
                    await sessionService.deleteUser()
                    await cleanAuthData(dispatch)
                    dispatch(addProfileRole(''))
                    await dispatch(configAxios({ipAddress: ipAddress, token: Conf.configs.TokenVisitor}))
                });
            });
        }).catch(() => {
            // HomeRoot.notOnline();
        });
    }

    const dispatchCountry = (countryChoice) => {
        dispatch(addCountryLog(countryChoice.name))
        dispatch(addCountryLogId(countryChoice.id))
        dispatch(setCountryCode(countryChoice.country_code))
        dispatch(addGlobalCurrencySymbol(countryChoice?.currencies?.symbol));
        dispatch(addGlobalCurrencyCode(countryChoice?.currencies?.code));
    }

    const loadArtistAndEventTypes = async () => {
        try {
            const {data} = await axios.get(apis.getArtistTypes, {headers});
            dispatch(addArtistTypes(data.artist_types));
            dispatch(addEventTypes(data.events));
        } catch (error) {
            console.error("Erreur lors du chargement des types d'artiste et événements :", error);
        }
    };

    /**
     * Charger et gérer les Pays (avec géolocalisation si nécessaire).
     */
    const loadCountries = async () => {
        try {
            const {data} = await axios.get(apis.getAllCountries, {headers});
            await dispatch(addCountries(data));

            const lastUpdatedDate = moment(JSON.parse(localStorage.getItem("lastUpdatedDate")));
            const elapsedDuration = moment.duration(moment().diff(lastUpdatedDate));

            // Vérifier si la mise à jour est nécessaire (>= 12 heures)
            if (!lastUpdatedDate._isValid || elapsedDuration._data?.hours >= 12) {
                const resp = await axios.get(Conf.configs.IPGeolocationAPI);
                const countryChoice = data.find(country =>
                    country['name'].toLowerCase() === resp.data["country_name"].toLowerCase() ||
                    resp.data["country_name"].toLowerCase() === country['nativeName'].toLowerCase()
                );

                if (countryChoice) {
                    localStorage.setItem('country', JSON.stringify(countryChoice));
                    localStorage.setItem('lastUpdatedDate', JSON.stringify(now));
                    dispatchCountry(countryChoice);
                }

                // Chargez également la session avec l'adresse IP récupérée
                await loadSession(resp.data["ip_address"]);
            } else {
                const countryChoice = JSON.parse(localStorage.getItem('country'));
                dispatchCountry(countryChoice);
            }
        } catch (error) {
            console.error("Erreur lors du chargement des pays :", error);
        }
    };

    /**
     * Fonction principale appelée dans le useEffect.
     */
    const initializeApp = async () => {
        try {
            await loadSession(); // Chargement initial de la session
            await Promise.all([
                loadArtistAndEventTypes(),
                loadCountries(),
            ]);

            dispatch(setIsDev(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'));
            setLoading(false); // Indiquer que le chargement est terminé
        } catch (error) {
            console.error("Erreur lors de l'initialisation :", error);
        }
    };

    useEffect(() => {
        initializeApp().then(r => null);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        localStorage.getItem('lastUpdatedDate') && loading
            ? <div className="d-flex justify-content-md-center align-items-center vh-100 bg-default-0">
                <div className="flex m-auto">
                    <Spinner type="grow" size="lg"/>
                </div>
            </div>
            : !localStorage.getItem('lastUpdatedDate') && loading
                ? <div className="d-flex justify-content-md-center align-items-center vh-100 bg-default-0">
                    <div className="flex m-auto">
                        <div className="row justify-content-center">
                            <h4 className="text-danger">Bienvenue sur Kantobiz</h4>
                        </div>
                        <hr/>
                        <div className="row mt-4 justify-content-center">
                            <p>Contactez l'artiste idéal à votre événement.</p>
                        </div>
                        <div className="row mt-3 justify-content-center">
                            <p className="text-secondary">Chargement...&nbsp;<Spinner type="grow" size="sm"/></p>
                        </div>
                    </div>
                </div>
                : <Home/>
    );
}

export default App;
