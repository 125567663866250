import DoneAllIcon from "@mui/icons-material/DoneAll";
import {FormControl, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useState, useEffect} from 'react';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardBody, CardFooter, Col, FormGroup, Row} from "reactstrap";
import {addReservationAddress, addReservationCity, addReservationPostalCode} from "../../../reducer";
import CardHeaderCustom from "../../../tools/CardHeaderCustom";
import {btnStyle, changeFields, IMultiSelectTools, ITextField, searchCity} from "../../../tools/Tools";

function AddAddressManually({closeModal}) {

    const dispatch = useDispatch();
    const [listOfCity, setListOfCity] = useState([]);
    const auditor = useSelector(state => state.axios.auditor);
    const contactForm = useSelector(state => state.contactForm);
    const [address, setAddress] = useState(contactForm?.address);
    const [postalCode, setPostalCode] = useState(contactForm?.postalCode);
    const [city, setCity] = useState(contactForm?.city);
    const [countryId, setCountryId] = useState(null);
    const {service} = useSelector(state => state.toContact);
    const countries = useSelector(state => state.global.countries);
    const {country, cities} = service;

    const checkCountryId = () => {
        countries?.forEach((c) => {
            if (c.name.toLowerCase() === country.toLowerCase())
                setCountryId(c.id);
        });
    };

    const checkAddressValidity = () => {
        if (Object.values({address, city, postalCode}).some(value => !value)) {
            toast.error("L'adresse n'est pas complète.");
            return
        }

        // Dispatch l'adresse, ville, code postal, pays (et région si le code postal est manquant)
        dispatch(addReservationAddress(address));
        dispatch(addReservationCity(city));
        dispatch(addReservationPostalCode(postalCode));
        closeModal()
    }


    useEffect(() => {
        !countryId && checkCountryId();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [countryId]);

    return (
        <div>

            <CardHeaderCustom
                cardTitle="Adresse de l'événement"
                closeModal={() => closeModal()}/>

            <CardBody>
                <Row>
                    <Col md="7">
                        <FormGroup>
                            {ITextField(
                                "Adresse",
                                null,
                                address,
                                (e) => changeFields(setAddress, e)
                            )}
                        </FormGroup>
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            {ITextField(
                                'Code Postale',
                                null,
                                postalCode,
                                (e) => changeFields(setPostalCode, e),
                                null, false
                            )}
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            {cities[0] === 'all' ?
                                IMultiSelectTools(
                                    "Chercher une ville",
                                    city,
                                    searchCity,
                                    listOfCity,
                                    (e, value) => changeFields(setCity, value),
                                    null,
                                    null,
                                    auditor,
                                    countryId,
                                    setListOfCity,
                                    true)
                                :
                                <FormControl size="small" variant="filled" fullWidth>
                                    <InputLabel id="demo-simple-select-filled-label">Choisir une ville</InputLabel>
                                    <Select id="demo-simple-select-filled"
                                            value={city}
                                            onChange={(e) => changeFields(null, e, addReservationCity, dispatch)}
                                    >
                                        {cities.map((val, index) =>
                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>}
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-end mx-1">
                    <Tooltip title="Valider l'address" placement="top">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('lightGreen')}
                            onClick={() => checkAddressValidity()}>
                            <small>Enregistrer&nbsp;</small>
                            <DoneAllIcon fontSize="small" sx={{color: "lightGreen!important"}}/>
                        </IconButton>
                    </Tooltip>
                </Row>
            </CardFooter>
        </div>
    )
}

export default AddAddressManually;
