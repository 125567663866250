import SaveAsIcon from "@mui/icons-material/SaveAs";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import ServiceDetailForm from "./ServiceDetailForm";
import ServiceInfoForm from "./ServiceInfoForm";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export const AddService = forwardRef(({upDate}, ref) => {

    const [activeStep, setActiveStep] = useState(0);
    const serviceForm = useSelector(state => state.serviceForm);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isValidServiceInfoForm = () => {
        if (!serviceForm.title) {
            toast.error("Titre est requis pour une prestation");
            return true
        } else if (!serviceForm.country) {
            toast.error("Le champ Pays est requis");
            return true
        } else if (serviceForm.thematics.length <= 0) {
            toast.error("Veuillez choisir au moins un genre musical");
            return true
        }
        return false
    }

    const isValidServiceDetailForm = () => {
        if (!serviceForm.price) {
            toast.error("Le prix est requis");
            return true
        } else if (serviceForm.events?.length <= 1) {
            toast.error("Veuillez choisir au moins deux événements");
            return true
        } else if (serviceForm.galleries.length === 0) {
            toast.error("Veuillez choisir au moins une image");
            return true
        } else if (serviceForm.duration_of_the_service <= 0) {
            toast.error("Veuillez renseigner la durée minimale votre prestation");
            return true
        } else if (!serviceForm.number_of_artists) {
            toast.error("Le nombre d'artiste minimum est de 1");
            return true
        }
        return false
    }

    const steps = [{
        label: "Informations Basic",
        description: "Un titre bien choisi pourrait faire la Différence.",
        input: <ServiceInfoForm newService upDate={upDate}/>,
        button:
            <div className="row mt-2 justify-content-center">
                <button
                    onClick={() => !isValidServiceInfoForm() && handleNext()}
                    className="width-full ml-3 mr-3 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                    Continuer&nbsp;<SaveAsIcon fontSize="small" sx={{color: 'white!important'}}/>
                </button>
            </div>
    }, {
        label: "Personnalisation de la fiche",
        input: <ServiceDetailForm upDate={upDate}/>,
        button:
            <div className="row mt-3 justify-content-center">
                <button
                    onClick={() => handleBack()}
                    className="width-full ml-3 mr-3 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                    Retour&nbsp;<KeyboardDoubleArrowUpIcon fontSize="small" sx={{color: "white!important"}}/>
                </button>
            </div>
    }]

    useImperativeHandle(ref, () => ({
        _isValid() {
            if (isValidServiceInfoForm()) {
                return false
            } else return !isValidServiceDetailForm();
        }
    }));

    return (
        <Box className="ml-4 mr-4">
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={index === 1 ? (<Typography variant="caption">Dernière étape</Typography>) : null}
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <br/>
                            <Box sx={{mb: 1}}>
                                <div>
                                    {step.input && step.input}
                                    {step.button && step.button}
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
})
