import {PickersDay} from '@mui/x-date-pickers';
import {styled} from '@mui/material/styles';

const StyledPickersDay = styled(PickersDay)(({theme}) => ({
    aspectRatio: '1', // Garde chaque case carrée
    borderRadius: '8px',
    fontSize: '1rem',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
        backgroundColor: '#E0E0E0',
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const CustomPickerDay = (props) => {
    return <StyledPickersDay {...props} />;
};

export default CustomPickerDay;
