import React, {useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from "react-redux";
import apis from "../config/apis";
import {addReservationMessagesList} from "../reducer";
import MyChatMsg from './MyChatMsg';

const GroupedChatMessages = ({messages, userId}) => {

    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const {contactToChat, msgTabIndex, reservationsMessageList} = useSelector(state => state.global);

    const groupMessagesByDate = (messages) => {
        return messages.reduce((groups, message) => {
            const date = dayjs(message.created_at).format('YYYY-MM-DD');
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(message);
            return groups;
        }, {});
    };

    const groupedMessages = groupMessagesByDate(messages);

    const onDelete = (message_id) => {
        auditor.delete(apis.onDeleteMessage(message_id)).then(response => {
            let tmp = {...reservationsMessageList};
            let reservation_id = contactToChat?.reservation_id

            if (tmp[reservation_id]) {
                tmp[reservation_id] = tmp[reservation_id]?.filter((msg) => msg.id !== message_id)
                dispatch(addReservationMessagesList(tmp));
            }
        }).catch((e) => console.log(e));
    }

    const markMessagesAsRead = async () => {
        const unreadIds = messages
            .filter(msg => !msg.read && parseInt(msg.sender_id) !== parseInt(userId))
            .map(msg => msg.id);

        if (unreadIds.length > 0) {
            auditor.put(apis.markAsRead, {message_ids: unreadIds}).then(response => {

                let tmp = {...reservationsMessageList};
                let reservation_id = contactToChat?.reservation_id

                if (tmp[reservation_id]) {
                    tmp[reservation_id] = tmp[reservation_id].map((msg) =>
                        unreadIds.includes(msg.id) ? {...msg, read: true} : msg
                    );

                    dispatch(addReservationMessagesList(tmp));
                }
            }).catch((e) => console.log(e));
        }
    };

    useEffect(() => {

        markMessagesAsRead().then(r => null)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [messages?.length]);

    return (
        <Box>
            {Object.keys(groupedMessages).map((date) => (
                <Box key={date} mb={3}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{textAlign: 'center', display: 'block', mb: 1}}
                    >
                        {dayjs(date).format('DD-MM-YYYY')}
                    </Typography>
                    {groupedMessages[date].map((msg) => (
                        <MyChatMsg
                            key={msg.id}
                            read={msg.read}
                            message={msg.message}
                            date={msg.created_at}
                            onDelete={() => onDelete(msg.id)}
                            side={parseInt(msg.sender_id) === parseInt(userId) ? 'right' : 'left'}
                            avatar={msgTabIndex === 'auditor'
                                ? contactToChat?.reservation?.auditor_photo || contactToChat?.name
                                : contactToChat?.reservation?.artist_photo || contactToChat?.reservation?.artist_name}
                        />
                    ))}
                </Box>
            ))}
        </Box>
    );
};

export default GroupedChatMessages;
