import React, {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, CircularProgress, Grid, TextField, Tooltip} from "@mui/material";
import apis from "../../../config/apis";
import {addProfileData} from "../../../reducer";
import {changeFields, checkErrorMessage, deleteNullInObj, fillInBodyForm} from "../../../tools/Tools";

function AddBirthDay({closeModal}) {
    const dispatch = useDispatch();
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const profileData = useSelector((state) => state.profile.profileData);
    const [birth, setBirth] = useState(profileData.birth || ""); // Valeur par défaut vide
    const [loading, setLoading] = useState(false);

    const addBirth = async () => {
        setLoading(true);
        let tData = deleteNullInObj({...profileData});
        tData["birth"] = birth || ""; // Assure une valeur par défaut
        delete tData["is_certified"];
        const bodyFormData = fillInBodyForm(new FormData(), tData);

        await auditorForm
            .put(apis.updateProfile, bodyFormData)
            .then(async (resp) => {
                await dispatch(addProfileData(resp.data));
                toast.success("Votre date de naissance a été mise à jour");
                setLoading(false);
                closeModal && closeModal();
            })
            .catch((error) => {
                setLoading(false);
                toast.error(checkErrorMessage(error).message);
            });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            padding={2}
            sx={{backgroundColor: "white", borderRadius: 2, boxShadow: 3, width: "100%"}}
        >
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="DD"
                        label="Jour"
                        value={birth.split("-")[2] || ""}
                        onChange={(e) =>
                            changeFields(setBirth, { target: { value: `${birth.split("-")[0] || ""}-${birth.split("-")[1] || ""}-${e.target.value}` } })
                        }
                        inputProps={{
                            maxLength: 2,
                            style: { textAlign: "center", width: "22.5px" }, // Ajuste la largeur et centre le texte
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="MM"
                        label="Mois"
                        value={birth.split("-")[1] || ""}
                        onChange={(e) =>
                            changeFields(setBirth, { target: { value: `${birth.split("-")[0] || ""}-${e.target.value}-${birth.split("-")[2] || ""}` } })
                        }
                        inputProps={{
                            maxLength: 2,
                            style: { textAlign: "center", width: "22.5px" }, // Ajuste la largeur et centre le texte
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="YYYY"
                        label="Année"
                        value={birth.split("-")[0] || ""}
                        onChange={(e) =>
                            changeFields(setBirth, { target: { value: `${e.target.value}-${birth.split("-")[1] || ""}-${birth.split("-")[2] || ""}` } })
                        }
                        inputProps={{
                            maxLength: 4,
                            style: { textAlign: "center", width: "35px" }, // Largeur plus large pour 4 caractères
                        }}
                    />
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" width="100%">
                {closeModal && (
                    <Tooltip title="Annuler" placement="top">
                        <Button
                            color="error"
                            variant="outlined"
                            disabled={loading}
                            onClick={() => !loading && closeModal()}
                            sx={{textTransform: "none"}}
                        >
                            Annuler
                        </Button>
                    </Tooltip>
                )}

                <Tooltip title="Enregistrer" placement="top">
                    <Button
                        onClick={addBirth}
                        color="success"
                        variant="contained"
                        disabled={loading}
                        sx={{textTransform: "none"}}
                    >
                        {loading ? <CircularProgress size={24}/> : "Enregistrer"}
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default AddBirthDay;
