import React from 'react';
import {Button} from '@mui/material';

const CustomToast = ({title, onClick}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
    >
        <div style={{marginRight: '8px'}}>
            📢&nbsp;{title}
        </div>
        <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={onClick}
        >
            Voir
        </Button>
    </div>
);

export default CustomToast;
