import React, {useEffect, useState} from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {useSelector} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {useNavigate} from "react-router-dom";
import settings from "../../config/tsconfig.json";
import {useWindowSize} from "../../tools/Tools";
import BankAccount from "../profile/Banks/BankAccount";
import SkeletonRectangular from "../skeletons/SkeletonRectangular";


function BankTransfers() {

    const width = useWindowSize();
    const navigate = useNavigate();
    const isDev = useSelector(state => state.global.isDev);
    const [loading, setLoading] = useState(true);
    const [stripePromise, setStripPromise] = useState();
    const role = useSelector(state => state.profile.role);
    const auth = useSelector(state => state.session.authenticated);
    const isLogout = [null, ''].includes(role);
    const isAuditor = isLogout || role === "professional_auditor";


    const toLoadStripePromise = async () => {
        setStripPromise(loadStripe(isDev ? settings.configs.StripeKeyDev : settings.configs.StripeKeyProd));
    }

    useEffect(() => {

        isAuditor && !auth && navigate('/')
        !isLogout && toLoadStripePromise().then(() => setLoading(false))

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isAuditor, auth, navigate, role])

    return (
        <div className={`content ${width <= 764 ? "pt-5 mx-2" : "pt-1 mx-5"}`}>
            {loading
                ? <div className="card-content">
                    <div className="card-body cleartfix">
                        <SkeletonRectangular width="100%" height={61}/>
                    </div>
                </div>
                : <Elements stripe={stripePromise}>
                    <BankAccount/>
                </Elements>}
        </div>
    )
}

export default BankTransfers;
