import {makeStyles} from "@material-ui/core/styles";
import React from 'react';
import {Box, Grid, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    addArtistNameForCertification,
    addBirthForCertification,
    addLastNameForCertification,
    addNameForCertification
} from "../../../reducer";
import {changeFields} from "../../../tools/Tools";

const useStyles = makeStyles(({palette}) => ({
    content: {
        backdropFilter: "blur(10px)",
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))",
        borderRadius: "0.8rem",
        padding: "12px",
        margin: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
    }
}));

function ProfileInfosUpdate() {

    const styles = useStyles();
    const dispatch = useDispatch();
    const profileData = useSelector(state => state.profile.profileData);
    const {name, birth, lastname, artistName} = useSelector(state => state.certificationData);

    return (
        <Box className={styles.content} sx={{margin: "12px !important"}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Prénom"
                        value={name || profileData?.name}
                        onChange={(e) => changeFields(null, e, addNameForCertification, dispatch)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nom"
                        size="small"
                        variant="outlined"
                        onChange={(e) => changeFields(null, e, addLastNameForCertification, dispatch)}
                        value={lastname || profileData?.lastname}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Nom d'artiste"
                        variant="outlined"
                        value={artistName || profileData.artist_name}
                        onChange={(e) => changeFields(null, e, addArtistNameForCertification, dispatch)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        variant="outlined"
                        value={birth || profileData.birth}
                        label="Date de naissance"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => changeFields(null, e, addBirthForCertification, dispatch)}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProfileInfosUpdate;
