import Box from '@material-ui/core/Box';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import Rating from '@mui/material/Rating';
import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Button from '@material-ui/core/Button';
// import ImgSlider from "../../tools/ImgSlider";
import {useFlexyTagStyles} from '@mui-treasury/styles/tag/flexy';
import {checkServiceId, useWindowSize} from "../../tools/Tools";
import {artist_t} from "../../tools/Variables";
import ArtistFollow from "../profile/ArtistFollow";
import SkeletonServices from "../skeletons/SkeletonServices";
import TabsDetail from "./TabsDetail";
import ServiceDetails from "./ServiceDetails";
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import {Row, Column, Item} from '@mui-treasury/components/flex';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useFourThreeCardMediaStyles} from '@mui-treasury/styles/cardMedia/fourThree';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';

const backGround = 'https://res.cloudinary.com/kantobiz/image/upload/v1729965383/images/kantobiz/cr0ifvuauy0sc22afjvn.png'

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 343,
        margin: 'auto',
        borderRadius: 12,
        backdropFilter: "blur(10px)",
        // background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))",
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.3))",
        padding: 12,
    },
    media: {
        borderRadius: 6,
    },
}));

const Service = () => {

    let {id} = useParams()
    const navigate = useNavigate();
    const styles = useStyles();
    const width = useWindowSize();
    const [bgColor] = useState('#ffffff');
    const [currency, setCurrency] = useState("$");
    const _classes = useFlexyTagStyles();
    const classes = useOverShadowStyles({bgColor});
    const [rating, setRating] = useState(0);
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState({});
    const [disabled, setDisabled] = useState(false);
    const {isDev, countries} = useSelector(state => state.global);
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const userId = useSelector(state => state.profile.userId);
    const mediaStyles = useFourThreeCardMediaStyles();
    const textCardContentStyles = useN04TextInfoContentStyles();
    const results = useSelector(state => state.searchBarData.results);

    const BasicProfile = props => {
        return (
            <Row {...props}>
                <ArtistFollow service={service} disabled={disabled} tag={artist_t[service?.artist_type]}/>
            </Row>
        );
    };

    const serviceChecked = async (_s) => {
        let countryChoice = countries?.filter(tmp => tmp.name === _s.country)[0]
        setCurrency(countryChoice?.currencies.symbol)
        setService(_s)
        setLoading(false)
    }

    const updateNotes = (note) => {
        if (!auth) {
            document.getElementById("login_button").click()
        } else {
            let data = {service_id: service.id, note: [note]}
            auditor.put('stars/update', data).then((resp) => {
                setRating(note)
                toast.success('Vous avez noté la prestation de ' + service.artist_name)
            })
        }
    }

    const CardHeader = props => {
        return (
            <Row {...props}>
                <Item position={'middle'}>
                    <h5 className={_classes.overline} style={{fontSize: 12}}>
                        Contact {service.contact_amount || "*Offert"}{service.contact_amount ? currency : ''}
                    </h5>
                </Item>
                <Item position={'right'} mr={1}>
                    <Button
                        size="small"
                        variant="contained"
                        disabled={userId === service?.user_id}
                        onClick={() => navigate('/artist/contact/service/' + service.id)}
                        endIcon={<SendTimeExtensionIcon />}>
                        Prendre Contact
                    </Button>
                </Item>
            </Row>
        );
    };

    useEffect(() => {
        if (countries?.length) {
            checkServiceId(results, serviceChecked, setLoading, navigate, auditor, id).then(r => null);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [countries]);

    useEffect(() => {
        if (auth && userId === service.user_id) {
            setDisabled(true)
        } else if (!isDev && service.hidden) {
            setDisabled(false)
        } else if (disabled) {
            setDisabled(false)
        }
    }, [auth, isDev, disabled, userId, service.user_id, service.hidden]);

    return (
        <div className="content">

            <img
                hidden={width < 775}
                src={backGround}
                alt=""
                style={{
                    position: 'absolute',
                    bottom: 1/2 + '%',
                    right: 0,
                    width: '80%',
                    height: '80%',
                    objectFit: 'cover',
                    opacity: 0.3,
                }}
            />

            <div className="justify-content-center ml-2 mr-2 mt-2 bg-transparent border-0 overflow-auto"
                 style={{height: "100vh"}}>
                {loading
                    ? <SkeletonServices/>
                    : <div className="row justify-content-center border-0 mt-3">
                        <div className="col-md-8 mt-2 height-full">
                            <Column>
                                <CardHeader className="mb-3"/>
                                <ServiceDetails service={service} currency={currency}/>
                                <br/>
                                <BasicProfile
                                    position={'bottom'}
                                    className="mb-4 ml-2 justify-content-center"/>
                            </Column>
                        </div>
                        <Box classes={classes} borderRadius={2} className="border-0 ml-5 mt-2 mr-5">

                            {/*<Col md={6} className="text-center">*/}
                            {/*    <ImgSlider images={service.galleries}/>*/}
                            {/*</Col>*/}

                            <Item grow>
                                <Box minHeight={200} borderRadius={8}>
                                    <Card className={cx(styles.root)}>
                                        <CardMedia
                                            className={cx(styles.media, mediaStyles.root)}
                                            image={service?.galleries[0]}
                                        />
                                        <CardContent>
                                            <Row className="justify-content-center">
                                                <Tooltip title="Je note la fiche" placement="top">
                                                    <Rating
                                                        name="half-rating"
                                                        value={rating}
                                                        size="medium"
                                                        readOnly={disabled}
                                                        onChange={(e, newValue) => updateNotes(newValue)}/>
                                                </Tooltip>
                                            </Row>
                                            <TextInfoContent
                                                classes={textCardContentStyles}
                                                heading={service?.title?.substring(0, 10) + `${service?.title?.length > 10 ? "..." : ''}`}
                                            />
                                            <TabsDetail
                                                materials={service.materials}
                                                rPolicy={service.refund_policy}
                                                description={service.description}/>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Item>
                        </Box>
                    </div>}
            </div>
        </div>
    );
};

export default Service;
