import React, {useState} from 'react';
import Rating from "@mui/material/Rating";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../config/apis";
import {addProfileCheckedNotes} from "../../reducer";

function RatingProfile({checked}) {

    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const auth = useSelector(state => state.session.authenticated);
    const users_who_rated = useSelector(state => state.profileChecked.users_who_rated);
    const notes = useSelector(state => checked ? state.profileChecked.notes : state.profile.notes);
    const userId = useSelector(state => checked ? state.profileChecked.userId : state.profile.userId);
    const [rating, setRating] = useState(users_who_rated[userId.toString()] || 0);

    const updateNotes = (e, note) => {
        if (!auth) {
            document.getElementById("login_button").click()
        } else {
            let data = {user_id: userId, note: [note]}
            auditor.put(apis.addArtistRating, data).then(async (resp) => {
                setRating(note)
                let _note = resp.data['note']['note'][0]
                await dispatch(addProfileCheckedNotes(_note))
                toast.success("Merci d'avoir noté")
            })
        }
    }

    return (
        <div className="col mt-4" hidden={!checked}>
            <h1 className="text-center">😎</h1>
            <div className="col text-center mt-4">
                <Tooltip title="Je note" placement="bottom">
                    <Rating
                        name="half-rating"
                        value={rating}
                        size="large"
                        onChange={(e, newValue) => updateNotes(e, newValue)}/>
                </Tooltip>
                <small className="mb-1">{notes}✩</small>
            </div>
        </div>
    )
}

export default RatingProfile;
