import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {Column, Item, Row} from "@mui-treasury/components/flex";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";
import Skeleton from "@mui/material/Skeleton";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import cx from "clsx";
import React, {useEffect, useState} from "react";
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 343,
        margin: 'auto',
        borderRadius: 12,
        padding: 12,
    },
    media: {
        borderRadius: 6,
    },
}));

function SkeletonServices() {

    const styles = useStyles();
    const [bgColor] = useState('#ffffff');
    const classes = useOverShadowStyles({bgColor});
    const shadowStyles = useOverShadowStyles({inactive: true});
    const textCardContentStyles = useN04TextInfoContentStyles();

    useEffect(() => {

    }, []);

    return (
        <div className="row justify-content-center border-0">
            <div className="col-md-8 mt-2 height-full">
                <Column>
                    <div className="row justify-content-between m-2">
                        <Item position={'middle'}>
                            <Skeleton sx={{borderRadius: '5px'}}
                                      animation="wave"
                                      variant="rectangular"
                                      height={15}
                                      width={150}/>
                        </Item>
                        <Item position={'right'}>
                            <Skeleton sx={{borderRadius: '5px'}}
                                      animation="wave"
                                      variant="rectangular"
                                      height={40}
                                      width={150}/>
                        </Item>
                    </div>
                    <Column gap={1.5} minWidth={250}>
                        <Info useStyles={useThemeXsInfoStyles}>
                            <InfoTitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          height={25}
                                          width={80}/>
                            </InfoTitle>
                            <InfoSubtitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          width={150}/>
                            </InfoSubtitle>
                            <InfoCaption>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          height={10}
                                          width={300}/>
                            </InfoCaption>
                        </Info>
                        <Item>
                            <Divider/>
                        </Item>
                        <Info useStyles={useThemeXsInfoStyles}>
                            <InfoTitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          variant="rectangular"
                                          height={25}
                                          width={80}/>
                            </InfoTitle>
                            <InfoSubtitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          width={200}/>
                            </InfoSubtitle>
                        </Info>
                        <Item>
                            <Divider/>
                        </Item>
                        <Info useStyles={useThemeXsInfoStyles}>
                            <InfoTitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          height={25}
                                          width={80}/>
                            </InfoTitle>
                            <InfoSubtitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          width={150}/>
                            </InfoSubtitle>
                            <InfoCaption>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          height={10}
                                          width={300}/>
                            </InfoCaption>
                        </Info>
                        <Item>
                            <Divider/>
                        </Item>
                        <Info useStyles={useThemeXsInfoStyles}>
                            <InfoTitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          height={25}
                                          width={80}/>
                            </InfoTitle>
                            <InfoSubtitle>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          width={150}/>
                            </InfoSubtitle>
                            <InfoCaption>
                                <Skeleton sx={{borderRadius: '5px'}}
                                          animation="wave"
                                          className="mt-2"
                                          variant="rectangular"
                                          height={10}
                                          width={300}/>
                            </InfoCaption>
                        </Info>
                        <Item>
                            <Divider/>
                        </Item>
                    </Column>
                    <Row position={'bottom'}>
                        <Skeleton sx={{borderRadius: '5px', margin: '0 auto'}}
                                  animation="wave"
                                  className="mt-4 ml-2 mr-2 width-full"
                                  variant="rectangular"
                                  height={50}/>
                    </Row>
                </Column>
            </div>
            <Box classes={classes} borderRadius={2} className="border-0 ml-5 mt-2 mr-5">
                <div className="row justify-content-center">
                    <Item grow>
                        <Box minHeight={200} borderRadius={8}>
                            <Card className={cx(styles.root, shadowStyles.root)}>
                                <Skeleton
                                    sx={{borderRadius: '5px'}}
                                    height={239}
                                    animation="wave"
                                    variant="rectangular"/>
                                <CardContent className="m-auto">
                                    <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                              animation="wave"
                                              variant="rectangular"
                                              width={100}/>
                                    <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                              animation="wave"
                                              className="mt-3"
                                              height={25}
                                              variant="rectangular"
                                              width={200}/>

                                    <TextInfoContent classes={textCardContentStyles}/>

                                    <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                              animation="wave"
                                              className="mt-3 width-full"
                                              height={150}
                                              variant="rectangular"/>
                                    <Skeleton sx={{borderRadius: '5px', margin: "0 auto"}}
                                              animation="wave"
                                              className="mt-3 width-full"
                                              height={50}
                                              variant="rectangular"/>

                                </CardContent>
                            </Card>
                        </Box>
                    </Item>
                </div>
            </Box>
        </div>
    );
}

export default SkeletonServices;
