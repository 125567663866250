import {IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {useSelector} from "react-redux";
import {useWindowSize} from "../../tools/Tools";
import Discussions from "./Messages/Discussions";
import Drawer from '@mui/material/Drawer';
import Badge from "@mui/material/Badge";

function FixedPlugin() {

    const width = useWindowSize();
    const [state, setState] = useState({right: false});
    const {contactsLength, role} = useSelector(state => state.profile);
    const contactToChat = useSelector(state => state.global.contactToChat);

    const isNotOpenedChat = () => {
        if (contactToChat === null || contactToChat === undefined || !Object.keys(contactToChat)?.length) {
            return true
        } else if (Object.keys(contactToChat)?.length && !state?.right) {
            return true
        }
        return false
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (isNotOpenedChat()) {
            setState({...state, [anchor]: open});
        }
    };

    return (
        <div className="fixed-plugin">
            <Tooltip title="Discussions" placement="left">
                <IconButton
                    sx={{p: '15px'}}
                    aria-label="search"
                    id="open_discussion"
                    onClick={toggleDrawer('right', true)}>
                    <Badge
                        color="secondary"
                        badgeContent={
                        role === 'professional_auditor'
                            ? contactsLength['book']?.accepted
                            : contactsLength['book']?.accepted + contactsLength['auditor']?.accepted}>
                        <QuestionAnswerIcon fontSize="large" sx={{color: "white!important"}}/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <Box sx={{width: width < 500 ? width : 450}} role="presentation">
                    <Discussions toggleDrawer={toggleDrawer}/>
                </Box>
            </Drawer>
        </div>
    );
}

export default FixedPlugin;
