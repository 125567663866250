import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {forwardRef, useImperativeHandle} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addCertificationObject, addProfileData} from "../../../reducer";
import {
    checkErrorMessage,
    deleteInObject,
    deleteNullInObj,
    fillInBodyForm,
    generateBodyFormOfGallery
} from "../../../tools/Tools";
import AddDocumentCertification from "./AddDocumentCertification";
import CertificationAdvantages from "./CertificationAdvantages";
import RightSideCertification from "./RightSideCertification";
import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography} from "@mui/material";

const Certification = forwardRef(({closeCertification}, ref) => {

    const dispatch = useDispatch();
    const {profileData} = useSelector(state => state.profile);
    const auditorForm = useSelector(state => state.axios.auditorForm);
    const {
        documents,
        name,
        birth,
        lastname,
        artistName,
        certification
    } = useSelector((state) => state.certificationData);

    const updateProfileForCertification = async () => {
        let tData = deleteNullInObj({...profileData});
        tData['birth'] = birth || profileData?.birth
        tData['name'] = name || profileData?.name
        tData['lastname'] = lastname || profileData?.lastname
        tData['artist_name'] = artistName || profileData?.artist_name
        delete tData['is_certified'];
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm.put(apis.updateProfile, bodyFormData).then(async resp => {
            await dispatch(addProfileData(resp.data));
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
        });
    }

    const updateCertification = async () => {
        if (name || lastname || artistName || birth) {
            await updateProfileForCertification();
        }

        let bodyFormData = new FormData();
        if (documents?.length) {
            bodyFormData = generateBodyFormOfGallery(new FormData(), documents)
        }

        bodyFormData = fillInBodyForm(bodyFormData, deleteInObject({...certification}));

        await auditorForm.put(apis.upCertification(certification?.id), bodyFormData).then(async resp => {
            await dispatch(addCertificationObject(resp.data));
            closeCertification()
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
        });
    }

    const createCertificationDemand = async () => {
        if (name || lastname || artistName || birth) {
            await updateProfileForCertification();
        }

        let bodyFormData = generateBodyFormOfGallery(new FormData(), documents);

        await auditorForm.post(apis.newCertification, bodyFormData).then(async resp => {
            await dispatch(addCertificationObject(resp.data));
            closeCertification()
        }).catch(error => {
            toast.error(checkErrorMessage(error).message);
        });
    };

    useImperativeHandle(ref, () => ({
        validate() {

            if (certification?.id) {
                updateCertification().then(r => null)
            } else if (!documents?.length) {
                toast.error("Veuillez télécharger un fichier valide.");
            } else {
                createCertificationDemand().then(r => null)
            }
        },
    }));

    return (
        <div>
            {certification?.missing_fields?.length > 0 && certification?.status !== 'pending' && (
                <Typography variant="caption" color="error" sx={{display: 'block', marginBottom: 1}}>
                    Champs manquants ou incompatibles :&nbsp;
                    {certification?.missing_fields
                        .map((field) => {
                            switch (field) {
                                case "name":
                                    return "Nom";
                                case "surname":
                                    return "Prénom";
                                case "dob":
                                    return "Date de naissance";
                                default:
                                    return field;
                            }
                        })
                        .join(", ")}.
                </Typography>
            )}
            {certification?.admin_message && certification?.status !== 'pending' && (
                <Box className="accordion-container" sx={{marginBottom: 1}}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="caption" color="textSecondary">
                                Un message est disponible.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                className="pb-5"
                                alignItems="center"
                                sx={{
                                    maxHeight: '150px', // Ajustez la hauteur maximale selon vos besoins
                                    overflowY: 'auto', // Ajoute un défilement vertical si nécessaire
                                    overflowX: 'hidden', // Cache tout débordement horizontal
                                }}>
                                <Typography variant="body2" sx={{marginLeft: 1, textAlign: 'justify'}}>
                                    {certification?.admin_message}
                                </Typography>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs: "column", md: "row"},
                    gap: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    backgroundColor: "#f9f9f9",
                }}
            >
                <Box sx={{flex: 2}}>
                    <AddDocumentCertification/>
                    <Divider sx={{marginLeft: 2, marginRight: 2}}/>
                    <CertificationAdvantages/>
                </Box>
                <RightSideCertification/>
            </Box>
        </div>
    );
});

export default Certification;
