import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import {Column} from '@mui-treasury/components/flex';
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from '@mui-treasury/components/info';
import {useChatzInfoStyles} from '@mui-treasury/styles/info/chatz';
import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {IconButton} from "@mui/material";
import React, {useEffect} from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useFlexyTagStyles} from '@mui-treasury/styles/tag/flexy';
import {usePushingGutterStyles} from '@mui-treasury/styles/gutter/pushing';
import {useN01TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n01';
import {currencySymbolByAddress, useWindowSize} from "../../tools/Tools";


function EventToReserve({address, countries, ContactCardToChat, bookedList}) {

    const _classes = useFlexyTagStyles();
    const width = useWindowSize();
    const gutterStyles = usePushingGutterStyles();
    const {reservation} = ContactCardToChat
    const description = reservation?.description
    const currency = currencySymbolByAddress(ContactCardToChat?.reservation?.address, countries)

    useEffect(() => {
        //
    }, []);

    return (
        <>
            <div className="col-md-6">
                <CardContent className="width-full">
                    <TextInfoContent
                        useStyles={useN04TextInfoContentStyles}
                        overline={<>
                            Fiche&nbsp;
                            <strong>
                                {ContactCardToChat?.reservation?.title.substring(0, 22)}
                                {ContactCardToChat?.reservation?.title.length > 22 && "..."}
                            </strong>
                            <IconButton
                                size="small"
                                aria-label="settings"
                                onClick={() => {
                                    const win = window.open("/artist/service/" + ContactCardToChat?.reservation.services_id, "_blank");
                                    win.focus();
                                }}>
                                <OpenInNewIcon/>
                            </IconButton>
                        </>}
                        heading={<small className="text-center">{ContactCardToChat?.reservation.event}</small>}
                        body={
                            <div>
                                <TextInfoContent
                                    useStyles={useN01TextInfoContentStyles}
                                    overline={<strong
                                        className="text-lowercase">Le&nbsp;{dayjs(ContactCardToChat?.reservation.event_date).format("DD-MM-YYYY à HH:mm A")}</strong>}
                                />
                                <Column gap={2}>
                                    <Info useStyles={useChatzInfoStyles}>
                                        <InfoTitle>{address.street}</InfoTitle>
                                        <InfoSubtitle>{address.postalCode}&nbsp;{address.city}</InfoSubtitle>
                                        <InfoCaption>{address.country}</InfoCaption>
                                    </Info>
                                </Column>
                                <Column gap={2}>
                                    <Info useStyles={useChatzInfoStyles}>
                                        <InfoSubtitle>
                                            Avec:&nbsp;
                                            {bookedList
                                                ? <strong>
                                                    {ContactCardToChat?.reservation?.artist_name}
                                                    {ContactCardToChat?.is_certified
                                                        ? <Tooltip style={{cursor: 'pointer'}} title="Profil verifié" placement='top'>
                                                            <VerifiedIcon
                                                                sx={{
                                                                    marginLeft: 0.5,
                                                                    marginBottom: 0.5,
                                                                    color: '#1976d2 !important',
                                                                    fontSize: '16px', // Taille précise
                                                                }}/>
                                                        </Tooltip>
                                                        : null}
                                            </strong>
                                                : <strong>
                                                    {ContactCardToChat?.lastname}&nbsp;{ContactCardToChat?.name}
                                                </strong>}
                                        </InfoSubtitle>

                                    </Info>
                                </Column>
                                <Box className={gutterStyles.parent}>
                                    <Button classes={{root: _classes.root, label: _classes.label}}>
                                        <div>
                                    <span className={_classes.overline}>
                                        À partir de&nbsp;{ContactCardToChat?.reservation.min_amount + currency}
                                    </span>
                                            <div className="media-body mt-1">
                                                <span>Proposition</span>
                                            </div>
                                        </div>
                                        <div className={_classes.icon}>
                                            <div className="align-self-center mt-2">
                                                <h4>{ContactCardToChat?.reservation.total_amount + currency}</h4>
                                            </div>
                                        </div>
                                    </Button>
                                </Box>
                            </div>
                        }
                    />
                </CardContent>
            </div>
            <div className="col-md-6 justify-content-start">
                <p
                    className={`${width < 500 ? 'ml-3' : ''} mt-4 pt-2 mr-3 text-justify text-muted overflow-auto`}
                    style={{maxHeight: '400px'}}>
                    {description}
                </p>
            </div>
        </>
    )
}

export default EventToReserve;
