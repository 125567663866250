import React, {useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {CardBody, Col, Form, FormGroup, Row, Spinner} from "reactstrap";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {changeFields, checkErrorMessage, ITextField, passWordValidate} from "../../tools/Tools";

export default function ChangePassword(props) {

    const {closeModal} = props
    const [keys, setKeys] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const auditor = useSelector(state => state.axios.auditor);

    const steps = [
        {
            label: "Verification d'email",
            description: 'Veuillez entrer votre adresse email',
            input: <Form>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            {ITextField('Email', null, email, (e) => changeFields(setEmail, e))}
                        </FormGroup>
                    </Col>
                </Row>
            </Form>,
            button:
                <Button variant="contained" onClick={() => checkEmail()} sx={{mt: 1, mr: 1}}>
                    Suivant&nbsp;{loading && <Spinner type="grow" size="sm"/>}
                </Button>,
        },
        {
            label: 'Code de validation',
            description: 'Code envoyer par email',
            input: <Form>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            {ITextField('Votre clé', 'number', keys, (e) => changeFields(setKeys, e))}
                        </FormGroup>
                    </Col>
                </Row>
            </Form>,
            button:
                <Button variant="contained" onClick={() => checkKeys()} sx={{mt: 1, mr: 1}}>
                    Suivant&nbsp;{loading && <Spinner type="grow" size="sm"/>}
                </Button>,
        },
        {
            label: 'Changement de mot de passe',
            description: 'Veuillez saisir deux fois le mot de passe',
            input: <Form>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            {ITextField('Nouveau mot de passe', 'password', password, (e) => changeFields(setPassword, e))}
                        </FormGroup>
                        <FormGroup>
                            {ITextField('Resaisir le nouveau mot de passe', 'password', confirmPassword, (e) => changeFields(setConfirmPassword, e))}
                        </FormGroup>
                    </Col>
                </Row>
            </Form>,
        },
    ];

    const changePass = () => {
        setLoading(true);
        let validation = passWordValidate(password, confirmPassword)
        if (!validation.error) {
            auditor.put("users/reset_password", {email, password}).then(async () => {
                setLoading(false);
                toast.success('Parfait, connectez vous avec votre nouveau mot de passe.', {
                    duration: 6000,
                    style: {
                        border: '1px solid #6bd098',
                        padding: '16px',
                        color: '#6bd098',
                    },
                    iconTheme: {
                        primary: '#6bd098',
                        secondary: '#FFFAEE',
                    },
                });
                closeModal()
            }).catch(error => {
                setLoading(false);
                if (password.length < 8) {
                    toast.error("Mot de passe trop court");
                } else toast.error(checkErrorMessage(error).message)
            })
        } else {
            setLoading(false);
            toast(
                "Votre mot de passe doit contenir au moins: " +
                "\n -> Une majuscule, " +
                "\n -> Un minuscule, " +
                "\n -> Un chiffre, " +
                "\n -> Un caractère spéciaux (!@#$$%^&*...) " +
                "\n -> Doit compter au moins 8",
                {
                    duration: 6000,
                }
            );
            toast.error(validation.message);
        }
    };

    const checkEmail = () => {
        setLoading(true);
        auditor.post("users/get_mail", {email: email}).then(() => {
            handleNext()
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            toast.error("Email invalide")
        })
    };

    const checkKeys = async () => {
        await setLoading(true)
        await auditor.post("users/get_if_keys_validate", {email, keys}).then(async (resp) => {
            handleNext()
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            toast.error("Code invalide");
        })
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div>

            <CardHeaderCustom
                cardTitle="Recupération de mot de passe"
                disabled={loading}
                closeModal={() => closeModal()}
            />

            <CardBody>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={index === 2 ? (
                                    <Typography variant="caption">Dernière étape</Typography>) : null}
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <br/>
                                <Box sx={{mb: 2}}>
                                    <div>
                                        {step.input && step.input}
                                        {step.button && step.button}
                                        {index === steps.length - 1 &&
                                            <Button variant="contained" onClick={() => changePass()}
                                                    sx={{mt: 1, mr: 1}}>
                                                Enregistrer&nbsp;{loading && <Spinner type="grow" size="sm"/>}
                                            </Button>}
                                        {index !== 0 && index !== steps.length - 1 &&
                                            <Button onClick={handleBack} sx={{mt: 1, mr: 1}}>
                                                Retour
                                            </Button>}
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </CardBody>
        </div>
    );
}
