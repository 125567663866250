import Conf from "./tsconfig.json";

const apis = {

    // Auth
    login: 'users/login',
    isTokenValid: 'users/if_token_valid',
    updateProfile: 'profiles/updateProfile',

    // Stars
    addArtistRating: 'stars/update',

    // Condition
    updateCondition: 'artist_conditions/update',

    // SupportMessage
    newSupportMessage: 'support_messages/new_message',

    // Chat
    markAsRead: "messages/mark_as_read_message_ids",
    onDeleteMessage: (message_id) => `messages/delete/${message_id}`,
    sendUserMessage: (reservation_id) => `messages/new_message/${reservation_id}`,
    getBotAnswers: (contact_id) => `/bot/predict/check_bot_response/${contact_id}`,

    // Certification
    newCertification: 'certifications/new',
    upCertification: (certification_id) => `certifications/update/${certification_id}`,
    myCertification: 'certifications/my',

    // Notification
    readNotification: (notification_id) => `notifications/read/${notification_id}`,
    checkNotificationUnread: (page) => `notifications/per_page/${page}/0`,
    checkNotificationRead: (page) => `notifications/per_page/${page}/1`,
    deleteNotification: (notification_id) => `notifications/delete/${notification_id}`,

    // Artist Bank Account
    upBank: 'banking/update',
    addBank: 'banking/create',
    getStripeDocumentsId: 'banking/checkDocumentStripeId',

    //geocoding
    getAddress: (newValue) => `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(newValue)}.json?access_token=${Conf.configs.GeoCodingApi}&autocomplete=true&limit=5`,

    // wallet
    transactions: 'transactions/per_page',
    payOut: 'wallet/bank_transfer_to_artist',
    payOutOrangeMoney: 'transactions/orange_money_payout',

    // Others
    getArtistTypes: 'artist_types/all',
    generateContract: (reservation_id) => `kantobiz_contract/generate/${reservation_id}`,
    getAllCountries: 'countries_and_cities/all_countries',

    // reservations
    eventNewProposition: (reservationId) => `reservation/new_proposition/${reservationId}`,

    // Services
    getServices: (pageNumber) => `/artist_services/check/per_page/${pageNumber}`,

    // Contacts
    updateContact: (bookId, status) => `contact_cards/update/${bookId}/${status}`,
    getContacts: (contact_tpe, page, status) => `contact_cards/${contact_tpe}/per_page/${page}/${status}`,
    contacts_length: (contact_type) => `contact_cards/${contact_type}/length`,
    getEventsToFinish: (contact_type) => `contact_cards/${contact_type}/events_passed_to_finish`,

    // Event
    auditorToPaid: 'reservation/auditor_to_paid'
};

export default apis;
