import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Column, Item} from "@mui-treasury/components/flex";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useGrowIconButtonStyles} from "@mui-treasury/styles/iconButton/grow";
import {useThemeSmInfoStyles} from "@mui-treasury/styles/info/themeSm";
import {Divider, Skeleton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {Row} from "reactstrap";
import SkeletonRectangular from "./SkeletonRectangular";

function DiscussSkeletonCard({index}) {
    const defaultGrowStyles = useGrowIconButtonStyles();

    return (
        <Column key={"ef_" + index}>
            <Row
                alignItems={"center"}
                className="px-4 py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {/* Avatar circulaire */}
                <Item position={"middle"}>
                    <Skeleton variant="circular" width={50} height={50}/>
                </Item>

                {/* Infos */}
                <Info useStyles={useThemeSmInfoStyles}>
                    <InfoTitle className="m-1">
                        <SkeletonRectangular height={20} width={120}/>
                    </InfoTitle>
                    <InfoSubtitle className="m-1">
                        <SkeletonRectangular height={15} width={100}/>
                    </InfoSubtitle>
                    <InfoCaption className="m-1">
                        <SkeletonRectangular height={10} width={150}/>
                    </InfoCaption>
                </Info>

                {/* Bouton à droite */}
                <Item minWidth={30} textAlign={"right"}>
                    <Tooltip title="Ouvrir la discussion" placement="bottom">
                        <IconButton classes={defaultGrowStyles}>
                            <SkeletonRectangular height={30} width={30}/>
                        </IconButton>
                    </Tooltip>
                </Item>
            </Row>

            {/* Ligne de séparation */}
            <Item>
                <Divider/>
            </Item>
        </Column>
    );
}

export default DiscussSkeletonCard;
