import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {Row} from "reactstrap";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddDefaultAddress from "./AddDefaultAddress";

function EditProfileAddress({activeDefaultAddress, setActiveDefaultAddress}) {
    const [customProps, setCustomProps] = useState({});
    const profileData = useSelector((state) => state.profile.profileData);
    const isFirstRun = useRef(true);

    const splitAddress = () => {
        if (profileData?.address && profileData?.address !== "null") {
            let [streetDefault, pcCity] = profileData.address.split(", ");
            let [postalCodeDefault, cityDefault] = pcCity.split(" ");
            setCustomProps({streetDefault, postalCodeDefault, cityDefault});
        } else {
            setCustomProps({streetDefault: "", postalCodeDefault: "", cityDefault: ""});
        }
    };

    useEffect(() => {
        if (isFirstRun.current || profileData.address) {
            isFirstRun.current = false;
            splitAddress();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [profileData.address]);

    return (
        <Row
            className="justify-content-between align-items-center ml-2 mr-1"
            style={{display: "flex"}}
        >
            {activeDefaultAddress ? (
                <AddDefaultAddress
                    {...customProps}
                    closeModal={() => setActiveDefaultAddress(false)}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <a
                        href="#kantobiz_artist_address"
                        className="mt-2 text-secondary"
                        style={{
                            flexGrow: 1,
                            textDecoration: "none",
                            color: "#555",
                            fontWeight: 400,
                        }}
                        onClick={() => setActiveDefaultAddress(true)}
                    >
                        {!profileData?.address || profileData.address === "null"
                            ? "Définir une adresse par défaut ..."
                            : `${customProps.streetDefault}, ${customProps.cityDefault} - ${customProps.postalCodeDefault}`}
                    </a>
                    <IconButton
                        aria-label="settings"
                        style={{marginLeft: "10px"}}
                        onClick={() => setActiveDefaultAddress(true)} // Simplifié ici
                    >
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </div>
            )}
        </Row>
    );
}

export default EditProfileAddress;
