import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import React, {useState} from "react";
import toast from "react-hot-toast";
import apis from "../../../config/apis";
import {addProfileData} from "../../../reducer";
import {useDispatch, useSelector} from "react-redux";
import {Row} from "reactstrap";
import IconButton from "@mui/material/IconButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {changeFields, checkErrorMessage, deleteNullInObj, fillInBodyForm} from "../../../tools/Tools";

function AddGender(props) {
    const {closeModal} = props;
    const dispatch = useDispatch();
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const profileData = useSelector((state) => state.profile.profileData);
    const [gender, setGender] = useState(profileData.gender);
    const [activeGenderEdit, setActiveGenderEdit] = useState(true);

    const upDateProfileGender = async () => {
        let tData = deleteNullInObj({...profileData});
        tData["gender"] = gender;
        delete tData['is_certified'];
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm
            .put(apis.updateProfile, bodyFormData)
            .then(async (resp) => {
                await dispatch(addProfileData(resp.data));
                toast.success("Votre genre a été mis à jour sur le profil.");
                setActiveGenderEdit(false);
                closeModal();
            })
            .catch((error) => {
                toast.error(checkErrorMessage(error).message);
            });
    };

    const validate = () => {
        if (!gender) {
            toast.error("Veuillez nous renseigner votre genre.");
        } else upDateProfileGender().then((r) => null);
    };

    return (
        <Row className="justify-content-between mb-2 align-items-center" style={{display: "flex"}}>
            {activeGenderEdit ? (
                <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                    <Tooltip title="Votre sexe" placement="top">
                        <InfoIcon sx={{color: "gray!important", cursor: "pointer"}}/>
                    </Tooltip>
                    <Select
                        value={gender}
                        onChange={(e) => changeFields(setGender, e)}
                        displayEmpty
                        sx={{flexGrow: 1, marginRight: "10px", height: "36px", minWidth: "120px"}}
                        inputProps={{style: {padding: "8px"}}}
                    >
                        <MenuItem value="">Non défini</MenuItem>
                        <MenuItem value="male">Homme</MenuItem>
                        <MenuItem value="female">Femme</MenuItem>
                    </Select>
                    <IconButton
                        size="small"
                        aria-label="cancel"
                        style={{marginLeft: "10px"}}
                        onClick={closeModal}
                    >
                        <CancelIcon sx={{color: "red"}}/>
                    </IconButton>
                    <IconButton
                        size="small"
                        aria-label="save"
                        style={{marginLeft: "10px"}}
                        onClick={validate}
                    >
                        <DoneAllIcon sx={{color: "lightGreen !important"}}/>
                    </IconButton>
                </div>
            ) : null}
        </Row>
    );
}

export default AddGender;
