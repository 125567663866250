import React from "react";
import {Box, Typography, Skeleton, Stack} from "@mui/material";

function SkeletonContact() {

    return (
        <Box sx={{width: '100% !important'}}>
            {/* Title Section */}
            <Skeleton variant="text" width="50%" height={40} sx={{mb: 2}}/>

            {/* Date and Time */}
            <Skeleton variant="text" width="40%" height={25} sx={{mb: 2}}/>

            {/* Details Section */}
            <Stack spacing={1}>
                {Array.from({length: 7}).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="text"
                        width={index % 2 === 0 ? "60%" : "40%"}
                        height={20}
                    />
                ))}
            </Stack>

            {/* About Section */}
            <Typography variant="h6" sx={{mt: 3, mb: 2}}>
                <Skeleton variant="text" width="30%"/>
            </Typography>
            <Skeleton variant="rectangular" height={100} sx={{borderRadius: 2}}/>
        </Box>
    );
}

export default SkeletonContact;
