export const artist_t = {
    "dj": "@Dj",
    "artist_musician": "@Chanteur_Musicien",
    "dancers": "@Danseur"
}

export const international_payout_minimum = {
    "ALL": 3000,
    "DZD": 1,
    "AOA": 15000,
    "XCD": 1,
    "ARS": 4600,
    "AMD": 12100,
    "AUD": 1,
    "EUR": 1,
    "AZN": 50,
    "BSD": 1,
    "BHD": 1,
    "BDT": 20,
    "XOF": 1,
    "BTN": 2500,
    "BOB": 200,
    "BAM": 50,
    "BWP": 1,
    "BND": 1,
    "BGN": 1,
    "KHR": 123000,
    "CAD": 1,
    "CLP": 23000,
    "COP": 140000,
    "CRC": 1,
    "DKK": 20,
    "DOP": 1,
    "USD": 1,
    "EGP": 20,
    "ETB": 1,
    "XAF": 100,
    "GMD": 1900,
    "GHS": 1,
    "GTQ": 1,
    "GYD": 6300,
    "HKD": 1,
    "HUF": 360,
    "INR": 1,
    "IDR": 1,
    "ILS": 1,
    "JMD": 1,
    "JPY": 1000,
    "JOD": 1,
    "KZT": 1,
    "KES": 1,
    "KWD": 1,
    "LAK": 516000,
    "MOP": 1,
    "MGA": 132300,
    "MYR": 133,
    "MUR": 1,
    "MXN": 10,
    "MDL": 500,
    "MNT": 105000,
    "MAD": 1,
    "MZN": 1700,
    "NAD": 550,
    "NZD": 1,
    "NGN": 1,
    "MKD": 1500,
    "NOK": 20,
    "OMR": 1,
    "PKR": 1,
    "PYG": 210000,
    "PEN": 1,
    "PHP": 20,
    "PLN": 5,
    "QAR": 1,
    "RON": 5,
    "RWF": 100,
    "SAR": 1,
    "RSD": 3000,
    "SGD": 1,
    "ZAR": 100,
    "KRW": 40000,
    "LKR": 1,
    "SEK": 20,
    "TWD": 800,
    "TZS": 1,
    "THB": 600,
    "TTD": 1,
    "TND": 1,
    "TRY": 5,
    "AED": 5,
    "GBP": 1,
    "UYU": 1,
    "UZS": 343000,
    "VND": 1
}

export const national_payout_minimum = {
    "AUD": 1,
    "EUR": 1,
    "BRL": 1,
    "BGN": 1,
    "CAD": 1,
    "CRC": 1,
    "CZK": 30,
    "XOF": 1,
    "DKK": 20,
    "DOP": 1,
    "GBP": 1,
    "GTQ": 1,
    "HKD": 1,
    "HUF": 360,
    "INR": 1,
    "IDR": 1,
    "JPY": 1,
    "CHF": 5,
    "MYR": 5,
    "MXN": 10,
    "NZD": 1,
    "NOK": 20,
    "PEN": 1,
    "PHP": 2,
    "PLN": 5,
    "RON": 5,
    "SGD": 1,
    "SEK": 20,
    "THB": 1,
    "TTD": 1,
    "AED": 2,
    "USD": 1,
    "UYU": 1
}

export const payment_appearance = {
    theme: 'flat',
    labels: 'floating',
    rules: {
        '.Input': {
            padding: '10px',
            border: '1px solid #E0E6EB',
        },
        '.Tab': {
            border: '1px solid #E0E6EB',
        }
    }
};

export const marks = [
    {
        value: 60,
        label: '1h',
    },
    {
        value: 120,
        label: '2h',
    },
    {
        value: 180,
        label: '3h',
    },
    {
        value: 240,
        label: '4h',
    },
    {
        value: 300,
        label: '5h',
    },
];

export const songMarks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },
];
