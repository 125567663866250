import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {
    addProfileContactsArchived,
    addProfileDataContacts,
    addProfileDataContactsLength,
    addProfileNotificationLength,
    addProfileNotificationRead,
    addProfileNotificationUnread,
    setContactIdToShow
} from "../../reducer";
import apis from "../../config/apis";
import CheckContactsData from "../../tools/CheckContactsData";
import NoDataList from "../../tools/NoDataList";
import {checkErrorMessage} from "../../tools/Tools";
import SkeletonContactCardHeader from "../skeletons/SkeletonContactCardHeader";
import ContactCard from "./ContactCard";
import PaginatedContacts from "./PaginatedContacts";

function ContactCardList({bookedList}) {
    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const {contactIdToShow} = useSelector(state => state.global);
    const [showBook, setShowBook] = useState({});
    const link = useSelector(state => bookedList ? 'book' : 'auditor');
    const [loading, setLoading] = useState(true);
    const [toShow, setToShow] = useState(false);
    const {
        contacts,
        notificationRead,
        notificationUnRead,
        userId,
        contacts_archived,
        contactsLength
    } = useSelector(state => state.profile);
    const booked = contacts[link]?.pending

    const addReservationToShow = async (book) => {
        setShowBook(book);
        setToShow(true);

        const notification = notificationUnRead.find(
            (n) => n.element_id === book.id && n.type === "contact_card_notify"
        );

        if (notification) {
            try {
                await auditor.put(apis.readNotification(notification.id));

                await dispatch(addProfileNotificationLength(notificationUnRead.length - 1));
                await dispatch(addProfileNotificationUnread(
                    notificationUnRead.filter((n) => n.id !== notification.id)
                ));

                const tmp = {...notification, read: true}; // Met à jour la notification comme lue
                await dispatch(addProfileNotificationRead([...notificationRead, tmp]));
            } catch (error) {
                console.error("Erreur lors de la mise à jour de la notification", error);
            }
        }
    };

    const updateContactsState = (status, contactCard) => {
        const updatedContacts = {
            ...contacts,
            [link]: {
                ...contacts[link],
                pending: [...contacts[link].pending]?.filter(c => c.id !== contactCard?.id),
                accepted: status === 'declined' ? contacts[link].accepted : [...contacts[link].accepted, contactCard]
            }
        };
        dispatch(addProfileDataContacts(updatedContacts));
    };

    const updateContactsLength = (status) => {
        const updatedLength = {
            ...contactsLength,
            [link]: {
                ...contactsLength[link],
                pending: contactsLength[link]?.pending - 1,
                accepted: status === 'declined'
                    ? contactsLength[link].accepted
                    : contactsLength[link].accepted + 1
            }
        };

        dispatch(addProfileDataContactsLength(updatedLength));
    };

    const updateContactList = async (status, newDataValue) => {
        updateContactsState(status, newDataValue)
        updateContactsLength(status);

        if (status === 'declined') {
            dispatch(addProfileContactsArchived([...contacts_archived, newDataValue]));
            toast.success(`La prise de contact a été ${bookedList ? 'annulée.' : 'déclinée.'}`);
        } else {
            toast.success("La discussion a été ouverte pour cette prise de contact.");
        }
    };

    const updateCardContact = async (bookId, status, setWarnDecline) => {
        setLoading(true);
        setToShow(false);
        setWarnDecline(false);
        auditor.put(apis.updateContact(bookId, status)).then(async (resp) => {
            updateContactList(status, resp.data).then(() => {
                setLoading(false);
                setToShow(false)
            })
        }).catch(error => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        });
    };

    useEffect(() => {
        if (booked?.length && contactIdToShow) {
            let contact = booked?.filter(contact => contact.id === contactIdToShow)?.[0]
            contact && addReservationToShow(contact).then(r => dispatch(setContactIdToShow(0)))
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contactIdToShow, booked?.length]);

    useEffect(() => {
        if (!booked?.length && userId) {
            CheckContactsData(link, auditor, dispatch, 1, 'accepted', contacts).then(() => setLoading(false))
        } else {
            setLoading(false)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userId, link]);

    return (
        <div style={{height: '100vh', overflowY: 'auto'}}>
            <Modal size="lg" show={toShow}>
                <ContactCard setToShow={setToShow} ContactCardToChat={showBook} updateCardContact={updateCardContact}/>
            </Modal>

            <List>
                {loading
                    ? Array.from(Array(6).keys()).map((key) => (
                        <ListItem key={key} button>
                            <SkeletonContactCardHeader/>
                        </ListItem>
                    ))
                    : booked?.length
                        ? <PaginatedContacts books={booked} setShowBook={addReservationToShow}/>
                        : NoDataList("Vide")}
            </List>
        </div>
    );
}

export default ContactCardList;
