import React, {useState} from 'react';
import {
    Box,
    Typography,
    Switch,
    Tooltip,
    CircularProgress
} from '@mui/material';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../config/apis";
import {addProfileConditions} from "../../reducer";
import {checkErrorMessage, deleteInObject} from "../../tools/Tools";

function AutoAccept() {

    const dispatch = useDispatch();
    const auditor = useSelector(state => state.axios.auditor);
    const {conditions} = useSelector(state => state.profile);
    const [loading, setLoading] = useState(false);

    const updateAutoAccept = () => {
        setLoading(true)
        let tmp = deleteInObject({...conditions});
        tmp['auto_accept'] = !conditions?.auto_accept
        auditor.put(apis.updateCondition, {...tmp}).then(async () => {
            toast.success("Auto acceptation de l'evenement à Jour.")
            await dispatch(addProfileConditions({...tmp}));
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 1,
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 400,
                    color: '#555',
                    textTransform: 'capitalize',
                }}
            >
                Auto acceptation de l'evenement
            </Typography>

            {/* Switch et tooltip à droite */}
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {/* Tooltip */}
                <Tooltip
                    title="En activant cette option, vous acceptez automatiquement les événements proposés lors d'une prise de contact."
                    placement="top"
                >
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#666',
                            marginRight: 1,
                            cursor: 'help',
                        }}
                    >
                        ?
                    </Typography>
                </Tooltip>

                {/* Switch */}
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <Switch
                        color="primary"
                        checked={conditions?.auto_accept}
                        onChange={updateAutoAccept}
                        disabled={loading}
                    />
                )}
            </Box>
        </Box>
    );
}

export default AutoAccept;
