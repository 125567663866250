import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addReservationDesc, addReservationEvent} from "../../../reducer";
import {changeFields, containsPhoneOrEmail, ISelectSearch} from "../../../tools/Tools";
import {Col, FormGroup, Row} from "reactstrap";

function AddEvent() {
    const dispatch = useDispatch();
    const [warningMessage, setWarningMessage] = useState("");
    const {event, description} = useSelector(state => state.contactForm);
    const {service} = useSelector(state => state.toContact);
    const {events} = service;


    useEffect(() => {
        if (description) {
            const {hasPhone, hasEmail} = containsPhoneOrEmail(description);
            if (hasPhone || hasEmail) {
                setWarningMessage(
                    "Attention : Il est strictement interdit de partager tout contact (email ou numéro) dans la description. Kantobiz ne garantit pas la sécurité des coordonnées personnelles."
                );
            } else {
                setWarningMessage(""); // Réinitialise si aucune information sensible n'est détectée
            }
        }
    }, [description]);

    return (
        <Row>
            <Col md="12" className="mb-3">
                <FormGroup>
                    {ISelectSearch(
                        "Événement proposé par l'artiste",
                        event,
                        (e, obj) => changeFields(null, obj, addReservationEvent, dispatch),
                        events,
                        false,
                        null,
                        "standard"
                    )}
                </FormGroup>
            </Col>
            <Col md="12" className="mb-2">
                <FormGroup>
                    <TextField
                        rows={2}
                        multiline
                        label="Brève description"
                        variant="filled"
                        value={description}
                        placeholder="Décrivez brièvement votre événement à l'artiste ?"
                        onChange={(e) => changeFields(null, e, addReservationDesc, dispatch)}
                        helperText={warningMessage || ""}
                        error={!!warningMessage} // Affiche un style d'erreur si un message d'avertissement est présent
                        sx={{
                            "& .MuiFilledInput-root": {
                                borderRadius: "10px",
                                backgroundColor: "#f1f3f4",
                                border: "1px solid rgba(0, 0, 0, 0.1)", // Légère bordure
                            },
                            "& .MuiFilledInput-root:before, & .MuiFilledInput-root:after": {
                                display: "none", // Masquer les lignes avant et après le champ
                            },
                        }}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
}

export default AddEvent;
