import Box from "@mui/material/Box";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useImperativeHandle, forwardRef} from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {addContactFormTabIndex, switchContactFormProcess, switchPaymentMethod} from "../../../reducer";
import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import {cardValidation, checkContactForm, orangeMoneyBtn} from "../../../tools/Tools";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useBlogTextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/blog';
import LaunchIcon from '@mui/icons-material/Launch';
import AddPhone from "../../profile/Edits/AddPhone";

const indicatorColors = ['#d93025', '#1a73e8', '#188038', '#e37400'];

/* eslint-disable-next-line no-empty-pattern */
export const AddPaymentMethods = forwardRef(({}, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const {service, tabIndex} = useSelector(state => state.toContact);
    const contact_amount = service?.contact_amount
    const contactForm = useSelector(state => state.contactForm);
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[1]});
    const {countryLog, isDev, countryOrangeMoneyAllowed, paymentMethod} = useSelector(state => state.global);
    const {phone, email} = useSelector((state) => state.profile.profileData);

    const onChangePaymentMethod = (e) => {
        let paymentType = e.value.type
        dispatch(switchPaymentMethod(paymentType))
    }

    useImperativeHandle(ref, () => ({
        cardValidate(orangeMoney, preparationProcessBeforeSend) {
            dispatch(switchContactFormProcess(true))
            if (tabIndex === 0) {
                if (paymentMethod === 'paypal') {
                    let tDate = new Date(
                        contactForm.eventDate.getTime() - contactForm.eventDate.getTimezoneOffset() * 60000,
                    )
                        .toISOString()
                        .slice(0, -1);
                    let contactCard = checkContactForm(contactForm, tDate, service, contactForm.amountProposed, email);
                    localStorage.setItem('contactForm', JSON.stringify(contactCard));
                }

                cardValidation(stripe, elements).then(token => {
                    if (token) {
                        preparationProcessBeforeSend(token, null);
                    } else {
                        dispatch(switchContactFormProcess(false))
                    }
                }).catch(error => {
                    dispatch(switchContactFormProcess(false))
                })
            } else {
                dispatch(switchContactFormProcess(false))
                if (!phone) {
                    toast.error('Merci d’enregistrer un numéro au préalable.')
                } else {
                    orangeMoney()
                }
            }
        },
    }));

    return (
        <div className="justify-content-center">
            {(contact_amount && isDev) || (contact_amount && countryOrangeMoneyAllowed.includes(countryLog.toLowerCase()))
                ? <Tabs
                    classes={tabsStyles}
                    value={tabIndex}
                    className="mb-3"
                    onChange={(e, index) => dispatch(addContactFormTabIndex(index))}
                    TabIndicatorProps={{
                        children: <div className={`MuiIndicator-${tabIndex}`}/>,
                    }}
                >
                    <Tab
                        classes={tabItem2Styles}
                        disableTouchRipple
                        label={
                            <div className={'MuiTabItem-labelGroup'}>
                                <div className={'MuiTabItem-label'}>
                                    Classique
                                </div>
                                <div className={'MuiTabItem-subLabel'}>Visa, Mastercard, paypal ...</div>
                            </div>
                        }
                        icon={<CreditCardIcon/>}
                    />
                    {orangeMoneyBtn(tabItem2Styles)}
                </Tabs> : null}
            {contact_amount && tabIndex === 0
                ? <div className="form-group">
                    <PaymentElement onChange={(e) => onChangePaymentMethod(e)}/>
                </div>
                : <div>
                    <div className="card p-2 rounded-lg">
                        <TextInfoContent
                            useStyles={useBlogTextInfoContentStyles}
                            overline={<div className="pt-2">
                                Orange Money
                                <hr/>
                            </div>}
                            heading=""
                            body={
                                <div className="row mt-0 pt-0 ml-1 mr-1">
                                    <div className="col-md-2 pt-2">
                                        <LaunchIcon sx={{color: 'gray !important'}} fontSize="large"/>
                                    </div>
                                    <div className="col-md-10 text-justify text-muted">
                                        En sélectionnant Orange Money, nous vous redirigerons vers une page sécurisée
                                        pour valider les étapes suivantes.
                                    </div>
                                </div>}
                        />
                    </div>
                    {!phone && (
                        <Box sx={{mt: 2}}>
                            <AddPhone />
                        </Box>
                    )}
                </div>}
        </div>
    )
})

export default AddPaymentMethods;
