import InputBase from "@material-ui/core/InputBase";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import React, {useState} from "react";
import toast from "react-hot-toast";
import SendIcon from '@mui/icons-material/Send';
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import {useChromeInputStyles} from '@mui-treasury/styles/input/chrome';
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {
    addBotMessageList,
    addReservationMessagesList,
    switchBotLoadingAnswer,
    switchPersonLoadingAnswer
} from "../../../reducer";
import {changeFields, checkErrorMessage, ISelectSearch, switchAnswers} from "../../../tools/Tools";

function ChatFooter({isBot, toScroll}) {

    const dispatch = useDispatch();
    const styles = useChromeInputStyles();
    const [textMsg, setTextMsg] = useState('');
    const [quest, setQuest] = useState('');
    const {messagesList} = useSelector(state => isBot ? state.chatBot : state.chatPerson);
    const auditor = useSelector(state => state.axios.auditor);
    const {contactToChat, currenciesChat, reservationsMessageList} = useSelector(state => state.global);
    const {symbol} = currenciesChat

    const getBotResponse = async (QuestText) => {
        dispatch(switchBotLoadingAnswer(true))
        let _textMsg = QuestText || textMsg
        setTextMsg('')
        let tmp = [...messagesList, <ChatMsg side={'right'} avatar={<SmartToyIcon/>} messages={[_textMsg]}/>]
        await dispatch(addBotMessageList(tmp))
        toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        auditor.post(apis.getBotAnswers(contactToChat?.id), {'user_message': _textMsg}).then(async (resp) => {
            dispatch(switchBotLoadingAnswer(false))
            let data = resp?.data
            dispatch(addBotMessageList([...tmp, switchAnswers(contactToChat, data, symbol)]))
            toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        }).catch((error) => {
            dispatch(switchBotLoadingAnswer(false))
            let errorMessage = checkErrorMessage(error).message;
            toast.error(errorMessage)
        })
    }

    const sendQuestionTips = async (obj) => {
        if (obj) {
            await setQuest(obj)
            await getBotResponse(obj)
        }
    }

    const sendUserMessage = async () => {
        auditor.post(apis.sendUserMessage(contactToChat?.reservation_id), {'message': textMsg}).then(async (resp) => {
            let msg = resp.data

            let tmp = {...reservationsMessageList}
            let reservation_id = msg?.reservation_id

            if (!tmp[reservation_id]) {
                tmp[reservation_id] = []
            }

            tmp[reservation_id] = [...tmp[reservation_id], msg]
            dispatch(addReservationMessagesList(tmp))

            dispatch(switchPersonLoadingAnswer(false))
            toScroll?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})

            setTextMsg('')
        }).catch((error) => {
            dispatch(switchPersonLoadingAnswer(false))
            let errorMessage = checkErrorMessage(error).message;
            toast.error(errorMessage)
        })
    }

    const dispatchFuncToGetAnswer = async () => {
        if (isBot) {
            await getBotResponse()
        } else {
            await sendUserMessage()
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && textMsg.length) {
            event.preventDefault();
            dispatchFuncToGetAnswer().then(() => null)
        }
    }

    return (
        <div>
            <div className="mb-2" hidden={!isBot}>
                {ISelectSearch(
                    "Exemples de question",
                    quest,
                    (e, obj) => sendQuestionTips(obj),
                    ["A quelle heure l'artiste devrait arriver ?", "Combien d'artiste"],
                    false,
                    null,
                    'standard')}
            </div>
            <InputBase
                onKeyPress={(e) => handleKeyPress(e)}
                value={textMsg}
                className={styles.root}
                style={{width: '100%'}}
                placeholder={'Poser votre question'}
                onChange={(e) => changeFields(setTextMsg, e)}
                startAdornment={
                    <Tooltip
                        title="Écrire votre message"
                        placement="bottom">
                        <IconButton className={styles.icon} sx={{borderRadius: '15px !important'}}>
                            <InfoOutlined />
                        </IconButton>
                    </Tooltip>
                }
                endAdornment={
                    <IconButton
                        sx={{borderRadius: '15px !important'}}
                        onClick={() => textMsg.length && dispatchFuncToGetAnswer()}>
                        <SendIcon/>
                    </IconButton>
                }
            />
        </div>
    );
}

export default ChatFooter;
