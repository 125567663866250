import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Spinner} from "reactstrap";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {Row, Col} from "reactstrap";
import {useWindowSize} from "./Tools";

function IStepper(props) {

    const {steps, auth} = props
    const width = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const googleData = useSelector(state => state.artistFromData.googleData);

    const btnReturn = () => {
        return (
            <button
                disabled={loading}
                onClick={() => setCurrentStep(currentStep - 1)}
                className="mt-1 mr-1 mb-1 width-full button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                <svg height="16" width="16" viewBox="0 0 16 16"
                     xmlns="http://www.w3.org/2000/svg">
                    <g fill="#ffffff" strokeWidth="1">
                        <path d="M9.98 0.67v12.63a2 2 0 0 1-2 2h-7.31" fill="none"
                              stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.32 5.32l4.66-4.66 4.65 4.66" fill="none" stroke="#ffffff"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>
        )
    }

    const next = async (index, value) => {
        await setLoading(true)
        let tmp = await value.isValid()
        if (!tmp)
            setCurrentStep(index + 1)
        await setLoading(false)
    }

    const btNext = (index, value) => {
      return (
          <button onClick={() => next(index, value)}
                  disabled={loading}
                  className="mt-1 mr-1 width-full mb-1 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
              {auth && currentStep === 2 ? "S'inscrire" : "Continuer"}&nbsp;
              {loading
                  ? <Spinner type="grow" size="sm"/>
                  : <SaveAsIcon fontSize="small" sx={{color: 'white!important'}}/>}
          </button>
      )
    }

    useEffect(() => {
        //
    }, []);

    return (
        <div className="justify-content-start ml-1">
            {steps.map((value, index) =>
                <div key={index}
                     className={"stepper__row" + (currentStep === index
                         ? " stepper__row--active" : " stepper__row--disabled")}>
                    <div className="stepper--vertical">
                        <div className="stepper--vertical__circle radius-1">
                        <span className="stepper--vertical__circle__text pb-4">
                          {index + 1}
                        </span>
                        </div>
                        <div className="stepper--vertical__details">

                            <h4 className="heading__three">{value.title}</h4>

                            <span className="paragraph">{value.description}</span>

                            <div className="button__rectangle">

                                {value?.component}

                                <Row className="justify-content-center pr-2">
                                    <Col md={currentStep !== 0 ? 8 : 12}>
                                        {btNext(index, value)}
                                    </Col>

                                    {currentStep !== 0
                                        ? <Col md={width < 600 ? 12 : 4}>
                                            {btnReturn()}
                                        </Col>
                                        : null}
                                </Row>
                            </div>

                            {currentStep === index && currentStep === steps.length - 1 && value?.component}
                            {currentStep === index && currentStep === steps.length - 1 && !auth &&
                                <button
                                    className="mt-1 mr-1 mb-1 button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon">
                                    Finaliser&nbsp;
                                    <svg height="16" width="16" viewBox="0 0 16 16"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g fill="#ffffff" strokeWidth="1">
                                            <path d="M9.98 15.3v-12.64a2 2 0 0 0-2-2h-7.31" fill="none"
                                                  stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.32 10.64l4.66 4.66 4.65-4.66" fill="none" stroke="#ffffff"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>
                                </button>}
                            {currentStep === index && Object.keys(googleData)?.length !== 0 && currentStep === steps.length - 1 && btNext(index, value)}
                            {currentStep === index && Object.keys(googleData)?.length !== 0 && currentStep === steps.length - 1 && btnReturn()}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default IStepper;
