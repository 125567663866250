import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {IconButton, Tooltip, useTheme} from "@mui/material";
import React, {useState, useEffect} from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {useGmailListItemStyles} from '@mui-treasury/styles/listItem/gmail';
import {useSelector} from "react-redux";
import ContactCardHeader from "./ContactCardHeader";

const PaginatedContacts = ({books, setShowBook}) => {
    const theme = useTheme();
    const itemsPerPage = 10;
    const {userId} = useSelector(state => state.profile);
    const [currentPage, setCurrentPage] = useState(0);
    const styles = useGmailListItemStyles({collapsed: false, margin: "5px !important"});
    const selectedStyles = useGmailListItemStyles({
        color: '#da3125',
        margin: "5px !important",
        collapsed: false
    });

    const [activeBookId, setActiveBookId] = useState(null);

    const startIndex = currentPage * itemsPerPage;
    const paginatedBooks = books.slice(startIndex, startIndex + itemsPerPage);

    const handleNextPage = () => {
        if ((currentPage + 1) * itemsPerPage < books.length) {
            setCurrentPage(currentPage + 1);
            setActiveBookId(null); // Réinitialiser la sélection pour mettre à jour au changement de page
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            setActiveBookId(null); // Réinitialiser la sélection pour mettre à jour au changement de page
        }
    };

    const handleSelectLine = (id) => {
        setActiveBookId(id);
    };


    useEffect(() => {
        // Si aucun élément n'est sélectionné, définissez le premier élément visible comme sélectionné
        if (!activeBookId && paginatedBooks.length > 0) {
            setActiveBookId(paginatedBooks[0].id);
        }
    }, [currentPage, activeBookId, paginatedBooks]);

    return (
        <div>
            <List>
                {paginatedBooks.map((book) => (
                    <Tooltip title="Appuyer pour afficher" placement="top" key={book?.id || ""}>
                        <ListItem
                            button
                            key={book.id}
                            className="p-0 border-bottom"
                            classes={activeBookId === book.id ? selectedStyles : styles}
                            selected={activeBookId === book.id}
                            onClick={() => handleSelectLine(book.id)}
                        >
                            <ContactCardHeader
                                book={book}
                                userId={userId}
                                setShowBook={setShowBook}
                            />
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
            {books.length > itemsPerPage && (
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <IconButton
                        onClick={handlePreviousPage}
                        disabled={currentPage === 0}
                        className="border-right mb-2 mt-2 border-bottom"
                        sx={{mr: 0.5, ml: 2, color: theme.palette.primary.main}}
                    >
                        <KeyboardArrowLeftIcon sx={{fontSize: 24}}/>
                    </IconButton>
                    <IconButton
                        onClick={handleNextPage}
                        disabled={(currentPage + 1) * itemsPerPage >= books.length}
                        className="border-right mb-2 mt-2 border-bottom"
                        sx={{ml: 0.5, mr: 2, color: theme.palette.primary.main}}
                    >
                        <KeyboardArrowRightIcon sx={{fontSize: 24}}/>
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default PaginatedContacts;
