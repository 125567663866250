export function addCountries(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_COUNTRIES",
            data: data
        });
    };
}

export function addCountryLog(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_COUNTRY_LOG",
            data: data
        });
    };
}

export function addBotMessageList(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_BOT_MESSAGE_LIST",
            data: data
        });
    };
}


export function switchBotMsgProcess(data) {
    return async dispatch => {
        dispatch({
            type: "SWITCH_BOT_MESSAGE_PROCESS",
            data: data
        });
    };
}

export function switchBotLoadingAnswer(data) {
    return async dispatch => {
        dispatch({
            type: "SWITCH_BOT_LOADING_ANSWER",
            data: data
        });
    };
}

export function addPersonMessageList(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PERSON_MESSAGE_LIST",
            data: data
        });
    };
}


export function switchPersonMsgProcess(data) {
    return async dispatch => {
        dispatch({
            type: "SWITCH_PERSON_MESSAGE_PROCESS",
            data: data
        });
    };
}

export function switchPersonLoadingAnswer(data) {
    return async dispatch => {
        dispatch({
            type: "SWITCH_PERSON_LOADING_ANSWER",
            data: data
        });
    };
}

export function addChatCurrencies(data) {
    return async dispatch => {
        dispatch({
            type: "SET_CHAT_CURRENCIES",
            data: data
        });
    };
}

export function addReservationMessagesList(data) {
    return async dispatch => {
        dispatch({
            type: "SET_RESERVATIONS_MESSAGES_LIST",
            data: data
        });
    };
}

export function switchLoadingDiscussions(data) {
    return async dispatch => {
        dispatch({
            type: "SET_DISCUSSIONS_LOADING",
            data: data
        });
    };
}

export function addChatAmountDetail(data) {
    return async dispatch => {
        dispatch({
            type: "SET_CHAT_AMOUNT_DETAILS",
            data: data
        });
    };
}

export function addNameForCertification(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CERTIFICATION_NAME",
            data: data
        });
    };
}

export function addLastNameForCertification(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CERTIFICATION_LASTNAME",
            data: data
        });
    };
}

export function addBirthForCertification(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CERTIFICATION_BIRTH",
            data: data
        });
    };
}

export function addArtistNameForCertification(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CERTIFICATION_ARTIST_NAME",
            data: data
        });
    };
}

export function addCertificationDocument(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CERTIFICATION_DOCUMENT",
            data: data
        });
    };
}

export function addCertificationObject(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CERTIFICATION_OBJECT",
            data: data
        });
    };
}

export function addCountryLogId(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_COUNTRY_LOG_ID",
            data: data
        });
    };
}

export function addGlobalCurrencySymbol(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_GLOBAL_CURRENCY_SYMBOL",
            data: data
        });
    };
}

export function addAuthCredentials(data) {
    return async dispatch => {
        dispatch({
            type: "AUTH_CREDENTIALS_RETURN",
            data: data
        });
    };
}

export function addGlobalCurrencyCode(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_GLOBAL_CURRENCY_CODE",
            data: data
        });
    };
}

export function configAxios(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_TOKEN_IP_AXIOS_AUDITOR",
            data: data
        });
    };
}

export function addIPAddress(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_IP_ADDRESS",
            data: data
        });
    };
}

export function addArtistSignInName(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_SIGN_IN_NAME",
            data: data
        });
    };
}

export function addArtistSignInRules(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_SIGN_IN_RULES",
            data: data
        });
    };
}

export function addArtistSignInEmail(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_SIGN_IN_EMAIL",
            data: data
        });
    };
}

export function addArtistSignInPass(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_SIGN_IN_PASSWORD",
            data: data
        });
    };
}

export function addServiceFormID(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_ID",
            data: data
        });
    };
}

export function addServiceFormTest(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_TEST",
            data: data
        });
    };
}

export function addServiceFormTitle(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_TITLE",
            data: data
        });
    };
}


export function addServiceFormCountry(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_COUNTRY",
            data: data
        });
    };
}


export function addServiceFormCities(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_CITIES",
            data: data
        });
    };
}


export function addServiceFormEvents(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_EVENTS",
            data: data
        });
    };
}


export function addServiceFormThematics(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_THEMATICS",
            data: data
        });
    };
}


export function addServiceFormDesc(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_DESC",
            data: data
        });
    };
}

export function addServiceFormPrice(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_PRICE",
            data: data
        });
    };
}


export function addServiceFormGalleries(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_GALLERIES",
            data: data
        });
    };
}

export function addServiceFormHidden(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_HIDDEN",
            data: data
        });
    };
}

export function addServiceFormUserId(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_USER_ID",
            data: data
        });
    };
}

export function addServiceFormMatId(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_MAT_ID",
            data: data
        });
    };
}

export function addServiceFormSpecDate(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_SPEC_DATE",
            data: data
        });
    };
}

export function addServiceFormNbArtist(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_NB_ARTIST",
            data: data
        });
    };
}

export function addServiceFormRefundPolicy(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_REFUND_POLICY",
            data: data
        });
    };
}

export function addServiceFormPrepTime(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_PREP_TIME",
            data: data
        });
    };
}

export function addServiceFormDurService(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_DUR_SERVICE",
            data: data
        });
    };
}

export function addServiceFormTravelExp(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_TRAVEL_EXP",
            data: data
        });
    };
}

export function addServiceFormUnitPrepTime(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_UNIT_PREP_TIME",
            data: data
        });
    };
}

export function addServiceFormUnitDurService(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SERVICE_FORM_UNIT_DUR_SERVICE",
            data: data
        });
    };
}

export function addArtistSignInArtistName(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_SIGN_IN_ARTIST_NAME",
            data: data
        });
    };
}

export function addEventTypes(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_EVENT_TYPES",
            data: data
        });
    };
}

export function addArtistTypes(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_TYPES",
            data: data
        });
    };
}

export function addArtistFormGoogleData(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_SIGN_IN_GOOGLE_DATA",
            data: data
        });
    };
}

export function addArtistType(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_TYPE",
            data: data
        });
    };
}

export function addDateToSearch(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_DATE_TO_SEARCH",
            data: data
        });
    };
}

export function addCityToSearch(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CITY_TO_SEARCH",
            data: data
        });
    };
}

export function addThematicsToSearch(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_THEMATICS_TO_SEARCH",
            data: data
        });
    };
}

export function addEventToSearch(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_EVENTS_TO_SEARCH",
            data: data
        });
    };
}

export function addSearchResults(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SEARCH_RESULTS",
            data: data
        });
    };
}

export function changeLoadingResult(data) {
    return async dispatch => {
        dispatch({
            type: "CHANGE_LOADING_RESULT",
            data: data
        });
    };
}

export function addDjServicesData(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_DJ_SERVICES_DATA",
            data: data
        });
    };
}

export function addOthersServicesData(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_OTHERS_SERVICES_DATA",
            data: data
        });
    };
}

export function addArtistMusicianServicesData(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_ARTIST_MUSICIAN_SERVICES_DATA",
            data: data
        });
    };
}

export function addSocket(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_SOCKET",
            data: data
        });
    };
}

export function setIsDev(data) {
    return async dispatch => {
        dispatch({
            type: "SET_IS_DEV",
            data: data
        });
    };
}

export function setCountryCode(data) {
    return async dispatch => {
        dispatch({
            type: "SET_COUNTRY_CODE",
            data: data
        });
    };
}

export function setScheduleDropDown(data) {
    return async dispatch => {
        dispatch({
            type: "SET_SCHEDULE_DROPDOWN",
            data: data
        });
    };
}

export function setContactToChat(data) {
    return async dispatch => {
        dispatch({
            type: "SET_CONTACT_TO_CHAT",
            data: data
        });
    };
}

export function addProfileUserId(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_USER_ID",
            data: data
        });
    };
}

export function addProfileDataContacts(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_USER_CONTACT",
            data: data
        });
    };
}

export function addProfileContactsArchived(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_USER_CONTACTS_ARCHIVED",
            data: data
        });
    };
}

export function addProfileContactsToFinish(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_USER_CONTACTS_TO_FINISH",
            data: data
        });
    };
}

export function addWalletTransfers(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_WALLET_TRANSFERS",
            data: data
        });
    };
}

export function addProfileDataContactsLength(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_USER_CONTACT_LENGTH",
            data: data
        });
    };
}

export function addProfileNotificationLength(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_NOTIFICATIONS_LENGTH",
            data: data
        });
    };
}

export function addProfileNotificationRead(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_NOTIFICATIONS_READ",
            data: data
        });
    };
}

export function addProfileNotificationUnread(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_NOTIFICATIONS_UNREAD",
            data: data
        });
    };
}

export function addWallet(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_WALLET",
            data: data
        });
    };
}

export function addProfileData(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_DATA",
            data: data
        });
    };
}

export function addProfileConditions(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CONDITIONS",
            data: data
        });
    };
}

export function addProfileBanking(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_BANKING",
            data: data
        });
    };
}

export function addProfileRole(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_ROLE",
            data: data
        });
    };
}

export function addProfileNotes(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_NOTES",
            data: data
        });
    };
}

export function addProfileFollowers(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_FOLLOWERS",
            data: data
        });
    };
}

export function addProfileFollowings(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_FOLLOWINGS",
            data: data
        });
    };
}

export function addPaymentPaid(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PAYMENT_PAID",
            data: data
        });
    };
}

export function addPaymentRefunded(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PAYMENT_REFUNDED",
            data: data
        });
    };
}

export function addPaymentPending(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PAYMENT_PENDING",
            data: data
        });
    };
}

export function addProfileBankingCurrencies(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_BANKING_CURRENCIES",
            data: data
        });
    };
}

export function addProfileBankingCurrenciesOriginal(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_BANKING_CURRENCIES_ORIGINAL",
            data: data
        });
    };
}

export function addProfileBankingCurrenciesCode(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_BANKING_CURRENCIES_CODE",
            data: data
        });
    };
}

export function addProfileServices(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_SERVICES",
            data: data
        });
    };
}

export function addReservationsLength(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATIONS_LENGTH",
            data: data
        });
    };
}

export function addContactService(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CONTACT_SERVICE",
            data: data
        });
    };
}

export function addContactFormTabIndex(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CONTACT_FORM_TABINDEX",
            data: data
        });
    };
}

export function addContactFormToSend(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CONTACT_FORM_TO_SEND",
            data: data
        });
    };
}

export function switchContactFormProcess(data) {
    return async dispatch => {
        dispatch({
            type: "SWITCH_CONTACT_FORM_PROCESS",
            data: data
        });
    };
}

export function switchPaymentMethod(data) {
    return async dispatch => {
        dispatch({
            type: "SWITCH_PAYMENT_METHOD",
            data: data
        });
    };
}

export function addContactCurrency(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CONTACT_CURRENCY",
            data: data
        });
    };
}

export function addContactCurrencySymbol(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CONTACT_CURRENCY_SYMBOL",
            data: data
        });
    };
}

export function addReservationName(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_NAME",
            data: data
        });
    };
}

export function addReservationPrice(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_PRICE",
            data: data
        });
    };
}


export function addReservationDesc(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_DESC",
            data: data
        });
    };
}

export function addContactFormEventProposedAmount(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_CONTACT_FORM_PROPOSED_AMOUNT",
            data: data
        });
    };
}

export function addReservationTravel(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_TRAVEL",
            data: data
        });
    };
}

export function addReservationEvent(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_EVENT",
            data: data
        });
    };
}

export function addReservationCity(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_CITY",
            data: data
        });
    };
}

export function addReservationAddress(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_ADDRESS",
            data: data
        });
    };
}

export function addReservationLastName(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_LASTNAME",
            data: data
        });
    };
}

export function addReservationPostalCode(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_POSTAL_CODE",
            data: data
        });
    };
}


export function setMsgTabIndex(data) {
    return async dispatch => {
        dispatch({
            type: "SET_MESSAGES_TAB_INDEX",
            data: data
        });
    };
}

export function swapSideBarExpandIcon(data) {
    return async dispatch => {
        dispatch({
            type: "SWAP_SIDEBAR_EXPAND_ICON",
            data: data
        });
    };
}


export function setContactIdToShow(data) {
    return async dispatch => {
        dispatch({
            type: "SET_CONTACT_ID_TO_SHOW",
            data: data
        });
    };
}

export function addReservationEventDate(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_RESERVATION_EVENT_DATE",
            data: data
        });
    };
}

export function addProfileCheckedRole(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_ROLE",
            data: data
        });
    };
}

export function addProfileCheckedUsersRated(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_USERS_RATED",
            data: data
        });
    };
}

export function addProfileCheckedUserId(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_USER_ID",
            data: data
        });
    };
}

export function addProfileCheckedFollowers(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_FOLLOWERS",
            data: data
        });
    };
}

export function addProfileCheckedFollowings(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_FOLLOWINGS",
            data: data
        });
    };
}

export function addProfileCheckedData(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_DATA",
            data: data
        });
    };
}

export function addProfileCheckedServices(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_SERVICES",
            data: data
        });
    };
}

export function addProfileCheckedNotes(data) {
    return async dispatch => {
        dispatch({
            type: "ADD_PROFILE_CHECKED_NOTES",
            data: data
        });
    };
}
