import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import {Box, Button, CircularProgress, TextField, Tooltip, Typography} from "@mui/material";
import {addProfileData} from "../../../reducer";
import {changeFields, checkErrorMessage, deleteNullInObj, fillInBodyForm} from "../../../tools/Tools";
import apis from "../../../config/apis";
import Conf from "../../../config/tsconfig.json";

function AddPhone({closeModal}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auditorForm = useSelector((state) => state.axios.auditorForm);
    const {countryLog} = useSelector((state) => state.global);
    const profileData = useSelector((state) => state.profile.profileData);
    const [phone, setPhone] = useState(profileData.phone);

    const addPhone = async (_phone) => {
        let tData = deleteNullInObj({...profileData});
        tData["phone"] = _phone?.toString();
        delete tData["is_certified"];
        const bodyFormData = fillInBodyForm(new FormData(), tData);
        await auditorForm
            .put(apis.updateProfile, bodyFormData)
            .then(async (resp) => {
                await dispatch(addProfileData(resp.data));
                toast.success("Votre contact a été mis à jour");
                closeModal && closeModal();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(checkErrorMessage(error).message);
            });
    };

    const phoneVerify = async () => {
        setLoading(true);

        let _phone = "";
        if (countryLog?.toLowerCase === "madagascar") {
            _phone = "+261" + phone.substring(phone.length - 9, phone.length);
        } else {
            _phone = phone?.toString();
        }

        await axios
            .get(Conf.configs.PhoneValidation + _phone)
            .then((resp) => {
                let data = resp.data;
                if (data.valid) {
                    addPhone(data.format?.international);
                } else {
                    setLoading(false);
                    toast.error("Le numéro que vous avez indiqué est invalide.");
                }
            })
            .catch((error) => console.log(error));
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            padding={2}
            sx={{backgroundColor: "white", borderRadius: 2, boxShadow: 3, width: "100%"}}
        >
            {!closeModal && (
                <Typography variant="body2" color="textSecondary">
                    • Veuillez enregistrer un numéro Orange.
                </Typography>
            )}

            <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={!closeModal ? "Ajouter un numéro orange" : "Numéro de téléphone"}
                value={phone}
                onChange={(e) => changeFields(setPhone, e)}
            />

            <Box display="flex" justifyContent="space-between" width="100%">
                {closeModal && (
                    <Tooltip title="Annuler" placement="top">
                        <Button
                            color="error"
                            variant="outlined"
                            disabled={loading}
                            onClick={() => !loading && closeModal()}
                            sx={{textTransform: "none"}}
                        >
                            Annuler
                        </Button>
                    </Tooltip>
                )}

                <Tooltip title={!closeModal ? "Enregistrer et faire le contact" : "Enregistrer"} placement="top">
                    <Button
                        onClick={phoneVerify}
                        color="success"
                        variant="contained"
                        disabled={loading}
                        sx={{textTransform: "none"}}
                    >
                        {loading ? <CircularProgress size={24}/> : "Enregistrer"}
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default AddPhone;
