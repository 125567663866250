import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useRef, useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import apis from "../../../config/apis";
import {addProfileBanking} from "../../../reducer";
import {
    checkErrorMessage,
    deleteInObject,
    deleteNullInObj,
    useWindowSize,
    validatorBanking
} from "../../../tools/Tools";
import {useStripe} from "@stripe/react-stripe-js";
import Box from "@material-ui/core/Box";
import AddBankAccount from "./AddBankAccount";
import BankBtnHeaders from "./BankBtnHeaders";
import Transfers from "./Transfers";
import {Wallet} from "./Wallet";

function BankAccount() {

    const stripe = useStripe();
    const walletRef = useRef();
    const dispatch = useDispatch();
    const width = useWindowSize();
    const auditor = useSelector(state => state.axios.auditor);
    const [up, setUp] = useState(false);
    const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const {countryCode, globalCurrencyCode, countryLog} = useSelector(state => state.global);
    const {currencies_banking_code, banking, profileData} = useSelector(state => state.profile);

    const dispatchFunc = (func) => {
        let validate = validatorBanking(banking, countryCode, countryLog);
        if (validate['error']) {
            toast.error(validate.message);
        } else {
            setLoading(true)
            if (func === "add")
                addAccount().then(r => null);
            else editAccount()
        }
    };

    const addAccount = async () => {
        await auditor.get(apis.getStripeDocumentsId).then(async (resp) => {
            const newAccount = await stripe.createToken('account', {
                business_type: 'individual',
                individual: {
                    last_name: "MAMPIONONA",
                    first_name: "Cynthion Mahavonjy",
                    gender: "male",
                    email: "mahavonjy.cynthion@gmail.com",
                    phone: "+33652359897",
                    dob: {
                        day: 12,
                        month: 3,
                        year: 1998
                    },
                    address: {
                        city: "Moissy Cramayel",
                        line1: "15 chemin des charmilles",
                        postal_code: "77550",
                    },
                },
                tos_shown_and_accepted: true,
            });

            const newAccountVerification = await stripe.createToken('account', {
                individual: {
                    verification: {
                        additional_document: {front: resp.data['att_id']},
                        document: {front: resp.data['pass_id']},
                    },
                }
            });

            let stripe_token = [newAccount.token.id, newAccountVerification.token.id]
            const {name, lastname, iban, swift} = banking
            let createBank = {
                name,
                lastname,
                iban,
                swift,
                stripe_token,
                country: countryLog,
                email: profileData.email,
                country_code: countryCode,
                currency: globalCurrencyCode
            };

            auditor.post(apis.addBank, deleteNullInObj(createBank)).then((resp) => {
                setAdd(false)
                setLoading(false)
                dispatch(addProfileBanking(resp.data));
                toast.success("Votre compte a été bien ajouté")
            }).catch((error) => {
                setLoading(false)
                let errorMessage = checkErrorMessage(error).message;
                toast.error(errorMessage)
            })
        })
    }

    const editAccount = () => {
        let tmpBanking = {...banking};
        tmpBanking = deleteInObject(tmpBanking);
        tmpBanking['stripe_token'] = ['no_token']
        tmpBanking['country_code'] = countryCode
        auditor.put(apis.upBank, deleteNullInObj(tmpBanking)).then((resp) => {
            setUp(false)
            setLoading(false)
            dispatch(addProfileBanking(resp.data));
            toast.success("Votre compte a été bien mis a jour")
        }).catch((error) => {
            setLoading(false)
            let errorMessage = checkErrorMessage(error);
            toast.error(errorMessage.message)
        })
    }

    useEffect(() => {
        //
    }, [])

    return (
        <Row>
            <Modal size="md" show={add || up}>
                <AddBankAccount
                    add={add}
                    setAdd={setAdd}
                    setUp={setUp}
                    loading={loading}
                    dispatchFunc={dispatchFunc}/>
            </Modal>

            <BankBtnHeaders walletRef={walletRef}/>

            <Col sm="12" className="m-0 p-0 overflow-auto" style={{height: "65vh"}}>
                <Box className="radius-1" style={{height: '100%'}}>
                    <Row className="justify-content-between p-3">
                        <Col md={8} className={width < 500 ? "" : "border-right"}>
                            <Wallet ref={walletRef}/>
                        </Col>
                        <Col md={4}>
                            <div className="justify-content-center mb-2">
                                {!Object.keys(banking)?.length
                                    ? <div className="row m-1 text-center flex-column">
                                        <Button
                                            variant="outlined"
                                            disabled={!currencies_banking_code?.includes(globalCurrencyCode)}
                                            onClick={() => setAdd(true)}
                                            endIcon={<AddIcon/>}>
                                            Ajouter un compte
                                        </Button>
                                        <small className="text-bold-300 mt-1">
                                            Veuillez ajouter les informations sur votre IBAN/RIB
                                        </small>
                                    </div>
                                    : <div className="media-body">
                                        <h6 className="text-bold-300">
                                            {banking.lastname}&nbsp;
                                            <small>{banking.name}</small>&nbsp;
                                            <IconButton
                                                className="mb-2"
                                                onClick={() => setUp(true)}>
                                                <EditIcon fontSize="small"/>
                                            </IconButton>
                                        </h6>
                                        <small className="text-bold-300">
                                            IBAN: {banking?.iban?.length >= 5 && Array.from(Array(banking.iban.length - 4).keys()).map(() => "*")}
                                            &nbsp;{banking?.iban?.length >= 5 && banking.iban.slice(-4)}
                                        </small>
                                    </div>}
                            </div>
                        </Col>
                        <Col md={12} className="border-bottom"/>
                    </Row>
                    <Transfers/>
                </Box>
            </Col>
        </Row>
    )
}

export default BankAccount;
