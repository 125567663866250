import React, {useEffect, useRef, useState, useCallback} from "react";
import {Analytics} from '@vercel/analytics/react';
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Footer from "components/Footer/Footer.js";
import NavbarCustom from "../components/Navbars/NavbarCustom.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import toast, {Toaster} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";
import {routes} from "routes";
import {setContactIdToShow, setMsgTabIndex, swapSideBarExpandIcon} from "../reducer";
import CustomToast from "../tools/CustomToast";
import NotificationMsg from "../tools/NotificationMsg";
import initializeSocketEvents from "./sockets/initializeSocketEvents";
import InitializeSocket from "./sockets/Socket";
import {addContactCardToChat, useWindowSize} from "../tools/Tools";


function Dashboard(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const width = useWindowSize();
    const [routesSwitch, setRoutesSwitch] = useState([]);
    const mainPanel = useRef();
    const socketIo = useRef(null);
    const auth = useSelector(state => state.session.authenticated);
    const {countries, reservationsMessageList} = useSelector(state => state.global);
    const profileData = useSelector(state => state.profile);
    const userId = profileData?.userId
    const {certification} = useSelector((state) => state.certificationData);
    const profileDataRef = useRef(profileData);
    const certificationRef = useRef(certification);
    const reservationsMessageListRef = useRef(reservationsMessageList);

    const dispatchRoutes = (data) => {
        return data.map((prop, index) => {
            if (prop.component?.length) {
                return dispatchRoutes(prop.component)
            }
            return (<Route path={prop.path} element={prop.component} key={'route_' + index}/>);
        })
    }

    const chargeRouteSwitch = () => {
        let t = dispatchRoutes(routes(profileData?.role))
        setRoutesSwitch(t)
    }

    const openContactCardModal = async (notification) => {
        dispatch(swapSideBarExpandIcon(true));
        dispatch(setContactIdToShow(notification.element_id));

        const route = notification?.subject === 'declined'
            ? '/archives'
            : notification?.isArtist
                ? '/artist-contacted-demand'
                : '/artist-contact-demand';

        navigate(route);
    };

    const openDiscus = async (notification, data) => {
        let ContactCardToChat;
        const events = [...data?.contacts.book.accepted, ...data?.contacts.auditor.accepted];
        if (notification?.type === "contact_card_notify") {
            ContactCardToChat = events.filter((value) => value.id === notification.element_id)[0]
        } else {
            ContactCardToChat = events.filter((value) => value.reservation.id === notification.element_id)[0]
        }

        await dispatch(setMsgTabIndex(notification?.isArtist ? 'book' : 'auditor'))
        await addContactCardToChat(dispatch, ContactCardToChat, countries)

        document.getElementById("open_discussion").click()
    }

    const dispatchNewNotificationToast = (notification, data) => {
        const title = NotificationMsg(notification)?.title;

        const isContactCardNotify =
            notification?.type === "contact_card_notify" &&
            ['pending', 'declined'].includes(notification?.subject);

        const isReservationNotify =
            notification?.type === "reservation_notify" &&
            ['finish', 'declined'].includes(notification?.subject);

        const onClick = (isContactCardNotify || isReservationNotify)
            ? () => openContactCardModal(notification)
            : () => openDiscus(notification, data);

        toast.custom(
            (t) => <CustomToast title={title} onClick={onClick} />,
            { duration: 45000 }
        );
    };

    useEffect(() => {
        profileDataRef.current = {...profileData};
        certificationRef.current = {...certification};
        reservationsMessageListRef.current = {...reservationsMessageList};
    }, [profileData, certification, reservationsMessageList]);

    // Fonction pour gérer l'initialisation des événements socket
    const setupSocketEvents = useCallback(() => {
        if (socketIo.current) {
            initializeSocketEvents(
                socketIo.current,
                dispatchNewNotificationToast,
                dispatch,
                profileDataRef,
                userId,
                certificationRef,
                reservationsMessageListRef
            );
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [dispatch, userId]);

    useEffect(() => {
        if (auth && userId) {
            // Initialisation du socket lors de la connexion de l'utilisateur
            socketIo.current = InitializeSocket(userId);

            // Initialisation des événements de socket
            setupSocketEvents();

            return () => {
                // Déconnexion du socket lors du démontage du composant ou du changement d'utilisateur
                socketIo.current?.disconnect();
                socketIo.current = null;
            };
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auth, userId, setupSocketEvents]);

    useEffect(() => {
        chargeRouteSwitch()
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div className="wrapper">
            <Analytics/>

            <Toaster
                reverseOrder={false}
                containerStyle={{maxWidth: width < 600 ? 200 : 240}}
                position={width < 600 ? "top-center" : "bottom-left"}
                toastOptions={{
                    duration: width < 600 ? 4000 : 8000,
                    style: {
                        border: '1px solid #713200',
                        padding: '16px',
                        color: '#713200',
                    },
                    iconTheme: {
                        primary: '#714600',
                        secondary: '#FFFAEE',
                    }
                }}
            />

            <Sidebar {...props} routes={routes(profileData?.role)}/>

            <div className="main-panel" ref={mainPanel}>
                <NavbarCustom {...props} />

                <Routes>
                    {routesSwitch}
                </Routes>

                <Footer fluid/>
            </div>

            {auth && <FixedPlugin/>}
        </div>
    );
}

export default Dashboard;
