import {useOnlineAvatarStyles} from "@mui-treasury/styles/avatar/online";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Badge from "@mui/material/Badge";
import React from 'react';
import {Column, Item} from "@mui-treasury/components/flex";
import Avatar from "@material-ui/core/Avatar";
import {useGrowIconButtonStyles} from '@mui-treasury/styles/iconButton/grow';
import IconButton from "@material-ui/core/IconButton";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import TextsmsIcon from "@mui/icons-material/Textsms";
import VerifiedIcon from "@mui/icons-material/Verified";
import ScheduleIcon from '@mui/icons-material/Schedule';
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {Row} from "reactstrap";
import {addContactCardToChat} from "../../../tools/Tools";

function DiscussCard({book}) {

    const dispatch = useDispatch();
    const defaultGrowStyles = useGrowIconButtonStyles();
    const {msgTabIndex, countries} = useSelector(state => state.global);
    const styles = useOnlineAvatarStyles({color: '#f05a99'});

    return (
        <Column gap={1} key={book?.id + '_chat_msg'} className="border-bottom px-4 py-2">
            <Row className="justify-content-center">
                <Item position={'middle'} className="col-2">
                    <div className={styles.root}>
                        <Avatar src={msgTabIndex === 'auditor'
                            ? book?.reservation?.auditor_photo
                            : book?.reservation?.artist_photo}/>
                    </div>
                </Item>
                {/* eslint-disable no-useless-concat */}
                <div className={"col-8"}>
                    <Info useStyles={useThemeXsInfoStyles}>
                        <InfoTitle>
                            {msgTabIndex === 'auditor'
                                ? book?.lastname + ' ' + book?.name
                                : book?.reservation?.artist_name}
                            {msgTabIndex === 'book'
                                ? book?.is_certified
                                    ? <Tooltip style={{cursor: 'pointer'}} title="Profil verifié" placement='top'>
                                        <VerifiedIcon
                                            sx={{
                                                marginLeft: 0.5,
                                                marginBottom: 0.5,
                                                color: '#1976d2 !important',
                                                fontSize: '14px', // Taille précise
                                            }}/>
                                    </Tooltip>
                                    : null : null}
                        </InfoTitle>
                        <InfoSubtitle>
                            {book?.reservation?.event}
                            {msgTabIndex === 'book'
                                ? <small>
                                    &nbsp;-&nbsp;
                                    <strong>
                                        {book?.lastname + ' ' + book?.name}
                                    </strong>
                                </small>
                                : null}
                        </InfoSubtitle>
                        <InfoCaption>
                            Le&nbsp;{dayjs(book?.reservation?.event_date).format("DD-MM-YYYY à HH:mm A")}
                        </InfoCaption>
                    </Info>
                </div>
                <Item minWidth={30} textAlign={'right'} className="col-2">
                    <Tooltip title="Ouvrir la discussion" placement="bottom">

                        <IconButton
                            classes={defaultGrowStyles}
                            onClick={() => addContactCardToChat(dispatch, book, countries)}>
                            <Badge
                                color="transparent"
                                badgeContent={
                                    book?.reservation?.status === 'paid'
                                        ? <EventAvailableIcon fontSize='small' sx={{color: '#ce93d8!important'}}/>
                                        : <ScheduleIcon fontSize='small' sx={{color: '#757575!important'}}/>
                                }
                                invisible={['pending'].includes(book?.reservation?.status)}>
                                <TextsmsIcon sx={{
                                    color: ['accepted', 'paid'].includes(book?.reservation?.status)
                                        ? '#9bdf9e !important'
                                        : 'gray !important'
                                }}/>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Item>
            </Row>
        </Column>
    )
}

export default DiscussCard;
