const initState = {
    name: "",
    birth: "",
    lastname: "",
    artistName: "",
    documents: [],
    certification: null,
};

const CertificationData = (state = initState, action) => {
    switch (action.type) {
        case "ADD_CERTIFICATION_NAME":
            return {
                ...state,
                name: action.data
            };
        case "ADD_CERTIFICATION_LASTNAME":
            return {
                ...state,
                lastname: action.data
            };
        case "ADD_CERTIFICATION_BIRTH":
            return {
                ...state,
                birth: action.data
            };
        case "ADD_CERTIFICATION_ARTIST_NAME":
            return {
                ...state,
                artistName: action.data
            };
        case "ADD_CERTIFICATION_DOCUMENT":
            return {
                ...state,
                documents: action.data,
            };
        case "ADD_CERTIFICATION_OBJECT":
            return {
                ...state,
                certification: action.data
            };
        default:
            return state;
    }
};

export default CertificationData;
