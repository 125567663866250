import React from 'react';
import dayjs from "dayjs";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Avatar, Box, Typography, IconButton, Tooltip} from '@mui/material';
import {Delete as DeleteIcon, Check as CheckIcon} from '@mui/icons-material';
import PropTypes from 'prop-types';

const generateRandomKey = () => {
    return Math.random().toString(36).substring(2, 15);
};

const MyChatMsg = ({side, read, message, avatar, date, onDelete}) => {

    const isLeft = side === 'left';
    const bubbleBgColor = isLeft ? '#f5f5f5' : '#ffebee';

    return (
        <Box
            key={generateRandomKey()}
            display="flex"
            flexDirection={isLeft ? 'row' : 'row-reverse'}
            alignItems="flex-start"
            mb={2}
            onMouseEnter={(e) => e.currentTarget.querySelector('.delete-icon')?.classList.remove('hidden')}
            onMouseLeave={(e) => e.currentTarget.querySelector('.delete-icon')?.classList.add('hidden')}
        >
            {isLeft && (
                <Avatar
                    alt="Avatar"
                    src={avatar && avatar.startsWith('http') ? avatar : undefined}
                    sx={{width: 40, height: 40, mr: 1}}
                >
                    {!avatar ? (
                        <AccountCircleIcon fontSize="large" sx={{color: "#fafafa !important"}}/>
                    ) : (
                        !avatar.startsWith('http') && avatar.charAt(0).toUpperCase()
                    )}
                </Avatar>
            )}

            <Box sx={{maxWidth: '70%', position: 'relative'}}>
                <Box
                    sx={{
                        backgroundColor: bubbleBgColor,
                        borderRadius: 2,
                        p: 1.5,
                        position: 'relative',
                        wordBreak: 'break-word',
                        ...(isLeft ? {} : {marginRight: '5px'}) // Ajouter une marge à droite pour les bulles du côté droit
                    }}
                >
                    <Typography variant="body2" color="textPrimary" className="text-justify">
                        {message}
                    </Typography>

                    {!isLeft && (
                        <CheckIcon
                            fontSize="small"
                            sx={{
                                bottom: 1,
                                right: 2,
                                position: 'absolute',
                                color: read ? '#66bb6a !important' : '#e0e0e0 !important',
                            }}
                        />
                    )}

                    {!isLeft && (
                        <Tooltip title="Supprimer" placement="top">
                            <IconButton
                                size="small"
                                className="delete-icon hidden"
                                sx={{
                                    left: -30,
                                    top: '50%',
                                    position: 'absolute !important',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'auto',
                                }}
                                onClick={onDelete}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{
                        display: 'block',
                        textAlign: isLeft ? 'left' : 'right',
                        mt: 0.5,
                        maxWidth: '100%', // La date reste à l'intérieur de la bulle
                        ...(isLeft ? {} : {marginRight: '5px'}) // Aligner la date avec la bulle à droite
                    }}
                >
                    {dayjs(date).format("HH:mm A")}
                </Typography>

            </Box>
        </Box>
    );
};

MyChatMsg.propTypes = {
    side: PropTypes.oneOf(['left', 'right']).isRequired,
    read: PropTypes.bool,
    message: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    date: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
};

MyChatMsg.defaultProps = {
    read: false,
    avatar: '',
    onDelete: () => {},
};

export default MyChatMsg;
