import React, {useEffect, useState} from 'react';
import {Avatar, Box} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import VerifiedIcon from '@mui/icons-material/Verified';
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import {addProfileServices} from "../../../reducer";
import {checkErrorMessage} from "../../../tools/Tools";
import SkeletonServiceCard from "../../skeletons/SkeletonServiceCard";
import ProfileInfosUpdate from "./ProfileInfosUpdate";

const useStyles = makeStyles(({palette}) => ({
    root: {
        cursor: "pointer",
        margin: "10px",
        position: "relative",
        overflow: "hidden",
        "&:before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))",
            backdropFilter: "blur(10px)",
            borderRadius: "0.8rem",
            zIndex: 0,
        },
    },
    content: {
        backdropFilter: "blur(10px)",
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))",
        borderRadius: "0.8rem",
        padding: "12px",
        margin: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
    },
    titleContainer: {
        marginLeft: "10px",
    },
    title: {
        color: "#222",
        fontSize: "1rem",
        marginBottom: "0px",
        textTransform: "capitalize",
    },
    type: {
        fontSize: "0.75rem",
        color: "#555",
    },
    events: {
        fontSize: "0.75rem",
        color: "#666",
    },
    genre: {
        fontSize: "0.75rem",
        color: "#888",
    },
    logo: {
        width: 40,
        height: 40,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "#fff",
        fontSize: "1.5rem",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "15% !important", // Ajustez selon le degré d'arrondi souhaité
    },
}));

function RightSideCertification() {

    const styles = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const auditor = useSelector(state => state.axios.auditor);
    const {profileData, services} = useSelector(state => state.profile);
    const artistData = {
        logo: services?.[0]?.galleries[0],
        brand: profileData?.artist_name,
        artist_type: '@' + services?.[0]?.artist_type,
        events: services?.[0]?.events,
        genre: services?.[0]?.thematics,
    };

    const fetchService = async () => {
        setLoading(true)
        await auditor.get(apis.getServices(1)).then(async (resp) => {
            await dispatch(addProfileServices(resp.data['user_services']))
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            toast.error(checkErrorMessage(error).message)
        })
    }

    useEffect(() => {
        if (!services?.length) {
            fetchService().then(() => null)
        } else {
            setLoading(false)
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [services?.length]);

    return (
        <Box
            sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderLeft: {md: "1px solid #ddd"},
            }}
        >

            <Box
                className={styles.root}
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >

                {loading
                    ? <SkeletonServiceCard index={'rightSideSkeletonService'}/>
                    : <Box className={styles.content}>
                        <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                            <Avatar
                                src={artistData?.logo}
                                className={styles.logo}
                                alt={artistData?.brand.charAt(0)}
                            />
                            <Box sx={{display: "flex", flexDirection: "column", gap: 0.5, textAlign: "left"}}>
                                <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                                    <h2 className={styles.title}>{artistData?.brand}</h2>
                                    <VerifiedIcon
                                        fontSize="small"
                                        sx={{color: '#1976d2 !important', marginBottom: 0.5}}/>
                                </Box>
                                <small className={styles.type}>{artistData?.artist_type}</small>
                            </Box>
                        </Box>
                        <Box sx={{mt: 1, textAlign: "left"}}>
                            <div className={styles.events}>
                                {artistData?.events.join(", ").substring(0, 28)}
                                {artistData?.events.join(", ").length > 28 && "..."}
                            </div>
                        </Box>
                        <Box sx={{mt: 1, textAlign: "left"}}>
                            <div className={styles.genre}>
                                {artistData?.genre.join(", ").substring(0, 28)}
                                {artistData?.genre.join(", ").length > 28 && "..."}
                            </div>
                        </Box>
                    </Box>}

                <ProfileInfosUpdate/>

            </Box>
        </Box>
    )
}

export default RightSideCertification;
