import {
    addCertificationObject,
    addProfileContactsArchived,
    addProfileData,
    addProfileDataContacts,
    addProfileDataContactsLength,
    addProfileNotificationLength,
    addProfileNotificationRead,
    addProfileNotificationUnread,
    addReservationMessagesList,
    switchLoadingDiscussions,
} from "../../reducer";

const initializeSocketEvents = (
    socket,
    dispatchNewNotificationToast,
    dispatch,
    profileDataRef,
    userId,
    certificationRef,
    reservationsMessageListRef
) => {

    socket.on('messages_list', (data) => {
        dispatch(addReservationMessagesList(data))
        reservationsMessageListRef.current = data
    });

    socket.on('deleted_message', (data) => {
        const {reservation_id, message_id} = data;

        let tmp = {...reservationsMessageListRef.current};

        if (tmp[reservation_id]) {
            tmp[reservation_id] = tmp[reservation_id]?.filter((msg) => msg.id !== message_id);

            dispatch(addReservationMessagesList(tmp));
            reservationsMessageListRef.current = {
                ...reservationsMessageListRef.current,
                [reservation_id]: tmp[reservation_id]
            };
        }
    })

    socket.on('ids_read_message', (data) => {
        const {reservation_id, message_ids} = data;

        let tmp = {...reservationsMessageListRef.current};

        if (tmp[reservation_id]) {
            tmp[reservation_id] = tmp[reservation_id].map((msg) =>
                message_ids.includes(msg.id) ? {...msg, read: true} : msg
            );

            dispatch(addReservationMessagesList(tmp));
            reservationsMessageListRef.current = {
                ...reservationsMessageListRef.current,
                [reservation_id]: tmp[reservation_id]
            };
        }
    });

    socket.on('new_message', (message) => {
        let tmp = {...reservationsMessageListRef.current}
        let reservation_id = message?.reservation_id

        if (!tmp[reservation_id]) {
            tmp[reservation_id] = []
        }

        tmp[reservation_id] = [...tmp[reservation_id], message]

        dispatch(addReservationMessagesList(tmp))
        reservationsMessageListRef.current = {
            ...reservationsMessageListRef.current,
            [reservation_id]: tmp[reservation_id]
        }
    });

    socket.on('new_notification', (notification) => {
        // Récupération et calcul cumulatif
        const {
            notificationUnRead = [],
            notificationRead = [],
            notification_length = 0
        } = profileDataRef.current;

        const filteredNotificationRead = notificationRead.filter(n => n.id !== notification.id);
        const filteredNotificationUnRead = notificationUnRead.filter(n => n.id !== notification.id);

        const updatedNotificationUnRead = [...filteredNotificationUnRead, notification];
        const updatedNotificationLength =
            filteredNotificationUnRead.length === notificationUnRead.length
                ? notification_length + 1
                : notification_length;

        // Dispatch Redux
        dispatch(addProfileNotificationRead(filteredNotificationRead));
        dispatch(addProfileNotificationUnread(updatedNotificationUnRead));
        dispatch(addProfileNotificationLength(updatedNotificationLength));

        // Mettre à jour profileDataRef
        profileDataRef.current = {
            ...profileDataRef.current,
            notificationRead: filteredNotificationRead,
            notificationUnRead: updatedNotificationUnRead,
            notification_length: updatedNotificationLength,
        };

        dispatchNewNotificationToast(notification, profileDataRef.current);
    });

    socket.on('new_contact_card', (contactCard) => {
        const {
            contacts = {auditor: {pending: [], accepted: []}, book: {pending: [], accepted: []}},
            contactsLength = {auditor: {pending: 0, accepted: 0}, book: {pending: 0, accepted: 0}}
        } = profileDataRef.current;

        const isAuditor = parseInt(userId) === parseInt(contactCard?.artist_id);
        const contactType = isAuditor ? 'auditor' : 'book';

        const updatedContacts = {
            ...contacts,
            [contactType]: {
                ...contacts[contactType],
                pending: [...contacts[contactType]?.pending, contactCard]
            }
        };

        const updatedContactsLength = {
            ...contactsLength,
            [contactType]: {
                ...contactsLength[contactType],
                pending: contactsLength[contactType]?.pending + 1
            }
        };

        dispatch(addProfileDataContacts(updatedContacts));
        dispatch(addProfileDataContactsLength(updatedContactsLength));

        profileDataRef.current = {
            ...profileDataRef.current,
            contacts: updatedContacts,
            contactsLength: updatedContactsLength,
        };
    });

    socket.on('confirmed_event', (contactCard) => {
        const {
            contacts = {auditor: {pending: [], accepted: []}, book: {pending: [], accepted: []}},
        } = profileDataRef.current;

        const contactFiltered = contacts['auditor']?.accepted.filter(n => n.id !== contactCard.id);

        const updatedContacts = {
            ...contacts,
            auditor: {
                ...contacts['auditor'],
                accepted: [...contactFiltered, contactCard]
            }
        };

        dispatch(addProfileDataContacts(updatedContacts));
        dispatch(switchLoadingDiscussions(true))

        profileDataRef.current = {...profileDataRef.current, contacts: updatedContacts,};
    });

    socket.on('new_proposition', (contactCard) => {
        const {
            contacts = {auditor: {pending: [], accepted: []}, book: {pending: [], accepted: []}},
        } = profileDataRef.current;

        const isArtist = parseInt(userId) === parseInt(contactCard?.artist_id);
        const contactType = isArtist ? 'auditor' : 'book';

        const contactFiltered = contacts[contactType]?.accepted.filter(n => n.id !== contactCard.id);

        const updatedContacts = {
            ...contacts,
            [contactType]: {
                ...contacts[contactType],
                accepted: [...contactFiltered, contactCard]
            }
        };

        dispatch(addProfileDataContacts(updatedContacts));
        dispatch(switchLoadingDiscussions(true))

        profileDataRef.current = {
            ...profileDataRef.current,
            contacts: updatedContacts
        };
    })

    socket.on('event_cancelled', (contactCard) => {
        const {
            contacts = {auditor: {pending: [], accepted: []}, book: {pending: [], accepted: []}},
            contacts_archived = [],
            contactsLength = {auditor: {pending: 0, accepted: 0}, book: {pending: 0, accepted: 0}}
        } = profileDataRef.current;

        const contactFiltered = contacts['auditor']?.accepted.filter(n => n.id !== contactCard.id);
        const updatedContacts = {
            ...contacts,
            auditor: {
                ...contacts['auditor'],
                accepted: contactFiltered
            }
        };

        const updatedContactsLength = {
            ...contactsLength,
            auditor: {
                ...contactsLength['auditor'],
                accepted: contactsLength['auditor']?.accepted - 1
            }
        };

        dispatch(addProfileDataContacts(updatedContacts));
        dispatch(addProfileDataContactsLength(updatedContactsLength));
        dispatch(addProfileContactsArchived([...contacts_archived, contactCard]));
        dispatch(switchLoadingDiscussions(true))

        profileDataRef.current = {
            ...profileDataRef.current,
            contacts: updatedContacts,
            contacts_archived: [...contacts_archived, contactCard],
            contactsLength: updatedContactsLength
        };
    });

    socket.on('event_accepted', (contactCard) => {
        const {
            contacts = {auditor: {pending: [], accepted: []}, book: {pending: [], accepted: []}},
        } = profileDataRef.current;

        const contactFiltered = contacts['book']?.accepted.filter(n => n.id !== contactCard.id);

        const updatedContacts = {
            ...contacts,
            book: {
                ...contacts['book'],
                accepted: [...contactFiltered, contactCard]
            }
        };

        dispatch(addProfileDataContacts(updatedContacts));
        dispatch(switchLoadingDiscussions(true))
        profileDataRef.current = {...profileDataRef.current, contacts: updatedContacts,};
    });

    socket.on('contact_card_updated', (contactCard) => {
        const {
            contacts = {auditor: {pending: [], accepted: []}, book: {pending: [], accepted: []}},
            contacts_archived = [],
            contactsLength = {auditor: {pending: 0, accepted: 0}, book: {pending: 0, accepted: 0}}
        } = profileDataRef.current;

        const isArtist = parseInt(userId) === parseInt(contactCard?.artist_id);
        const contactType = isArtist ? 'auditor' : 'book';

        const contactFiltered = contacts[contactType]?.pending.filter(n => n.id !== contactCard.id);

        const updatedContacts = {
            ...contacts,
            [contactType]: {
                ...contacts[contactType],
                pending: contactFiltered,
                accepted: contactCard?.status === 'declined'
                    ? contacts[contactType]?.accepted
                    : [...contacts[contactType]?.accepted, contactCard]
            }
        };

        const updatedContactsLength = {
            ...contactsLength,
            [contactType]: {
                ...contactsLength[contactType],
                pending: contactsLength[contactType]?.pending - 1,
                accepted: contactCard?.status === 'declined'
                    ? contactsLength[contactType]?.accepted
                    : contactsLength[contactType]?.accepted + 1
            }
        };

        dispatch(addProfileDataContacts(updatedContacts));
        dispatch(addProfileDataContactsLength(updatedContactsLength));

        const updatedArchived = contactCard?.status === 'declined'
            ? [...contacts_archived, contactCard]
            : contacts_archived;

        if (contactCard?.status === 'declined') {
            dispatch(addProfileContactsArchived(updatedArchived));
        }

        profileDataRef.current = {
            ...profileDataRef.current,
            contacts: updatedContacts,
            contacts_archived: updatedArchived,
            contactsLength: updatedContactsLength
        };

    });

    socket.on('certification_updated', (certification) => {
        if (certification?.status === 'accepted') {
            let {profileData} = {...profileDataRef.current};

            profileData['is_certified'] = true

            dispatch(addProfileData(profileData));

            profileDataRef.current = {
                ...profileDataRef.current,
                profileData: profileData
            };
        }

        dispatch(addCertificationObject(certification));
        certificationRef.current = {...certificationRef.current, ...certification};
    });

};

export default initializeSocketEvents;
