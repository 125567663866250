import DoneAllIcon from "@mui/icons-material/DoneAll";
import React, {useEffect, useRef, useState} from "react";
import {Column} from "@mui-treasury/components/flex";
import {Info, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useChatzInfoStyles} from "@mui-treasury/styles/info/chatz";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CardFooter, Col, Row, Spinner} from "reactstrap";
import {addReservationEventDate} from "../../reducer";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {btnStyle, containsPhoneOrEmail, dateVerify, isValidDate, useWindowSize} from "../../tools/Tools";
import ContactAmountInfos from "./ContactAmountInfos";
import ContactStepper from "./ContactStepper";
import {IconButton} from "@mui/material";
import {StaticDatePicker, TimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import AddPersonInfo from "./Steps/AddPersonInfo";

dayjs.extend(utc)
const backGround = 'https://res.cloudinary.com/kantobiz/image/upload/v1729961517/images/kantobiz/bc7tveva8jptegqxpndh.png'


function ContactForm({lastContactForm, paymentIntent, redirectStatus, paymentIntentSecret}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const width = useWindowSize();
    const isPaid = lastContactForm && redirectStatus === 'succeeded'
    const isMounted = useRef(false);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dateTimes, setDateTimes] = useState(false);
    const send = useSelector(state => state.toContact.send);
    const contactForm = useSelector(state => state.contactForm);
    const {eventDate} = contactForm
    const [isValid, setIsValid] = useState({
        name: false,
        event: false,
        city: false,
        address: false,
        description: false,
        lastname: false,
        postalCode: false,
        eventDate: false,
    });

    const checkEventDate = () => {
        setLoading(true);
        let event_date = new Date(date?.getFullYear(), date?.getMonth(), date?.getDate(), time?.getHours(), time?.getMinutes(), 0)
        if (!dateVerify(event_date)) {
            setLoading(false);
            toast.error("Artiste indisponible pour cette date.")
        } else if (!isValidDate(event_date)) {
            setLoading(false);
            toast.error("La date de l'événement n'est pas considérée.")
            toast(
                "Pour réserver, veuillez fournir la date et l'heure de l'événement.\n\n" +
                "Ainsi, vous saurez si l'artiste sera disponible pour votre événement.",
                {duration: 20000}
            );
        } else {
            dispatch(addReservationEventDate(event_date))
            setDateTimes(false);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!isMounted.current && !contactForm.eventDate) {
            if (!lastContactForm || !paymentIntent) {
                setDateTimes(true)
            }
        }

        return () => {
            isMounted.current = true
        };
    }, [contactForm.eventDate, paymentIntent, lastContactForm]);

    useEffect(() => {
        setIsValid((state) => {
            const { hasPhone, hasEmail } = containsPhoneOrEmail(contactForm.description || "");

            return {
                ...state,
                event: contactForm.event?.length > 0,
                name: contactForm.name?.length > 0,
                city: contactForm.city?.length > 0,
                address: contactForm.address?.length > 0,
                description: contactForm.description?.length > 0 && !hasPhone && !hasEmail,
                postalCode: contactForm.postalCode?.length > 0,
                lastname: contactForm.lastname?.length > 0,
            };
        });
    }, [setIsValid, contactForm]);

    return (
        <Row>

            <img
                hidden={width < 775}
                src={backGround}
                alt=""
                style={{
                    position: 'absolute',
                    top: '5%',
                    right: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 0.3,
                }}
            />

            <Modal show={dateTimes}>

                <CardHeaderCustom
                    cardTitle="Date de l'événement"
                    closeModal={() => !eventDate ? navigate(-1) : setDateTimes(false)}/>

                <LocalizationProvider dateAdapter={AdapterDateFns}>

                    <StaticDatePicker
                        openTo="day"
                        displayStaticWrapperAs="desktop"
                        minDate={new Date(new Date().getTime() + 86400000)}
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <div className="row justify-content-center mb-4 ml-2 mr-2">
                        <div className="col-md-8 text-black">
                            <TimePicker
                                ampm={false}
                                openTo="hours"
                                className="text-black"
                                views={['hours', 'minutes']}
                                inputFormat="HH:mm"
                                mask="__:__"
                                label="Heure de votre événement"
                                value={time}
                                onChange={(newValue) => {
                                    setTime(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    </div>

                </LocalizationProvider>

                <CardFooter>
                    <Row className="justify-content-end mx-1">
                        <Tooltip title="Enregistrer la date" placement="top">
                            <IconButton
                                size="small"
                                aria-label="settings"
                                sx={btnStyle('lightGreen')}
                                onClick={() => checkEventDate()}>
                                <small>Appliquer&nbsp;</small>
                                {loading
                                    ? <Spinner type="grow" size="sm"/>
                                    : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                            </IconButton>
                        </Tooltip>
                    </Row>
                </CardFooter>
            </Modal>

            <Col md="8">
                <Box className="width-full">
                    {(isPaid || send)
                        ? null
                        : <Column className="mb-2">
                            <Info useStyles={useChatzInfoStyles}>
                                <InfoTitle className={contactForm.eventDate ? '' : 'mb-5'}>
                                    Détails sur votre événement
                                </InfoTitle>
                                {contactForm.eventDate
                                    ? <InfoSubtitle>
                                        Le&nbsp;
                                        <strong>{dayjs(contactForm.eventDate).utc().format("DD-MM-YYYY")}</strong>&nbsp;à
                                        partir de&nbsp;
                                        <strong>{dayjs(contactForm.eventDate).format("HH:mm A")}</strong>
                                        <IconButton
                                            size="small"
                                            sx={{mb: 1.5, ml: 0.5}}
                                            aria-label="settings"
                                            onClick={() => setDateTimes(true)}>
                                            <EditCalendarIcon/>
                                        </IconButton>
                                    </InfoSubtitle>
                                    : null}
                            </Info>
                            <AddPersonInfo/>
                        </Column>}

                    <ContactStepper
                        isValid={isValid}
                        redirectStatus={redirectStatus}
                        paymentIntent={paymentIntent}
                        lastContactForm={lastContactForm}
                        paymentIntentSecret={paymentIntentSecret}/>

                </Box>
            </Col>

            <Col md="4">
                <ContactAmountInfos isPaid={isPaid}/>
            </Col>

        </Row>
    );
}

export default ContactForm;
