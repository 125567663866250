import {Box, Typography} from "@mui/material";
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ImpulseSpinner} from "react-spinners-kit";
import {CardBody, Spinner} from "reactstrap";
import {addPersonMessageList, switchPersonMsgProcess} from "../../../reducer";
import GroupedChatMessages from "../../../tools/GroupedChatMessages";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";


function ChatArtist({toScroll}) {

    const dispatch = useDispatch();
    const {userId} = useSelector(state => state.profile);
    const {process, messagesList, loadingAnswer} = useSelector(state => state.chatPerson);
    const {contactToChat, reservationsMessageList} = useSelector(state => state.global);

    const checkDiscussMessageList = useCallback(async () => {
        const reservationId = contactToChat.reservation_id;
        let tmpMessagesList = reservationsMessageList[reservationId]
        await dispatch(addPersonMessageList(tmpMessagesList));
        await dispatch(switchPersonMsgProcess(false));

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contactToChat.reservation_id, dispatch, reservationsMessageList]);

    useEffect(() => {
        checkDiscussMessageList().then(r => toScroll?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
        }))
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [reservationsMessageList, toScroll]);

    return process ? (
        <div className="row justify-content-center mt-5">
            <Spinner type="grow" size="sm"/>
        </div>
    ) : (
        <div>
            <CardBody className="mt-0">
                <div className="flex-grow-1 overflow-y-auto" style={{maxHeight: '100%', paddingBottom: '1rem'}}>
                    <p style={{whiteSpace: "pre-line"}} ref={toScroll}>
                        {messagesList?.length > 0 ? (
                            <GroupedChatMessages messages={messagesList} userId={userId}/>
                        ) : <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                color: "text.secondary",
                                textAlign: "center",
                                bgcolor: "background.default", // Couleur d'arrière-plan du thème
                                gap: 1.5, // Espace entre les éléments
                            }}
                        >
                            <Typography variant="subtitle1" component="p">
                                Aucune discussion
                            </Typography>
                            <ChatBubbleOutlineIcon sx={{fontSize: 30, opacity: 0.6, color: "#777777 !important"}}/>
                        </Box>}
                        <div style={{margin: 12, paddingTop: 10}} hidden={!loadingAnswer}>
                            <ImpulseSpinner size={25} frontColor="#f5f5f5"/>
                        </div>
                    </p>
                </div>
            </CardBody>
        </div>
    )
}

export default ChatArtist;
