import React from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {IconButton, TextField} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import {CardBody, CardFooter, Col, Form, FormGroup, Row, Spinner} from "reactstrap";
import {addProfileBanking} from "../../../reducer";
import CardHeaderCustom from "../../../tools/CardHeaderCustom";
import {btnStyle, changeFields, ITextField} from "../../../tools/Tools";


function AddBankAccount({loading, dispatchFunc, add, setAdd, setUp}) {

    const dispatch = useDispatch();
    const {banking} = useSelector(state => state.profile);
    const country_code = useSelector(state => state.global.countryCode);

    return (
        <div>
            <CardHeaderCustom
                cardTitle={add ? "Ajouter un compte" : "Modifications"}
                closeModal={() => add ? setAdd(false) : setUp(false)}/>
            <CardBody>
                <Form>
                    <div className="row justify-content-center mb-2">
                        Titulaire
                    </div>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                {ITextField(
                                    'Nom',
                                    null,
                                    banking['lastname'],
                                    (e) => changeFields(null, e, addProfileBanking, dispatch, "lastname", banking),
                                    null)}

                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>

                                {ITextField(
                                    'Prénom',
                                    null,
                                    banking['name'],
                                    (e) => changeFields(null, e, addProfileBanking, dispatch, "name", banking),
                                    null)}

                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="row justify-content-center mb-3">
                        Information bancaire
                    </div>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <FormGroup>
                                    <TextField
                                        size="small"
                                        value={banking['iban']}
                                        variant="filled"
                                        label="Iban"
                                        helperText="• Numéro d'acheminement pour les compte US"
                                        id="filled-start-adornment"
                                        placeholder={country_code + "....."}
                                        onChange={(e) => changeFields(null, e, addProfileBanking, dispatch, "iban", banking)}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <FormGroup>
                                    <TextField
                                        size="small"
                                        label="Swift"
                                        variant="filled"
                                        value={banking['swift']}
                                        helperText="• Numéro de compte pour les compte US"
                                        id="filled-start-adornment"
                                        placeholder="....."
                                        onChange={(e) => changeFields(null, e, addProfileBanking, dispatch, "swift", banking)}
                                    />
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-end mx-1">
                    <Tooltip title="Enregister" placement="top">
                        <IconButton
                            size="small"
                            disabled={loading}
                            aria-label="settings"
                            onClick={() => add ? dispatchFunc("add") : dispatchFunc("up")}
                            sx={btnStyle('lightGreen')}>
                            <small>Enregister</small>&nbsp;
                            {loading
                                ? <Spinner type="grow" size="sm"/>
                                : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}
                        </IconButton>
                    </Tooltip>
                </Row>


            </CardFooter>
        </div>
    )
}

export default AddBankAccount;
