import React, {useImperativeHandle, forwardRef} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {CardHeader, Row} from "reactstrap";
import {switchContactFormProcess} from "../../reducer";
import {checkErrorMessage} from "../../tools/Tools";
import Timer from "../../tools/Countdown/timer";

const OrangeMoney = forwardRef(
    ({orangeResponse, setOrange, sendContactCard}, ref) => {
        const dispatch = useDispatch();
        const isDev = useSelector((state) => state.global.isDev);
        const {service} = useSelector((state) => state.toContact);
        const auditor = useSelector((state) => state.axios.auditor);

        useImperativeHandle(ref, () => ({
            async checkValidationOrangeMoney() {
                await dispatch(switchContactFormProcess(true));

                const data = {
                    amount: isDev ? 1 : service.contact_amount,
                    order_id: orangeResponse["order_id"],
                    pay_token: orangeResponse["pay_token"],
                };

                auditor
                    .post("payments/orange_money_status", data)
                    .then(async (resp) => {
                        const status = resp.data.status;

                        if (["INITIATED", "PENDING"].includes(status)) {
                            await dispatch(switchContactFormProcess(false));
                            toast("Le paiement est en cours, je vous prie d'attendre jusqu’à la fin", {duration: 4000});
                        } else if (["EXPIRED", "FAILED"].includes(status)) {
                            await dispatch(switchContactFormProcess(false));
                            toast.error("Le paiement a échoué, veuillez recommencer.");
                            await setOrange(false);
                        } else {
                            await setOrange(false);
                            sendContactCard(orangeResponse["payment_url"], orangeResponse["order_id"]);
                        }
                    })
                    .catch((error) => {
                        dispatch(switchContactFormProcess(false));
                        toast.error(checkErrorMessage(error).message);
                    });
            },
        }));

        return (
            <>
                <CardHeader>
                    <Row className="justify-content-center">
                        <span className="mr-4">
                          Expire dans
                        </span>
                        <Timer timerData={{days: 0, hours: 0, minutes: 10, seconds: 0}}/>
                    </Row>
                </CardHeader>
                <iframe
                    height={525}
                    title="orange money payment"
                    className="width-full"
                    src={orangeResponse.payment_url}
                />
            </>
        );
    }
);

export default OrangeMoney;
