import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';

const CalendarLegend = () => {
    const theme = useTheme();

    return (
        <Box sx={{mt: 2, textAlign: {xs: 'left', sm: 'center'}}}>
            <Typography
                variant="caption"
                sx={{
                    display: 'flex',
                    color: 'text.secondary',
                    flexDirection: {xs: 'column', sm: 'row'}, // Column on xs, row on sm and up
                    alignItems: {xs: 'flex-start', sm: 'center'}, // Align left on xs, center on sm and up
                    justifyContent: {xs: 'flex-start', sm: 'center'}
                }}
            >
                <Box display="flex" alignItems="center" sx={{mb: {xs: 1, sm: 0}, mr: {sm: 2}}}>
                    <Box
                        sx={{
                            width: 8,
                            height: 8,
                            bgcolor: theme.palette.info.light,
                            borderRadius: '50%',
                            mr: 1
                        }}
                    />
                    Événement(s) en attente
                </Box>
                <Box display="flex" alignItems="center">
                    <Box
                        sx={{
                            width: 8,
                            height: 8,
                            bgcolor: theme.palette.success.light,
                            borderRadius: '50%',
                            mr: 1
                        }}
                    />
                    Événement(s) en cours de discussion (ou réalisation)
                </Box>
            </Typography>
        </Box>
    );
};

export default CalendarLegend;
