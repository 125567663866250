import io from 'socket.io-client';
import Conf from "../../config/tsconfig.json";

let socket = null;

export const initializeSocketConnection = (userId, onConnect, onDisconnect) => {
    const socketApi = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? Conf.configs.socketEndPointDev
        : Conf.configs.socketEndPoint;

    // Si aucune connexion existante ou socket déconnectée
    socket = io(socketApi, {
        query: { userId },
        pingTimeout: 30000, // Timeout plus bas
        pingInterval: 10000, // Intervalle régulier
        reconnection: true,
        reconnectionAttempts: 5, // Limiter les tentatives pour éviter les boucles infinies
        reconnectionDelay: 2000, // Délai entre chaque tentative
    });

    socket.on('connect', () => {
        if (onConnect) onConnect();
    });

    socket.on('disconnect', (reason) => {
        console.warn('Socket déconnectée. Raison :', reason);
        if (onDisconnect) onDisconnect(reason);
    });

    return socket;
};

export const getSocket = () => socket;
