
import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {CardBody, Spinner} from "reactstrap";
import apis from "../../config/apis";
import CardHeaderCustom from "../../tools/CardHeaderCustom";

function Contract({closeContract}) {

    const isMounted = useRef(false);
    const [loading, setLoading] = useState(true);
    const [urlContract, setUrlContract] = useState('');
    const {contactToChat} = useSelector(state => state.global);
    const auditor = useSelector(state => state.axios.auditor);

    const generateContract = async () => {
        auditor.get(apis.generateContract(contactToChat['reservation_id'])).then(resp => {
            setUrlContract(resp.data)
            setLoading(false);
        }).catch(() => {})
    }

    useEffect(() => {

        !isMounted.current && generateContract().then(r => null)

        return () => {
            isMounted.current = true
        };

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <div>
            <CardHeaderCustom
                cardTitle="Mon Contrat"
                closeModal={() => closeContract(false)}/>

            <CardBody>
                {loading
                    ? <div className="row justify-content-center my-5">
                        <Spinner type="grow" size="sm"/>
                    </div>
                    : <iframe
                        height={700}
                        className="width-full"
                        title="Mon Contrat"
                        src={urlContract}/>}
            </CardBody>
        </div>
    )
}

export default Contract;
