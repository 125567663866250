import React, {useState} from "react";
import Avatar from '@mui/material/Avatar';
import Badge from "@mui/material/Badge";
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import CloseIcon from "@mui/icons-material/Close";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import LoupeIcon from '@mui/icons-material/Loupe';
import DescriptionIcon from '@mui/icons-material/Description';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AddCardIcon from '@mui/icons-material/AddCard';
import {useDispatch, useSelector} from "react-redux";
import DrawIcon from '@mui/icons-material/Draw';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {addBotMessageList, addPersonMessageList, switchBotMsgProcess, switchPersonMsgProcess} from "../../../reducer";
import {clearContactToChat} from "../../../tools/Tools";

function ChatNavigation({
                            isBot,
                            closeDiscuss,
                            setSeeContract,
                            bookType,
                            setPropose,
                            setAllowEvent,
                            setWarnDecline,
                            handleChangePersonChat,
                        }) {

    const dispatch = useDispatch();
    const {messagesList} = useSelector(state => state.chatBot);
    const [value, setValue] = useState('recents');
    const userId = useSelector(state => state.profile.userId);
    const {contactToChat} = useSelector(state => state.global);
    const updateInfo = contactToChat?.reservation?.last_user_updated;
    let isArtist = parseInt(contactToChat?.reservation?.artist_owner_id) === parseInt(userId)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const closeChat = async () => {
        await clearContactToChat(dispatch)
        dispatch(switchBotMsgProcess(true))
        dispatch(switchPersonMsgProcess(true))
        dispatch(addBotMessageList([messagesList[0]]))
        dispatch(addPersonMessageList([]))
    }

    const removeMessagesListOfPerson = () => {
        if (!isBot) {
            dispatch(addPersonMessageList([]))
        }
    }

    const chatChatBubble = () => {
        removeMessagesListOfPerson()
        handleChangePersonChat()
    }

    return (<div>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
            <BottomNavigationAction
                label={<small>{isBot ? contactToChat?.reservation?.artist_name : "ChatBot"}</small>}
                onClick={() => chatChatBubble()}
                icon={
                    <Badge
                        color="transparent"
                        badgeContent={<AutorenewIcon fontSize='small' sx={{color: '#00e676 !important'}}/>}>
                        {isBot
                            ? contactToChat?.reservation?.artist_photo
                                ? <Avatar
                                    variant="rounded"
                                    sx={{width: 18, height: 18}}
                                    src={contactToChat?.reservation?.artist_photo}/>
                                : <Avatar sx={{width: 18, height: 18, fontSize: 10}} variant="rounded">
                                    {contactToChat?.reservation?.artist_name?.substring(0, 1).toUpperCase()}
                                </Avatar>
                            : <SmartToyIcon fontSize="small" sx={{color: "#607d8b !important"}}/>}
                    </Badge>
                }
            />

            {bookType === 'auditor' && (updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) !== parseInt(userId)) ?
                <BottomNavigationAction
                    label="Signer"
                    value="accept"
                    disabled={closeDiscuss}
                    sx={{color: 'lightGreen!important'}}
                    onClick={() => {
                        removeMessagesListOfPerson()
                        setAllowEvent(true)
                    }}
                    icon={<DrawIcon fontSize="small" sx={{color: 'lightGreen!important'}}/>}
                /> : updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) === parseInt(userId)
                    ? null : updateInfo?.object === 'accepted'
                        ? null : updateInfo?.object === 'paid'
                            ? null : bookType === 'auditor' &&
                            <BottomNavigationAction
                                label="Signer"
                                value="accept"
                                disabled={closeDiscuss}
                                sx={{color: 'lightGreen!important'}}
                                onClick={() => {
                                    removeMessagesListOfPerson()
                                    setAllowEvent(true)
                                }}
                                icon={<DrawIcon fontSize="small" sx={{color: 'lightGreen!important'}}/>}
                            />}

            {bookType === 'book' && (updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) !== parseInt(userId)) ?
                <BottomNavigationAction
                    label="Signer"
                    value="accept"
                    disabled={closeDiscuss}
                    sx={{color: 'lightGreen!important'}}
                    onClick={() => {
                        removeMessagesListOfPerson()
                        setAllowEvent(true)
                    }}
                    icon={<DrawIcon fontSize="small" sx={{color: 'lightGreen!important'}}/>}
                /> : bookType === 'book' && updateInfo?.object !== 'proposition'
                    ? null : updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) === parseInt(userId)
                        ? null : bookType === 'book' &&
                        <BottomNavigationAction
                            label="Signer"
                            value="accept"
                            disabled={closeDiscuss}
                            sx={{color: 'lightGreen!important'}}
                            onClick={() => {
                                removeMessagesListOfPerson()
                                setAllowEvent(true)
                            }}
                            icon={<DrawIcon fontSize="small" sx={{color: 'lightGreen!important'}}/>}
                        />}

            {['accepted', 'paid', 'finish'].includes(contactToChat?.reservation?.status)
                ? <BottomNavigationAction
                    label="Contrat"
                    value="contract"
                    disabled={closeDiscuss}
                    sx={{color: '#40c4ff!important'}}
                    onClick={() => {
                        removeMessagesListOfPerson()
                        setSeeContract(true)
                    }}
                    icon={<DescriptionIcon fontSize="small" sx={{color: '#40c4ff!important'}}/>}
                /> : null}

            {contactToChat?.reservation?.invoice && !isArtist &&
                <BottomNavigationAction
                    label="Facture"
                    value="Invoice"
                    onClick={() => document.getElementById("download-invoice").click()}
                    icon={<VerticalAlignBottomOutlinedIcon fontSize="small"/>}
                />}


            {bookType === 'book'
                ? updateInfo?.object === 'proposition' && parseInt(updateInfo?.user_id) !== parseInt(userId)
                    ? <BottomNavigationAction
                        label='Proposition'
                        value="proposition"
                        disabled={closeDiscuss}
                        onClick={() => {
                            removeMessagesListOfPerson()
                            setPropose(contactToChat?.reservation?.id)
                        }}
                        icon={<LoupeIcon fontSize="small" sx={{color: '#ce93d8!important'}}/>}
                    /> : updateInfo?.object === 'accepted'
                        ? <BottomNavigationAction
                            label='Payment'
                            value="paiment"
                            disabled={closeDiscuss || contactToChat?.reservation?.status === 'declined'}
                            onClick={() => {
                                removeMessagesListOfPerson()
                                setAllowEvent(true)
                            }}
                            icon={<AddCardIcon fontSize="small"/>}
                        /> : <BottomNavigationAction
                            label='Annuler'
                            value="decline"
                            disabled={closeDiscuss || contactToChat?.reservation?.status === 'declined'}
                            onClick={() => {
                                removeMessagesListOfPerson()
                                setWarnDecline(contactToChat?.reservation?.id)
                            }}
                            icon={<EventBusyIcon fontSize="small" sx={{color: '#d1c4e9 !important'}}/>}
                        /> : updateInfo?.object === 'accepted'
                    ? null : updateInfo?.object === 'paid'
                        ? null : <BottomNavigationAction
                            label='Proposition'
                            value="proposition"
                            disabled={closeDiscuss}
                            onClick={() => {
                                removeMessagesListOfPerson()
                                setPropose(contactToChat?.reservation?.id)
                            }}
                            icon={<LoupeIcon fontSize="small" sx={{color: '#ce93d8!important'}}/>}
                        />}

            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href={contactToChat?.reservation?.invoice}
               rel="noreferrer"
               target="_blank"
               download
               id="download-invoice"
               hidden/>
            <BottomNavigationAction
                label="Fermer"
                value="close"
                icon={<CloseIcon fontSize="small"/>}
                onClick={() => closeChat()}
            />
        </BottomNavigation>

    </div>);
}

export default ChatNavigation;
