import axios from "axios";
import apis from "../config/apis";
import {addProfileContactsToFinish} from "../reducer";
import DecryptData from "./DecryptData";
import {checkErrorMessage} from "./Tools";
axios.interceptors.response.use(
    response => {
        return {data: DecryptData(response.data)}
    },
    error => {
        return Promise.reject(error);
    }
);

const CheckEventsToFinish = async (contact_type, headers, dispatch) => {

    try {
        const resp = await axios.get(apis.getEventsToFinish(contact_type), {headers: headers});
        dispatch(addProfileContactsToFinish(resp.data));
    } catch (error) {
        console.log('error: ', checkErrorMessage(error).message);
    }

};

export default CheckEventsToFinish;
