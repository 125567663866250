import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: "0.8rem",
        "&:before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))",
            zIndex: 0,
        },
    },
    cover: {
        width: "100%",
        height: 120,
        borderRadius: "0.8rem",
    },
    content: {
        position: "relative",
        zIndex: 1,
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))",
        padding: "12px",
        borderRadius: "0.8rem",
    },
    avatar: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "#fff",
        width: 40,
        height: 40,
        fontSize: "1.5rem",
        borderRadius: "0.8rem",
    },
    textPrimary: {
        fontSize: "1rem",
        fontWeight: 500,
        marginBottom: "4px",
    },
    textSecondary: {
        fontSize: "0.75rem",
        color: "#888",
    },
}));

const SkeletonPage = () => {
    const styles = useStyles();

    return (
        <div className="content pt-4">
            <Box>
                {/* Skeleton sliders pour les catégories */}
                {["ARTIST / MUSICIEN", "DJ", "AUTRES"].map((category, idx) => (
                    <Box key={idx} sx={{mb: 2}}>
                        {/* Titre de la catégorie */}
                        <Skeleton variant="text" width="20%" animation="wave" sx={{mb: 3}}/>

                        {/* Flicking Slider */}
                        <Flicking
                            align="prev"
                            circular={false}
                            bound={true}
                            autoResize={true}
                            horizontal={true}
                        >
                            {Array.from({length: 8}).map((_, index) => (
                                <Box
                                    key={index}
                                    className="flicking-panel"
                                    sx={{
                                        pr: 1,
                                        width: 180,
                                        height: 200,
                                        flexShrink: 0,
                                    }}
                                >
                                    <Box className={styles.root}>
                                        <Box className={styles.content}>
                                            <Avatar className={styles.avatar}>
                                                <Skeleton variant="circular" width={40} height={40}/>
                                            </Avatar>
                                            <Box sx={{mt: 1}}>
                                                <Skeleton
                                                    variant="text"
                                                    width="70%"
                                                    className={styles.textPrimary}
                                                    animation="wave"
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    width="50%"
                                                    className={styles.textSecondary}
                                                    animation="wave"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Flicking>
                    </Box>
                ))}
            </Box>
        </div>
    );
};

export default SkeletonPage;
