import Button from "@material-ui/core/Button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {IconButton, InputAdornment, TextField} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import apis from "../../config/apis";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useDispatch, useSelector} from "react-redux";
import {CardBody, CardFooter, Col, FormGroup, Row, Spinner} from "reactstrap";
import "../../assets/css/keyValidation.css";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import PassWordHints from "../../tools/PassWordHints";
import {changeFields, checkErrorMessage, createSession, ITextField} from "../../tools/Tools";
import ChangePassword from "./ChangePassword";
import LoginGoogle from "./Google";
import Validation from "./Validation";

function Login(props) {

    const {closeModal} = props
    const dispatch = useDispatch()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [passVisibility, setPassVisibility] = useState(false);
    const auditor = useSelector(state => state.axios.auditor);
    const [verificationCode, setVerificationCode] = useState(false);
    const ipAddress = useSelector(state => state.global.ipAddress);

    const login = useCallback(() => {
        setLoading(true)
        auditor.post(apis.login, {email, password}).then(async response => {
            await createSession(response.data, dispatch, ipAddress, setLoading, closeModal, true)
        }).catch(error => {
            setLoading(false);
            let response = checkErrorMessage(error).message;
            if (response === "Veuillez activer votre compte.") {
                setVerificationCode(true)
            }
            toast.error(response);
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [closeModal, ipAddress, dispatch, auditor, email, password])

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;

        if ((forgotPass || verificationCode) && keyCode === 13 && key === 'Enter') {
            event.preventDefault()
        } else if (keyCode === 27 && key === 'Escape' && !forgotPass && !verificationCode) {
            closeModal()
        } else if (keyCode === 13 && key === 'Enter' && !forgotPass && !verificationCode) {
            login()
        }
    }, [closeModal, login, verificationCode, forgotPass]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="justify-content-center">
            <Modal size="md" show={forgotPass}>
                <ChangePassword closeModal={() => setForgotPass(false)}/>
            </Modal>

            <CardHeaderCustom
                cardTitle="Se connecter"
                disabled={loading || verificationCode}
                closeModal={() => closeModal()}
            />

            <CardBody>
                {!verificationCode ?
                    <div>
                        <Row className="justify-content-center">
                            <Col md="10">
                                <FormGroup>
                                    {ITextField('Email', null, email, (e) => changeFields(setEmail, e))}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={10}>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        value={password}
                                        variant="filled"
                                        placeholder='Mot de passe'
                                        onChange={(e) => changeFields(setPassword, e)}
                                        type={passVisibility ? "text" : "password"}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                borderRadius: '10px',
                                                backgroundColor: '#f1f3f4'
                                            },
                                            '& .MuiFilledInput-root:before, & .MuiFilledInput-root:after': {
                                                display: 'none', // Masquer les lignes avant et après le champ
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip title={PassWordHints()} placement="top">
                                                        <IconButton size="small">
                                                            <InfoIcon fontSize="small" sx={{color: "gray!important"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={passVisibility ? "Masquer le mot de passe" : "Afficher le mot de passe"}
                                                        placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => setPassVisibility(!passVisibility)}>
                                                            {passVisibility
                                                                ? <VisibilityIcon fontSize="small"/>
                                                                : <VisibilityOffIcon fontSize="small" sx={{color: "gray!important"}}/>}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <div className="material-switch text-center mt-1">
                                <small
                                    onClick={() => setForgotPass(true)}
                                    className="text-monospace border-bottom cursor-pointer text-muted">
                                    Mot de passe oublié ?
                                </small>
                            </div>
                        </Row>
                    </div> :
                    <Validation email={email} closeModal={() => setVerificationCode(false)} forgotValidate/>}
            </CardBody>

            <CardFooter>
                <Row className="justify-content-end mx-1">
                    <Tooltip title="Se connecter avec Google" placement="top">
                        <LoginGoogle
                            LogIn
                            disable={verificationCode}
                            callBack={(data) => createSession(data, dispatch, ipAddress, setLoading, closeModal, true)}
                            _setLoad={setLoading}/>
                    </Tooltip>
                    <Tooltip title="Se connecter" placement="top">
                        <Button
                            size="small"
                            className="ml-2"
                            disabled={loading || verificationCode}
                            onClick={() => login()}
                            endIcon={
                                loading
                                    ? <Spinner type="grow" size="sm"/>
                                    : <DoneAllIcon sx={{color: "lightGreen!important"}}/>}>
                            Connexion
                        </Button>
                    </Tooltip>
                </Row>
            </CardFooter>
        </div>
    );
}

export default Login;
