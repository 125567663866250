import CardHeader from "@material-ui/core/CardHeader";
import {Column} from "@mui-treasury/components/flex";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useChatzInfoStyles} from "@mui-treasury/styles/info/chatz";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import 'moment/locale/fr';
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {
    addProfileContactsArchived,
    addProfileContactsToFinish,
    addWallet,
    addWalletTransfers,
} from "../../reducer";
import Timer from "../../tools/Countdown/timer";
import {checkTimer} from "../../tools/Tools";
import ContactCard from "../contactCards/ContactCard";
import EventListSkeleton from "../skeletons/EventListSkeleton";

moment.locale('fr');

function EventList({openToChat}) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const {contacts_to_finish, contacts, contacts_archived} = useSelector(state => state.profile);
    const {transfers, wallets, selected} = useSelector(state => state.wallet);
    const wallet = wallets[selected];
    const [showBook, setShowBook] = useState({});
    const [toShow, setToShow] = useState(false);
    const [time, setTime] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
    const [upcomingEvent, setUpcomingEvent] = useState(null);
    const [street, pcCity, country] = upcomingEvent?.reservation?.address?.split(', ') || [];
    const [comingEvents, setComingEvents] = useState([]);

    // Function to find the closest event based on the current date
    const findClosestEvent = () => {
        const events = [
            ...(contacts.book?.accepted || []), // Vérifie que 'contacts.book.accepted' existe, sinon utilise un tableau vide
            ...(contacts.book?.pending || []), // Vérifie que 'contacts.book.pending' existe
            ...(contacts.auditor?.accepted || []), // Vérifie que 'contacts.auditor.accepted' existe
            ...(contacts.auditor?.pending || []), // Vérifie que 'contacts.auditor.pending' existe
        ];

        const now = moment();

        // Filtrer et trouver l'événement le plus proche basé sur la date actuelle
        const upcoming = events
            ?.filter(event => ['accepted', 'paid']?.includes(event.reservation.status) && moment(event.reservation.event_date).isAfter(now))
            .sort((a, b) => moment(a.reservation.event_date) - moment(b.reservation.event_date))[0];

        if (upcoming) {
            setUpcomingEvent(upcoming);
            checkTimer(upcoming, setTime);
        } else {
            setComingEvents([]);
            setUpcomingEvent(null);
        }

        setComingEvents(events
            ?.filter((event) => event.id !== upcoming?.id)
            .sort((a, b) => moment(a.reservation.event_date) - moment(b.reservation.event_date))
            .slice(0, 10));

        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const toFinished = async (transaction, contact_card) => {
        const newList = contacts_to_finish.filter(contact => contact.id !== contact_card.id);
        dispatch(addProfileContactsToFinish(newList));
        dispatch(addProfileContactsArchived([...contacts_archived, contact_card]));

        const updatedWallets = [...wallets];
        updatedWallets[selected] = {...wallet, balance: wallet.balance + transaction.amount};

        dispatch(addWallet(updatedWallets));
        dispatch(addWalletTransfers([...transfers, transaction]));
        setToShow(false);
        toast("Vous avez terminé une prestation. La prise de contact sera automatiquement déplacée dans vos archives. Veuillez consulter votre compte pour vérifier votre dû.");
    };

    const addReservationToShow = async (book) => {
        setShowBook(book);
        setToShow(true);
    };

    useEffect(() => {
        findClosestEvent();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [contacts]);

    return loading ? (
            <EventListSkeleton/>
        ) : (
        <div className="row justify-content-center">

            <Modal size="lg" show={toShow}>
                <ContactCard setToShow={setToShow} ContactCardToChat={showBook} toFinished={toFinished}/>
            </Modal>

            <div className="col-md-12">
                <Column gap={1} className="text-left">
                    <Info useStyles={useChatzInfoStyles}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}>
                            <InfoSubtitle className="mb-2 font-weight-bold">
                                {upcomingEvent ? "Prochain événement dans" : "Pas de prestation prévue pour le moment"}
                            </InfoSubtitle>

                            <InfoTitle style={{flexShrink: 0}}>
                                {upcomingEvent ? <Timer timerData={time} left/> : ""}
                            </InfoTitle>
                        </div>

                        {upcomingEvent
                            ? <Tooltip title="Cliquer pour afficher la discussion" placement="top">
                                <IconButton
                                    key={"first_event_btn"}
                                    className="width-full border-bottom"
                                    sx={{backgroundColor: 'background.paper', padding: '4px'}}
                                    onClick={() => openToChat(upcomingEvent)}>

                                    <CardHeader
                                        key={'first_event'}
                                        style={{width: '100%', padding: '8px'}}
                                        title={
                                            <Column gap={0.5} className="text-left">
                                                <Info useStyles={useChatzInfoStyles}>
                                                    <InfoTitle>{upcomingEvent?.reservation?.event}</InfoTitle>
                                                    <InfoSubtitle>
                                                        {street}
                                                    </InfoSubtitle>
                                                    <InfoSubtitle>
                                                        {pcCity + ', ' + country}
                                                    </InfoSubtitle>
                                                    <InfoCaption>
                                                        <strong>
                                                            Le&nbsp;
                                                            {moment(upcomingEvent?.reservation?.event_date)?.format("DD-MM-YYYY à HH:mm A")}
                                                        </strong>
                                                    </InfoCaption>
                                                </Info>
                                            </Column>
                                        }/>
                                </IconButton>
                            </Tooltip> : null}

                        {contacts_to_finish?.length
                            ? <div className="mt-2">
                                <InfoSubtitle className="mb-2 font-weight-bold">
                                    {"Événements Terminé"}
                                </InfoSubtitle>

                                {contacts_to_finish?.map((item, index) => (
                                    <Tooltip title="Cliquer pour afficher la discussion" placement="top"
                                             key={'w' + index}>
                                        <IconButton
                                            className="width-full border-bottom"
                                            key={"first_event_btn"}
                                            sx={{backgroundColor: 'background.paper', padding: '4px'}}
                                            onClick={() => addReservationToShow(item)}
                                            aria-label="settings">

                                            <CardHeader
                                                key={'first_event'}
                                                style={{width: '100%', padding: '8px'}}
                                                title={
                                                    <Column gap={1} className="text-left">
                                                        <Info useStyles={useChatzInfoStyles}>
                                                            <InfoTitle>{item.reservation?.event}</InfoTitle>
                                                            <InfoSubtitle>
                                                                {item.reservation?.address}
                                                            </InfoSubtitle>
                                                            <InfoCaption>
                                                                <strong>
                                                                    Le&nbsp;
                                                                    {moment(item.reservation?.event_date)?.format("DD-MM-YYYY à HH:mm A")}
                                                                </strong>
                                                            </InfoCaption>
                                                        </Info>
                                                    </Column>
                                                }/>
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </div>
                            : comingEvents?.length ? <hr className="ml-1"/> : null}

                        {comingEvents?.length
                            ? <div className="mt-2">
                                <InfoSubtitle className="mb-3 font-weight-bold">
                                    Autres Evenements
                                </InfoSubtitle>

                                <div style={{maxHeight: '388px', overflowY: 'auto'}}>
                                    {comingEvents.map((contact, i) => (
                                        <Tooltip title="Cliquer pour afficher la discussion" placement="top"
                                                 key={'w' + i}>
                                            <IconButton
                                                key={"first_event_btn"}
                                                className="width-full border-bottom"
                                                sx={{
                                                    backgroundColor:
                                                        contact.status === 'accepted'
                                                            ? 'secondary.light'
                                                            : 'primary.light',
                                                    padding: '4px'}}
                                                onClick={() => addReservationToShow(contact)}
                                            >
                                                <CardHeader
                                                    key={'first_event'}
                                                    style={{width: '100%', padding: '8px'}}
                                                    title={
                                                        <Column gap={1} className="text-left">
                                                            <Info useStyles={useChatzInfoStyles}>
                                                                <InfoTitle style={{color: 'white'}}>
                                                                    {contact.reservation?.event}
                                                                </InfoTitle>
                                                                <InfoCaption className="mt-2">
                                                                    <strong>
                                                                        Le&nbsp;
                                                                        {moment(contact.reservation?.event_date)?.format("DD-MM-YYYY à HH:mm A")}
                                                                    </strong>
                                                                </InfoCaption>
                                                            </Info>
                                                        </Column>
                                                    }/>
                                            </IconButton>
                                        </Tooltip>
                                    ))}
                                </div>
                            </div>
                            : null}
                    </Info>
                </Column>
            </div>
        </div>
    );
}

export default EventList;
