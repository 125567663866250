import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";
import React from "react"
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import Badge from "@mui/material/Badge";
import moment from "moment/moment";
import {useSelector} from "react-redux";
import AddTaskIcon from '@mui/icons-material/AddTask';
import CardHeader from "@material-ui/core/CardHeader";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useThemeXsInfoStyles} from "@mui-treasury/styles/info/themeXs";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

dayjs.extend(utc)


function ContactCardHeader({userId, book, setShowBook}) {

    let now = moment()
    const bookedList = parseInt(userId) !== parseInt(book?.artist_id)
    const tabIndex = useSelector(state => state.contactCards.tabIndex);
    const isPaidAllow = !bookedList
        && (book?.reservation?.status === 'paid'
            && book?.reservation?.payment
            && now.isAfter(new Date(book?.reservation?.event_date))
        )

    return (
        <CardHeader
            style={{width: "100%", marginBottom: 2}}
            onClick={() => setShowBook(book)}
            action={<small className="text-muted">{dayjs(book.created_at).utc().format("DD-MM-YYYY")}</small>}
            avatar={
                <div className="text-lg-center">
                    <div className="s-24 m-auto">
                        {isPaidAllow
                            ? <AddTaskIcon fontSize="small" sx={{color: "lightGreen!important", padding: 0.4}}/>
                            : tabIndex === 1
                                ? <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{
                                        mb: 0.5,
                                        color:
                                            ['paid', 'finish', 'accepted'].includes(book.reservation.status)
                                                ? 'lightGreen!important'
                                                : book.reservation.status === 'declined' || book.aborted
                                                    ? 'danger!important'
                                                    : 'silver!important'
                                    }}/>
                                : null}
                        {dayjs(book.reservation.event_date).format("DD")}
                    </div>
                    <span>
                            {dayjs(book.reservation.event_date).format("MM")},&nbsp;
                        <small>{dayjs(book.reservation.event_date).format("YYYY")}</small>
                        </span>
                </div>
            }
            title={
                <Info useStyles={useThemeXsInfoStyles}>
                    <InfoCaption>
                        {userId === book.user_id
                            ? <strong>
                                {book.reservation.artist_name}
                                {book?.is_certified
                                    ? <Tooltip style={{cursor: 'pointer'}} title="Profil verifié" placement='top'>
                                        <VerifiedIcon
                                            sx={{
                                                marginLeft: 0.5,
                                                marginBottom: 0.5,
                                                color: '#1976d2 !important',
                                                fontSize: '12px', // Taille précise
                                            }}/>
                                    </Tooltip>
                                    : null}
                        </strong>
                            : <strong>{book?.lastname}&nbsp;{book?.name}</strong>}
                    </InfoCaption>
                    <Badge variant="dot" color="secondary" invisible={!isPaidAllow}>
                        <InfoTitle>
                            {book.reservation.event}
                        </InfoTitle>
                    </Badge>
                    <InfoSubtitle className="d-none d-lg-block">
                        {isPaidAllow
                            ? <strong>Fonds disponibles pour encaissement</strong>
                            : book?.reservation?.status === 'finish' && !bookedList
                                ? <strong>Encaissement effectué</strong>
                                : ""}
                    </InfoSubtitle>
                </Info>
            }
        />
    )
}

export default ContactCardHeader;
