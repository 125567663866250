const initState = {
    name: '',
    event: '',
    city: '',
    price: 0,
    address: '',
    description: '',
    lastname: '',
    postalCode: '',
    travelExpenses: 0,
    amountProposed: 0,
    eventDate: '',
};

const ContactForm = (state = initState, action) => {
    switch (action.type) {
        case "ADD_CONTACT_FORM_PROPOSED_AMOUNT":
            return {
                ...state,
                amountProposed: action.data
            };
        case "ADD_RESERVATION_PRICE":
            return {
                ...state,
                price: action.data
            };
        case "ADD_RESERVATION_DESC":
            return {
                ...state,
                description: action.data
            };
        case "ADD_RESERVATION_TRAVEL":
            return {
                ...state,
                travelExpenses: action.data
            };
        case "ADD_RESERVATION_NAME":
            return {
                ...state,
                name: action.data
            };
        case "ADD_RESERVATION_EVENT":
            return {
                ...state,
                event: action.data
            };
        case "ADD_RESERVATION_CITY":
            return {
                ...state,
                city: action.data
            };
        case "ADD_RESERVATION_ADDRESS":
            return {
                ...state,
                address: action.data
            };
        case "ADD_RESERVATION_LASTNAME":
            return {
                ...state,
                lastname: action.data
            };
        case "ADD_RESERVATION_POSTAL_CODE":
            return {
                ...state,
                postalCode: action.data
            };
        case "ADD_RESERVATION_EVENT_DATE":
            return {
                ...state,
                eventDate: action.data
            };
        default:
            return state;
    }
};

export default ContactForm;
