import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from '@mui/icons-material/Forum';
import apis from "../../config/apis";
import BottomNavigation from "@mui/material/BottomNavigation";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import React, {useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import {useN04TextInfoContentStyles} from '@mui-treasury/styles/textInfoContent/n04';
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useNavigate} from "react-router-dom";
import {CardBody, CardFooter, Row} from "reactstrap";
import {
    addProfileNotificationLength,
    addProfileNotificationRead,
    addProfileNotificationUnread,
    setContactIdToShow,
    setMsgTabIndex,
    swapSideBarExpandIcon
} from "../../reducer";
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import NoDataList from "../../tools/NoDataList";
import NotificationMsg from "../../tools/NotificationMsg";
import NotifyTitle from "../../tools/NotifyTitle";
import {addContactCardToChat, btnStyle, notificationCard, useWindowSize} from "../../tools/Tools";
import Drawer from '@mui/material/Drawer';

function Notify() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const width = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [toShow, setToShow] = useState({});
    const [notifyTheme, setNotifyTheme] = useState('unread');
    const auth = useSelector(state => state.session.authenticated);
    const {countries} = useSelector(state => state.global);
    const auditor = useSelector(state => state.axios.auditor);
    const [state, setState] = useState({right: false});
    const {notificationRead, notificationUnRead, contacts, notification_length} = useSelector(state => state.profile);


    const toCloseNotify = (isOpen) => {
        setToShow({})
        if (isOpen) {
            setState({...state, 'right': false});
        }
    }

    const openContactCardModal = async () => {
        dispatch(swapSideBarExpandIcon(true));
        dispatch(setContactIdToShow(toShow.element_id));

        const route = toShow?.subject === 'declined'
            ? '/archives'
            : toShow?.isArtist
                ? '/artist-contacted-demand'
                : '/artist-contact-demand';

        toCloseNotify()
        navigate(route);
    };

    const openDiscus = async () => {
        let ContactCardToChat;
        const events = [...contacts.book.accepted, ...contacts.auditor.accepted];
        if (toShow?.type === "contact_card_notify") {
            ContactCardToChat = events.filter((value) => value.id === toShow.element_id)[0]
        } else {
            ContactCardToChat = events.filter((value) => value.reservation.id === toShow.element_id)[0]
        }

        toCloseNotify()

        if (ContactCardToChat) {
            await dispatch(setMsgTabIndex(toShow?.isArtist ? 'book' : 'auditor'))
            await addContactCardToChat(dispatch, ContactCardToChat, countries)
            document.getElementById("open_discussion").click()
        } else {
            navigate('/archives')
        }
    }

    const dispatchNewNotificationToast = () => {
        const isContactCardNotify =
            toShow?.type === "contact_card_notify" &&
            ['pending', 'declined'].includes(toShow?.subject);

        const isReservationNotify =
            toShow?.type === "reservation_notify" &&
            ['finish', 'declined'].includes(toShow?.subject);

        (isContactCardNotify || isReservationNotify) ? openContactCardModal() : openDiscus()
    };

    const notifyToRead = async (notify_id) => {
        await auditor.put(apis.readNotification(notify_id)).then(async () => {
            await dispatch(addProfileNotificationLength(notification_length - 1))
            await dispatch(addProfileNotificationUnread([...notificationUnRead.filter((value) => value.id !== notify_id)]))
            let tmp = notificationUnRead.filter((value) => value.id === notify_id)
            tmp.read = true
            await dispatch(addProfileNotificationRead([...notificationRead, ...tmp]))
        }).catch(() => console.log(''))
    }

    const checkUnread = async () => {
        await auditor.get(apis.checkNotificationUnread(1)).then(async (rsp) => {
            let nt = rsp.data;
            await dispatch(addProfileNotificationUnread(nt))
        }).catch((error) => {
            // console.log(error)
        });
    };

    const checkRead = async () => {
        await auditor.get(apis.checkNotificationRead(1)).then(async (rsp) => {
            let nt = rsp.data;
            await dispatch(addProfileNotificationRead(nt))
        }).catch((error) => {
            // console.log(error)
        });
    };

    const handleChange = async (event, newValue) => {
        await setNotifyTheme(newValue);
    };

    const deleteNotification = async (id, _read) => {
        setLoading(true);
        await auditor.delete(apis.deleteNotification(id)).then(async () => null).catch((e) => console.log(e))
        if (_read) {
            await dispatch(addProfileNotificationRead([...notificationRead.filter((value) => value.id !== id)]))
        } else {
            await dispatch(addProfileNotificationLength(notification_length - 1))
            await dispatch(addProfileNotificationUnread([...notificationUnRead.filter((value) => value.id !== id)]))
        }
        toCloseNotify()
        toast.success("Notification supprimé")
        setLoading(false);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const openNotify = (notify) => {
        setToShow(notify)
        if (!notify.read)
            notifyToRead(notify?.id).then(r => null)
        setState({...state, 'right': false});
    }

    useEffect(() => {
        if (auth && !notificationUnRead?.length && !notificationRead?.length) {
            Promise.all([
                checkRead().then(() => null),
                checkUnread().then(() => null),
            ]).then(() => setLoading(false));
        } else {
            setLoading(false);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [auth]);

    return (
        <div>

            <Tooltip title="Notifications" placement="bottom">
                <IconButton
                    id="notification_open"
                    sx={{color: '#1976d2', mx: 0.5}}
                    onClick={toggleDrawer('right', true)}>
                    <Badge badgeContent={notification_length} color="secondary" invisible={!notification_length}>
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>

            <Modal size="md" show={toShow?.id}>
                <CardHeaderCustom
                    cardTitle={NotifyTitle(toShow?.type, toShow?.subject)}
                    closeModal={() => {
                        setState({...state, 'right': true});
                        toCloseNotify(false)
                    }}/>
                <CardBody>
                    <Box>
                        <CardContent>
                            <TextInfoContent
                                classes={{body: 'justify'}}
                                useStyles={useN04TextInfoContentStyles}
                                overline={NotificationMsg(toShow)?.title}
                                body={NotificationMsg(toShow)?.message}/>
                        </CardContent>
                    </Box>
                </CardBody>
                <CardFooter>
                    <Row className="justify-content-end mx-1">
                        <IconButton
                            aria-label="settings"
                            size="small"
                            sx={btnStyle(
                                toShow?.type === "contact_card_notify" && ['pending', 'declined'].includes(toShow?.subject)
                                    ? 'orange'
                                    : 'blue'
                            )}
                            onClick={() => dispatchNewNotificationToast()}>
                            {toShow?.type === "contact_card_notify" && ['pending', 'declined'].includes(toShow?.subject)
                                ? <LaunchIcon fontSize="small" sx={{color: "orange!important"}}/>
                                : <ForumIcon fontSize="small" sx={{color: "blue!important", marginTop: 0.5}}/>}
                        </IconButton>
                    </Row>
                </CardFooter>
            </Modal>

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}>

                <Box sx={{width: width < 500 ? width : 450}} role="presentation">
                    <div className={width > 500 ? "justify-content-center" : null}>
                        <TextInfoContent
                            useStyles={useN04TextInfoContentStyles}
                            heading={width > 500 ? 'Notifications' :
                                <div className={"row m-3 justify-content-between"}>
                                    Notifications
                                    <InputAdornment position="start" sx={{mt: 4}}>
                                        <Button
                                            id="close_notification"
                                            variant="outlined"
                                            onClick={toggleDrawer('right', false)}
                                            endIcon={<CloseIcon/>}>
                                            ESC
                                        </Button>
                                    </InputAdornment>
                                </div>}
                        />
                    </div>
                    <div className="row justify-content-center">
                        <BottomNavigation
                            style={{width: '100%'}}
                            className="mb-3"
                            showLabels
                            value={notifyTheme}
                            onChange={handleChange}>
                            <BottomNavigationAction
                                label="Non Lu(s)"
                                value="unread"
                                icon={<NotificationsIcon/>}
                            />
                            <BottomNavigationAction
                                label="Déjà Lu(s)"
                                value="read"
                                icon={<NotificationsNoneIcon/>}
                            />
                        </BottomNavigation>
                        <Divider sx={{height: 12.5, width: '100%'}} orientation="horizontal"/>
                    </div>
                    <div className="mt-1 overflow-auto">
                        {notifyTheme === 'unread'
                            ? <div style={{background: 'rgba(160,237,199,0.16)'}}>
                                {notificationUnRead?.length
                                    ? notificationUnRead.map((notify, index) =>
                                        <Tooltip
                                            title="Cliquer pour afficher"
                                            placement="left"
                                            key={index + "_unRead"}>
                                            {notificationCard(notify, index, loading, openNotify, deleteNotification)}
                                        </Tooltip>)
                                    : NoDataList("Aucune notification")
                                }
                            </div>
                            : <div style={{background: 'rgb(244,240,240)'}}>
                                {notificationRead?.length
                                    ? notificationRead.map((notify, index) =>
                                        <Tooltip
                                            title="Cliquer pour afficher"
                                            placement="left"
                                            key={index + "_read"}>
                                            {notificationCard(notify, index, loading, openNotify, deleteNotification)}
                                        </Tooltip>)
                                    : NoDataList("Aucune notification")
                                }
                            </div>
                        }
                    </div>
                </Box>

            </Drawer>

        </div>
    );
}

export default Notify;
