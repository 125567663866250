import React, {useState} from "react";
import {
    Box,
    Button,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {Modal, ModalBody} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import {addCertificationDocument} from "../../../reducer";
import {detectFileType} from "../../../tools/Tools";

function AddDocumentCertification() {
    const dispatch = useDispatch();
    const {documents, certification} = useSelector((state) => state.certificationData);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const toggleModal = () => setModalOpen(!modalOpen);

    const openPreview = (url) => {
        setSelectedImage(url);
        setModalOpen(true);
    };

    const handleFileChange = async (e) => {
        let selectedFiles = Array.from(e.target.files);

        // If more than two files are selected, keep only the first two
        if (selectedFiles.length > 2) {
            selectedFiles = selectedFiles.slice(0, 2);
            toast.error("Vous pouvez sélectionner au maximum deux fichiers. Les deux premiers ont été retenus.");
        }

        // Check if adding selected files exceeds the total limit of 2
        if (documents.length + selectedFiles.length > 2) {
            toast.error("Vous ne pouvez pas avoir plus de deux fichiers au total.");
            return;
        }

        const validTypes = ["application/pdf", "image/png", "image/jpeg"];

        // Validate each selected file
        const validFiles = [];
        const invalidFiles = [];

        for (const file of selectedFiles) {
            if (!validTypes.includes(file.type)) {
                invalidFiles.push(file);
                continue;
            }
            validFiles.push(file);
        }

        if (invalidFiles.length > 0) {
            toast.error("Tous les fichiers doivent être en format PDF, PNG ou JPEG.");
        }

        // Prepare documents for dispatch
        const newDocuments = [...documents, ...validFiles.map((file, index) => ({
            id: `${file.name}-${Date.now()}-${index}`,
            name: file.name,
            file: file,
        }))];

        await dispatch(addCertificationDocument(newDocuments));
    };

    const handleDelete = (id) => {
        const updatedDocuments = documents.filter((doc) => doc.id !== id);
        dispatch(addCertificationDocument(updatedDocuments));
    };

    return (
        <Box
            sx={{
                p: 2,
                gap: 2,
                boxShadow: 2,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                borderRadius: 2,
            }}
        >
            <Typography variant="h6">Document officiel</Typography>
            <Button
                variant="outlined"
                component="label"
                startIcon={<CloudUploadIcon/>}
            >
                Téléchargez votre document
                <input
                    hidden
                    multiple
                    type="file"
                    accept="application/pdf, image/png, image/jpeg"
                    onChange={handleFileChange}
                />
            </Button>
            <Typography variant="body2" color="textSecondary">
                Formats acceptés : PDF, PNG, JPEG
            </Typography>

            {documents?.length > 0 && (
                <List>
                    {documents.map((doc) => (
                        <ListItem key={doc.id}>
                            <ListItemText primaryTypographyProps={{variant: "body2"}} primary={doc.name}/>
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDelete(doc.id)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            )}

            {certification?.document_urls?.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle2">Documents envoyés</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            mt: 1,
                        }}
                    >
                        {certification.document_urls.map((url, index) => {
                            const fileType = detectFileType(url);

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        overflow: "hidden",
                                        borderRadius: 1,
                                        boxShadow: 2,
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#f0f0f0",
                                    }}
                                    onClick={() => openPreview(url)}
                                >
                                    {fileType === "image" ? (
                                        <img
                                            src={url}
                                            alt={`Document ${index + 1}`}
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        />
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                textAlign: "center",
                                                color: "#333",
                                            }}
                                        >
                                            Aperçu du document
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            )}

            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered>
                <ModalBody>
                    {selectedImage && (
                        detectFileType(selectedImage) === "image" ? (
                            <img src={selectedImage} alt="Agrandissement" className="img-fluid" />
                        ) : (
                            <iframe
                                src={selectedImage}
                                title="Aperçu du document"
                                style={{width: "100%", height: "500px", border: "none"}}
                            />
                        )
                    )}
                </ModalBody>
            </Modal>
        </Box>
    );
}

export default AddDocumentCertification;
