import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, {useState, useEffect} from 'react';
import {Box, IconButton, Stack, TextField, Tooltip, Typography} from "@mui/material";
import Button from "@material-ui/core/Button";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../../config/apis";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {addProfileConditions} from "../../../reducer";
import {changeFields, checkErrorMessage, deleteInObject} from "../../../tools/Tools";

const ContactCost = () => {

    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('');
    const {conditions, profileData} = useSelector(state => state.profile);
    const {countries} = useSelector(state => state.global);
    const auditor = useSelector(state => state.axios.auditor);
    const [newAmount, setNewAmount] = useState(null);
    const {currency} = profileData;

    const updateContactAmount = () => {
        if (!newAmount) {
            toast.error("Veuillez entrer un montant valide.");
            return;
        }

        const sanitizedAmount = parseFloat(newAmount.toString().replace(',', '.'));

        if (isNaN(sanitizedAmount)) {
            toast.error("Veuillez entrer un montant numérique valide.");
            return;
        }

        let tmp = deleteInObject({...conditions});
        tmp['contact_amount'] = newAmount
        auditor.put(apis.updateCondition, {...tmp}).then(async () => {
            setEdit(false)
            setNewAmount(null)
            toast.success("Frais de contact à Jour.")
            await dispatch(addProfileConditions({...tmp}));
        }).catch((error) => {
            toast.error(checkErrorMessage(error).message)
        })
    }

    useEffect(() => {
        countries?.forEach((tmpCountry) => {
            if (tmpCountry?.currencies?.code?.toLowerCase() === currency?.toLowerCase()) {
                setCurrencySymbol(tmpCountry?.currencies?.symbol);
            }
        });
    }, [countries, currency]);

    return (
        <Box
            sx={{
                marginBotton: 10,
                backgroundColor: "#F5F5FA",
                padding: 2,
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: 1,
            }}
        >
            <Box>
                <Typography variant="subtitle2" sx={{color: "#6C757D", fontWeight: 300}}>
                    FRAIS DE CONTACT
                </Typography>
                {!edit ? (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography
                            variant="h6"
                            sx={{color: "#3F51B5", fontWeight: "bold"}}
                        >
                            {currencySymbol}{conditions?.contact_amount || 0}
                        </Typography>
                        <IconButton onClick={() => setEdit(true)} size="small">
                            <EditIcon fontSize="small" sx={{color: "#00ACC1"}}/>
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={newAmount}
                            placeholder={conditions?.contact_amount || 0}
                            onChange={(e) => changeFields(setNewAmount, e)}
                            inputProps={{
                                style: {textAlign: "center", fontSize: "1rem"},
                            }}
                            sx={{
                                maxWidth: 100,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                },
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            onClick={updateContactAmount}
                            endIcon={<DoneAllIcon fontSize="small" sx={{color: "#00c177 !important"}}/>}
                            sx={{
                                textTransform: "none",
                                fontWeight: "bold",
                            }}
                        >
                            Enregistrer
                        </Button>
                    </Stack>
                )}
            </Box>
            <Tooltip title="Les frais de contact s'appliquent pour les auditeurs." arrow>
                <IconButton size="small">
                    <InfoOutlinedIcon sx={{color: "#6C757D"}}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
};

export default ContactCost;
