import React from "react";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';

const initState = {
    process: true,
    loadingAnswer: false,
    messagesList: [ <ChatMsg
        avatar={<SmartToyIcon/>}
        messages={[
            "Bonjour je suis Izuka." +
            " Mon rôle c'est vous renseignez au maximum sur l'événement en cours. Je mets a disposition toutes les" +
            " commandes nécessaires pour le bon déroulement de la réservation."
        ]}
    />],
};

const ChatBotReducer = (state = initState, action) => {
    switch (action.type) {
        case "ADD_BOT_MESSAGE_LIST":
            return {
                ...state,
                messagesList: action.data
            };
        case "SWITCH_BOT_MESSAGE_PROCESS":
            return {
                ...state,
                process: action.data
            };
        case "SWITCH_BOT_LOADING_ANSWER":
            return {
                ...state,
                loadingAnswer: action.data
            };
        default:
            return state;
    }
};

export default ChatBotReducer;
