import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {addContactCardToChat} from "../../tools/Tools";
import CalendarView from "./CalendarView";
import {Row} from "reactstrap";
import EventList from "./EventList";


function Agenda() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {countries} = useSelector(state => state.global);
    const auth = useSelector(state => state.session.authenticated);

    const openToChat = async (contact) => {
        await addContactCardToChat(dispatch, contact, countries)
        document.getElementById("open_discussion").click()
    }

    useEffect(() => {
        !auth && navigate('/')
    }, [auth, navigate])

    return (
        <div className="content">
            <Row className="justify-content-center bg-transparent border-0">
                <div className="col-md-4">
                    <EventList openToChat={openToChat}/>
                </div>
                <div className="col-md-8">
                    <CalendarView openToChat={openToChat}/>
                </div>
            </Row>
        </div>
    );
}

export default Agenda;
