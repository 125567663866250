import Avatar from "@material-ui/core/Avatar";
import ListItem from '@material-ui/core/ListItem';
import {useGrowAvatarStyles} from "@mui-treasury/styles/avatar/grow";
import {useMaterialListItemStyles} from '@mui-treasury/styles/listItem/material';
import {Column, Item, Row} from "@mui-treasury/components/flex";
import {Info, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useMusicInfoStyles} from "@mui-treasury/styles/info/music";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import React, {useState, useEffect, useCallback} from "react";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {usePushingGutterStyles} from '@mui-treasury/styles/gutter/pushing';
import {useLabelIconStyles} from '@mui-treasury/styles/icon/label';
import {useNavigate} from "react-router-dom";
import {CardBody, CardFooter, CardHeader, Spinner} from "reactstrap";
import NoDataList from "../../../tools/NoDataList";
import {checkErrorMessage} from "../../../tools/Tools";

const commonProps = {
    blur: '1px',
    radius: 16,
    size: 40,
    opacity: 0.1,
};

function ArtistSearch(props) {

    const navigate = useNavigate();
    const {setOpened, setLoading} = props;
    const classes = useMaterialListItemStyles();
    const [loader, setLoader] = useState(false)
    const [artistResults, setArtistResults] = useState([])
    const iconLabelStyles = useLabelIconStyles({linked: true});
    const auditor = useSelector(state => state.axios.auditor);
    const avatarStyles = useGrowAvatarStyles({...commonProps});
    const gutterStyles = usePushingGutterStyles({space: 3, firstExcluded: true});
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const searchArtists = (e) => {
        let tmp;
        try {
            tmp = e.target.value;
        } catch {
            tmp = e;
        }

        let value = tmp.replace(/[^a-zA-Z0-9 ]/g, "");

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        if (value) {
            const timeout = setTimeout(async () => {
                setLoader(true);
                setLoading(true);
                try {
                    const resp = await auditor.get("artist_services/artist_search/" + value);
                    setArtistResults(resp.data);
                } catch (error) {
                    toast.error(checkErrorMessage(error).message);
                } finally {
                    setLoading(false);
                    setLoader(false);
                }
            }, 500); // Ajustez le délai de 500 ms selon vos besoins

            setDebounceTimeout(timeout);
        } else {
            setArtistResults([]);
            setLoading(false);
            setLoader(false);
        }
    };

    const handleUserKeyPress = useCallback(event => {
        const {key, keyCode} = event;
        if(keyCode === 27 && key === 'Escape'){
            setOpened(false);
        }
    }, [setOpened]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div>
            <CardHeader>
                <InputBase
                    sx={{width: '100%', mt: 0.5}}
                    placeholder="Trouver un artiste ..."
                    className="radius-1"
                    onChange={(e) => searchArtists(e)}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <Button variant="outlined" onClick={() => setOpened(false)} endIcon={<CloseIcon/>}>
                                ESC
                            </Button>
                        </InputAdornment>
                    }
                    inputProps={{'aria-label': "Trouver un artiste"}}
                />
            </CardHeader>
            <CardBody>
                <Box sx={{height: 400}}>
                    <Column gap={2} width={'100%'}>
                        {loader
                            ? null
                            : !artistResults?.length && NoDataList("Aucun Résultat")}

                        {loader
                            ? <div className="row justify-content-center mt-5">
                                <Spinner type="grow" size="sm"/>
                            </div>
                            : artistResults?.length
                                ? <Box sx={{maxHeight: 400}} className="overflow-auto">
                                    {artistResults.map((artist, index) =>
                                        <ListItem
                                            className="radius-1 cursor-pointer row justify-content-between"
                                            key={index}
                                            classes={classes}
                                            onClick={() => {
                                                setOpened(false)
                                                navigate("/artists/pages/" + artist.id)
                                            }}>
                                            <Row key={"artist_search" + index}>
                                                <Item>
                                                    <div className={avatarStyles.root}>
                                                        <Avatar src={artist.photo}/>
                                                    </div>
                                                </Item>
                                                <Info useStyles={useMusicInfoStyles} minWidth={0}>
                                                    <InfoTitle>{artist.artist_name}</InfoTitle>
                                                    <InfoSubtitle>
                                                        <small>@{artist.artist_type}</small>
                                                    </InfoSubtitle>
                                                </Info>
                                            </Row>
                                            <span className={'MuiLabel-amount'}>
                                                    <div className={gutterStyles.parent}>
                                                      <button type={'button'} tabIndex={0}
                                                              className={iconLabelStyles.link}>
                                                        <StarIcon className={iconLabelStyles.icon}/>{artist.rating}
                                                      </button>
                                                    </div>
                                                </span>
                                        </ListItem>
                                    )}
                                </Box>
                                : null}
                    </Column>
                </Box>
            </CardBody>
            <CardFooter>
                <Row className="justify-content-end">
                    <small>Search by <strong>KantoBiz</strong></small>
                </Row>
            </CardFooter>
        </div>
    )
}

export default ArtistSearch;
