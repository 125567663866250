import {initializeSocketConnection} from './initializeSocketConnection';

const InitializeSocket = (userId) => {
    return initializeSocketConnection(userId,
        () => console.log(''),
        (reason) => console.log('', reason)
    );
};

export default InitializeSocket;
