const initState = {
    process: true,
    loadingAnswer: false,
    messagesList: [],
};

const ChatPersonReducer = (state = initState, action) => {
    switch (action.type) {
        case "ADD_PERSON_MESSAGE_LIST":
            return {
                ...state,
                messagesList: action.data
            };
        case "SWITCH_PERSON_MESSAGE_PROCESS":
            return {
                ...state,
                process: action.data
            };
        case "SWITCH_PERSON_LOADING_ANSWER":
            return {
                ...state,
                loadingAnswer: action.data
            };
        default:
            return state;
    }
};

export default ChatPersonReducer;
