import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {CardFooter, CardHeader, CardTitle, Row, Spinner} from "reactstrap";
import CelebrationIcon from '@mui/icons-material/Celebration';
import InfoIcon from '@mui/icons-material/Info';
import CardHeaderCustom from "../../tools/CardHeaderCustom";
import {btnStyle, checkErrorMessage, useWindowSize} from "../../tools/Tools";
import EventToReserve from "../reservations/EventToReserve";
import ArtistEventRate from "./ArtistEventRate";
import ContactCardInfos from "./ContactCardInfos";
import ContactInvoice from "./ContactInvoice";

const indicatorColors = ['#ed1c24'];

const ContactCard = ({ContactCardToChat, setToShow, updateCardContact, toFinished, toDelete}) => {

    const now = moment();
    const width = useWindowSize();
    const [warnDecline, setWarnDecline] = useState(false);
    const [modalTabIndex, setModalTabIndex] = useState(0);
    const [address, setAddress] = useState({});
    const [load, setLoad] = useState(false);
    const userId = useSelector((state) => state.profile.userId);
    const auditor = useSelector((state) => state.axios.auditor);
    const countries = useSelector((state) => state.global.countries);
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const tabItemStyles = useGmailTabItemStyles({color: '#ed1c24'});
    const bookedList = parseInt(userId) !== parseInt(ContactCardToChat?.artist_id);

    const splitAddress = () => {
        const [street, pcCity, country] = ContactCardToChat?.reservation?.address?.split(', ') || [];
        const [postalCode, city] = pcCity?.split(' ') || [];
        setAddress({street, postalCode, country, city});
    };

    const getRoyalties = async (reservationId) => {
        setLoad(true);
        try {
            const resp = await auditor.put(`reservation/finish/${reservationId}`);
            let data = resp.data;
            /* eslint-disable-next-line no-unused-vars */
            let {transaction, contact_card} = data
            await toFinished(transaction, contact_card);
        } catch (error) {
            toast.error(checkErrorMessage(error).message);
        }
        setLoad(false);
    };

    const handleDeclineEvent = (bookId) => setWarnDecline(bookId);

    const renderButton = (btnType, label, color, onClickAction, icon, disabled = false) => (
        <Tooltip title={label} placement="top">
            <IconButton
                size="small"
                aria-label={label}
                sx={btnStyle(color)}
                onClick={onClickAction}
                disabled={disabled}
            >
                <small>{label}&nbsp;</small>
                {load ? <Spinner size="sm" className="mb-1 mr-1"/> : icon}
            </IconButton>
        </Tooltip>
    );

    const renderDeclineButton = () => {
        const isEventFinished = ContactCardToChat?.reservation?.status === 'finish';

        if (isEventFinished) {
            return (
                <ArtistEventRate ContactCardToChat={ContactCardToChat}/>
            );
        } else {
            // Affiche le bouton "Annuler l'événement" ou "Décliner l'événement"
            return renderButton(
                "decline",
                userId === ContactCardToChat?.reservation?.auditor_who_reserve_id
                    ? "Annuler l'événement"
                    : "Décliner l'événement",
                "red",
                () => handleDeclineEvent(ContactCardToChat?.id),
                <RemoveCircleOutlineOutlinedIcon fontSize="medium" sx={{color: "red!important"}}/>
            );
        }
    };

    const renderAcceptButton = () => (
        renderButton(
            "accept",
            "Accepter",
            "lightGreen",
            () => updateCardContact(ContactCardToChat?.id, 'accepted', setWarnDecline),
            <DoneAllIcon sx={{color: "lightGreen!important"}}/>
        )
    );

    const renderGetPaymentButton = () => (
        renderButton(
            "getPayment",
            "J'ai terminer cette prestation",
            "lightGreen",
            () => getRoyalties(ContactCardToChat?.reservation?.id),
            <AddTaskIcon fontSize="medium" sx={{color: "lightGreen!important"}}/>
        )
    );

    const renderButtons = () => {
        if (toDelete) return null;

        if (!bookedList) {
            if (ContactCardToChat?.reservation?.status === 'paid' &&
                ContactCardToChat?.reservation?.payment &&
                now.isAfter(new Date(ContactCardToChat?.reservation?.event_date))) {
                return renderGetPaymentButton();
            }
            return (
                <>
                    {renderDeclineButton()}
                    {renderAcceptButton()}
                </>
            );
        }

        return renderDeclineButton();
    };

    useEffect(() => {
        splitAddress();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [ContactCardToChat]);

    return (
        <div>
            {/* Modal for Decline Warning */}
            <Modal size="sm" show={warnDecline} style={{marginTop: 4}}>
                <CardHeader className="text-center pt-3">
                    <CardTitle tag="h6">
                        <DoNotDisturbIcon sx={{color: 'silver!important'}}/>
                    </CardTitle>
                </CardHeader>
                <p className="text-center mt-3">
                    {bookedList ? "Annuler cet événement ?" : "Décliner cet événement ?"}
                </p>
                <CardFooter>
                    <Row className="justify-content-end">
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('red')}
                            onClick={() => setWarnDecline(false)}>
                            <CloseIcon sx={{color: "red!important"}}/>
                        </IconButton>
                        <IconButton
                            size="small"
                            aria-label="settings"
                            sx={btnStyle('lightGreen')}
                            onClick={() => updateCardContact(parseInt(warnDecline), 'declined', setWarnDecline)}>
                            <small>Oui&nbsp;</small>
                            <DoneAllIcon sx={{color: "lightGreen!important"}}/>
                        </IconButton>
                    </Row>
                </CardFooter>
            </Modal>

            {/* Tabs Section */}
            <CardHeaderCustom
                closeModal={() => setToShow(false)}
                cardTitle={
                    <Tabs
                        value={modalTabIndex}
                        classes={tabsStyles}
                        onChange={(e, index) => setModalTabIndex(index)}
                        className="radius-top-1 border-bottom-0"
                        TabIndicatorProps={{
                            children: <div className={`MuiIndicator-${modalTabIndex}`}/>,
                        }}
                    >
                        <Tab
                            wrapped
                            iconPosition="start"
                            label={width < 500 ? null : "Événement"}
                            classes={tabItemStyles}
                            icon={<CelebrationIcon/>}
                        />
                        <Tab
                            wrapped
                            iconPosition="start"
                            label={width < 500 ? null : "Facture"}
                            classes={tabItemStyles}
                            icon={<ReceiptIcon/>}
                        />
                        <IconButton onClick={() => setModalTabIndex(2)}>
                            <InfoIcon/>
                        </IconButton>
                    </Tabs>
                }
            />

            {/* Tab Content */}
            <Box className="row justify-content-center">
                {!modalTabIndex ? (
                    <EventToReserve
                        address={address}
                        ContactCardToChat={ContactCardToChat}
                        bookedList={bookedList}
                        countries={countries}/>
                ) : modalTabIndex === 1 ? (
                    <ContactInvoice address={address} ContactCardToChat={ContactCardToChat} countries={countries}/>
                ) : (
                    <ContactCardInfos ContactCardToChat={ContactCardToChat}/>
                )}
            </Box>

            {/* Buttons Footer */}
            <CardFooter>
                <Row className="justify-content-end mx-1">
                    <div className={width < 500 ? "col justify-content-end" : ""}>{renderButtons()}</div>
                    {toDelete
                        ? <Tooltip title="Supprimer" placement="top">
                            <IconButton
                                size="small"
                                sx={btnStyle('red')}
                                onClick={() => toDelete(ContactCardToChat?.id)}
                            >
                                <DeleteIcon sx={{color: "red!important"}}/>
                            </IconButton>
                        </Tooltip>
                        : null}
                </Row>
            </CardFooter>
        </div>
    );
};

export default ContactCard;
